import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PreferencesState = {
  selectedTheme: null | "light" | "dark";
};

const initialState: PreferencesState = {
  selectedTheme: null,
};

export const { actions, reducer } = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setSelectedTheme: (
      state,
      action: PayloadAction<{ selectedTheme: null | "light" | "dark" }>
    ) => {
      state.selectedTheme = action.payload.selectedTheme;
    },
  },
});
