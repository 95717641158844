import { useSelector } from "react-redux";
import { preferencesSelectors } from "../store/preferences/selector";
import { darkTheme, lightTheme } from "../styles/theme";
import { useColorScheme } from "./useColorScheme";

export const useTheme = () => {
  let theme = useColorScheme();
  const selectedTheme = useSelector(preferencesSelectors.selectedTheme);

  if (selectedTheme) {
    theme = selectedTheme;
  }

  return theme === "light" ? lightTheme : darkTheme;
};
