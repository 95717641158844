import { format, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MatchParams } from ".";
import { PostOverlay, TagWrap } from "../../../components/Brand/BookingDetails";
import { BookingTimeline } from "../../../components/Brand/BookingDetails/BookingTimeline";
import { ContactDetails } from "../../../components/Brand/ContactDetails";
import { Card } from "../../../components/Card";
import { CardLink } from "../../../components/CardLink";
import DetailList from "../../../components/DetailList";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { PreferredProfile } from "../../../components/PreferredProfile";
import { Tag } from "../../../components/Tag";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingPostType,
  BookingStatus,
  BrandBookingsQuery,
  useGetBookingQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import styled, { css } from "../../../styles";
import { renderBookingPostTypeTag } from "../../../utils/enums";

interface Props {
  bookings: BrandBookingsQuery["bookings"]["bookings"];
  lastElementRef: (node: any) => void;
}

const Wrap = styled.div`
  height: 100%;
  padding: 0 0 ${(p) => p.theme.spacing.l};
`;

const TableWrap = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Allows inertial scrolling on iOS */
`;

export const ArchivedBookings = ({ bookings, lastElementRef }: Props) => {
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  const history = useHistory();

  useEffect(() => {
    setActiveBookingId(idMatch && idMatch.params.id ? idMatch.params.id : null);
  }, [idMatch]);

  return (
    <Wrap>
      <Modal
        isOpen={activeBookingId ? true : false}
        setIsOpen={() => setActiveBookingId(null)}
        onClose={() => {
          history.replace("/b/bookings/archived");
        }}
        maxWidth={760}
      >
        <ArchivedBookingDetails bookingId={activeBookingId} />
      </Modal>

      <Card>
        <TableWrap>
          <Table>
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col" className="widerColumn">
                  Name
                </th>
                <th scope="col">Handle</th>
                <th scope="col" className="widerColumn">
                  Listing
                </th>
                <th scope="col">Location</th>
                <th scope="col">Application Date</th>
                <th scope="col">Expiry Date</th>
                <th scope="col">Cancellation Date</th>
                <th scope="col">Rejected At</th>
              </tr>
            </thead>
            {bookings ? (
              <tbody>
                {bookings
                  .filter((e) => e.creator.preferredProfile)
                  .map((b, index) => {
                    const creatorProfile = b.creator.preferredProfile!;
                    return (
                      <CardLink to={`/b/bookings/archived/${b.id}`}>
                        <Row
                          key={b.id}
                          ref={
                            index === bookings.length - 5
                              ? lastElementRef
                              : null
                          }
                        >
                          <td>
                            <Text margin="0" colorPreset="text" isCompact>
                              {b.status ===
                              BookingStatus.BookingStatusCancelled ? (
                                <Tag text="Cancelled" color="purple" />
                              ) : b.status ===
                                BookingStatus.BookingStatusExpired ? (
                                <Tag text="Expired" color="blue" />
                              ) : (
                                <Tag text="Rejected" color="red" />
                              )}
                            </Text>
                          </td>
                          <td className="widerColumn">
                            <Text
                              margin="0"
                              colorPreset="text"
                              isCompact
                              truncate
                            >
                              {creatorProfile.name}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text" truncate>
                              {creatorProfile.username}
                            </Text>
                          </td>
                          <td className="widerColumn">
                            <Text
                              margin="0"
                              colorPreset="text"
                              size="s"
                              truncate
                            >
                              {b.listing.name}
                            </Text>
                          </td>
                          <td>
                            <Text
                              margin="0"
                              colorPreset="text"
                              size="s"
                              truncate
                            >
                              {b.location ? b.location.name : ""}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text">
                              {b.createdAt
                                ? format(
                                    fromUnixTime(b.createdAt),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text">
                              {b.status ===
                                BookingStatus.BookingStatusExpired &&
                              b.updatedAt
                                ? format(
                                    fromUnixTime(b.updatedAt),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text">
                              {b.status ===
                                BookingStatus.BookingStatusCancelled &&
                              b.updatedAt
                                ? format(
                                    fromUnixTime(b.updatedAt),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text">
                              {b.status ===
                                BookingStatus.BookingStatusRejected &&
                              b.updatedAt
                                ? format(
                                    fromUnixTime(b.updatedAt),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </Text>
                          </td>
                        </Row>
                      </CardLink>
                    );
                  })}
              </tbody>
            ) : null}
          </Table>
        </TableWrap>
      </Card>
    </Wrap>
  );
};

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;

  .widerColumn {
    width: 160px;
  }

  thead,
  tbody tr {
    display: table;
    min-width: 100%;
    table-layout: fixed; /* This helps to keep the columns aligned */
  }

  tbody {
    display: block;
    height: 80vh;
    overflow-y: scroll;
    min-width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
    border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
    width: 130px;
  }

  th {
    background-color: ${(p) => p.theme.color.card.callout};
    font-size: ${(p) => p.theme.typography.size.xs};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    color: ${(p) => p.theme.color.typography.secondary};
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody {
    overflow-y: scroll;
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

const Row = styled.tr`
  display: table;
  width: 100%;
  cursor: pointer;
  table-layout: fixed;
  background-color: ${(p) => p.theme.color.card.background};

  :hover {
    background-color: ${(p) => p.theme.color.card.callout};
  }
`;

export const ArchivedBookingDetails = ({
  bookingId,
}: {
  bookingId: string | null;
}) => {
  const client = useGqlClient();
  const { isLoading, data, isError } = useGetBookingQuery(
    client,
    { id: bookingId ? bookingId : "" },
    {
      retry: false,
    }
  );

  if (isLoading) {
    return (
      <Flex align="center" justify="center">
        <Loading />
      </Flex>
    );
  }

  if (isError || !data) {
    return (
      <View padding="xl">
        <H3>Oops</H3>
        <Text margin={"0 0 xl 0"}>
          Couldn't load your booking, please try again later.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <PreferredProfile
        creator={data.booking.creator}
        platform={data.booking.platform}
        avatarSize={68}
      />
      <ContactDetails booking={data.booking} />
      <CardDivider margin="xl 0 0" />
      {data.booking.bookingEvents.length > 0 && (
        <View margin="xl 0 m">
          <BookingTimeline booking={data.booking} />
        </View>
      )}
      <PostsGrid>
        {data.booking.bookingPosts.map((bp) => (
          <CardLink href={bp.permalink} to={"/b/reports/" + bp.id} key={bp.id}>
            <div style={{ position: "relative" }}>
              <Image
                src={bp.thumbnailUrl ? bp.thumbnailUrl : bp.mediaUrl}
                square={bp.postType === BookingPostType.BookingPostTypeFeed}
              />
              <TagWrap>
                <div>{renderBookingPostTypeTag(bp.postType)}</div>
              </TagWrap>
              <PostOverlay />
            </div>
          </CardLink>
        ))}
      </PostsGrid>
      <View margin="xl 0 0">
        <DetailList
          fields={[
            {
              label: "Name",
              value: data.booking.creator.preferredProfile?.name,
            },
            {
              label: "Handle",
              value: data.booking.creator.preferredProfile?.username,
            },
            {
              label: "Listing",
              value: data.booking.listing.name,
            },
            {
              label: "Location",
              value: data.booking.location ? data.booking.location.name : "",
            },
            {
              label: "Status",
              value:
                data.booking.status === BookingStatus.BookingStatusCancelled
                  ? "Cancelled"
                  : data.booking.status === BookingStatus.BookingStatusExpired
                  ? "Expired"
                  : "Rejected",
            },
            {
              label: "Application Date",
              value: data.booking.createdAt
                ? format(fromUnixTime(data.booking.createdAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusExpired && {
              label: "Expiration Date",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusCancelled && {
              label: "Cancellation Date",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusCancelled && {
              label: "Cancellation Reason",
              value: data.booking.cancellationReason
                ? data.booking.cancellationReason
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusRejected && {
              label: "Rejection Date",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusRejected && {
              label: "Rejection Reason",
              value: data.booking.rejectionMessage
                ? data.booking.rejectionMessage
                : "",
            },
          ]}
        />
      </View>
    </View>
  );
};

const Image = styled.img<{ maxHeight?: number; square: boolean }>`
  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 1 / 1;
        `}
  width: 100%;
  object-fit: cover;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : "none")};
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }
`;
