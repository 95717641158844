import { format, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MatchParams } from ".";
import {
  BrandMentionWrap,
  HoverOverlay,
  PostOverlay,
  PostWrap,
  SocialMediaHandle,
  TagWrap,
} from "../../../components/Brand/BookingDetails";
import { BookingTimeline } from "../../../components/Brand/BookingDetails/BookingTimeline";
import { ContactDetails } from "../../../components/Brand/ContactDetails";
import { Card } from "../../../components/Card";
import { CardLink } from "../../../components/CardLink";
import DetailList from "../../../components/DetailList";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import { PressLinkIcon } from "../../../components/icons/PressLinkIcon";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { PreferredProfile } from "../../../components/PreferredProfile";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingPostType,
  BrandBookingsQuery,
  useGetBookingQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import styled, { css } from "../../../styles";
import { renderBookingPostTypeTag } from "../../../utils/enums";

interface Props {
  bookings: BrandBookingsQuery["bookings"]["bookings"];
  lastElementRef: (node: any) => void;
}

const Wrap = styled.div`
  height: 100%;
`;

const TableWrap = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Allows inertial scrolling on iOS */
`;

export const CompletedBookings = ({ bookings, lastElementRef }: Props) => {
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  const history = useHistory();

  useEffect(() => {
    setActiveBookingId(idMatch && idMatch.params.id ? idMatch.params.id : null);
  }, [idMatch]);

  return (
    <Wrap>
      <Modal
        isOpen={activeBookingId ? true : false}
        setIsOpen={() => setActiveBookingId(null)}
        onClose={() => {
          history.replace("/b/bookings/completed");
          setActiveBookingId(null);
        }}
        maxWidth={760}
      >
        <CompletedBookingDetails
          bookingId={activeBookingId ? activeBookingId : ""}
        />
      </Modal>

      <Card>
        <TableWrap>
          <Table>
            <thead>
              <tr>
                <th scope="col" className="widerColumn">
                  Name
                </th>
                <th scope="col">Handle</th>
                <th scope="col" className="widerColumn">
                  Listing
                </th>
                <th scope="col">Location</th>
                <th scope="col">Date of Visit</th>
                <th scope="col" className="widerColumn">
                  Date of Completion
                </th>
                <th scope="col">Feedback Score</th>
                <th scope="col" className="widerColumn">
                  Feedback details
                </th>
              </tr>
            </thead>
            {bookings ? (
              <tbody>
                {bookings
                  .filter((e) => e.creator.preferredProfile)
                  .map((b, index) => {
                    const creatorProfile = b.creator.preferredProfile!;
                    return (
                      <CardLink to={`/b/bookings/completed/${b.id}`}>
                        <Row
                          key={b.id}
                          ref={
                            index === bookings.length - 5
                              ? lastElementRef
                              : null
                          }
                        >
                          <td className="widerColumn">
                            <Text
                              margin="0"
                              colorPreset="text"
                              isCompact
                              truncate
                            >
                              {creatorProfile.name}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text" truncate>
                              {creatorProfile.username}
                            </Text>
                          </td>
                          <td className="widerColumn">
                            <Text
                              margin="0"
                              colorPreset="text"
                              size="s"
                              truncate
                            >
                              {b.listing.name}
                            </Text>
                          </td>
                          <td>
                            <Text
                              margin="0"
                              colorPreset="text"
                              size="s"
                              truncate
                            >
                              {b.location ? b.location.name : ""}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text">
                              {b.confirmedTimeslot
                                ? format(
                                    fromUnixTime(b.confirmedTimeslot.date),
                                    "dd/MM/yyyy"
                                  )
                                : b.redeemedAt
                                ? format(
                                    fromUnixTime(b.redeemedAt),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </Text>
                          </td>
                          <td className="widerColumn">
                            <Text margin="0" colorPreset="text">
                              {b.completedAt
                                ? format(
                                    fromUnixTime(b.completedAt),
                                    "dd/MM/yyyy"
                                  )
                                : b.updatedAt
                                ? format(
                                    fromUnixTime(b.updatedAt),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </Text>
                          </td>
                          <td>
                            <Text margin="0" colorPreset="text">
                              {b.rating ? b.rating : ""}
                            </Text>
                          </td>
                          <td className="widerColumn">
                            <Text margin="0" colorPreset="text" truncate>
                              {b.feedback ? b.feedback : ""}
                            </Text>
                          </td>
                        </Row>
                      </CardLink>
                    );
                  })}
              </tbody>
            ) : null}
          </Table>
        </TableWrap>
      </Card>
    </Wrap>
  );
};

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;

  .widerColumn {
    width: 160px;
  }

  thead,
  tbody tr {
    display: table;
    min-width: 100%;
    table-layout: fixed; /* This helps to keep the columns aligned */
  }

  tbody {
    display: block;
    height: 80vh;
    overflow-y: scroll;
    min-width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
    border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
    width: 130px;
  }

  th {
    background-color: ${(p) => p.theme.color.card.callout};
    font-size: ${(p) => p.theme.typography.size.xs};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    color: ${(p) => p.theme.color.typography.secondary};
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody {
    overflow-y: scroll;
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

const Row = styled.tr`
  display: table;
  width: 100%;
  cursor: pointer;
  table-layout: fixed;
  background-color: ${(p) => p.theme.color.card.background};

  :hover {
    background-color: ${(p) => p.theme.color.card.callout};
  }
`;

export const CompletedBookingDetails = ({
  bookingId,
}: {
  bookingId: string | null;
}) => {
  const client = useGqlClient();
  const { isLoading, data, isError } = useGetBookingQuery(
    client,
    { id: bookingId ? bookingId : "" },
    {
      retry: false,
    }
  );

  if (isLoading) {
    return (
      <Flex align="center" justify="center">
        <Loading />
      </Flex>
    );
  }

  if (isError || !data) {
    return (
      <View padding="xl">
        <H3>Oops</H3>
        <Text margin={"0 0 xl 0"}>
          Couldn't load your booking, please try again later.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <PreferredProfile
        creator={data.booking.creator}
        platform={data.booking.platform}
        avatarSize={68}
      />
      <ContactDetails booking={data.booking} />
      {data.booking.bookingEvents.length > 0 && (
        <View margin="xl 0 m">
          <BookingTimeline booking={data.booking} />
        </View>
      )}
      <H3 margin="xl 0 m">Completed content</H3>
      <PostsGrid>
        {data.booking.bookingPosts.map((bp) => (
          <CardLink href={bp.permalink} to={"/b/reports/" + bp.id} key={bp.id}>
            <PostWrap>
              <Image
                src={bp.thumbnailUrl ? bp.thumbnailUrl : bp.mediaUrl}
                square={bp.postType === BookingPostType.BookingPostTypeFeed}
              />
              <TagWrap>
                <div>{renderBookingPostTypeTag(bp.postType)}</div>
              </TagWrap>
              <HoverOverlay className="show-on-hover">
                <BrandMentionWrap>
                  <Flex
                    margin="0 0 0 0"
                    align="center"
                    justify="center"
                    style={{ marginTop: -2 }}
                  >
                    <PressLinkIcon width={16} />
                  </Flex>
                  <SocialMediaHandle>View in Reports</SocialMediaHandle>
                </BrandMentionWrap>
              </HoverOverlay>
              <PostOverlay />
            </PostWrap>
          </CardLink>
        ))}
      </PostsGrid>
      <View margin="xl 0 0">
        <DetailList
          fields={[
            {
              label: "Name",
              value: data.booking.creator.preferredProfile?.name,
            },
            {
              label: "Handle",
              value: data.booking.creator.preferredProfile?.username,
            },
            {
              label: "Listing",
              value: data.booking.listing.name,
            },
            {
              label: "Location",
              value: data.booking.location ? data.booking.location.name : "",
            },
            {
              label: "Date of Visit",
              value: data.booking.confirmedTimeslot
                ? format(
                    fromUnixTime(data.booking.confirmedTimeslot.date),
                    "dd/MM/yyyy"
                  )
                : data.booking.redeemedAt
                ? format(fromUnixTime(data.booking.redeemedAt), "dd/MM/yyyy")
                : "",
            },
            {
              label: "Date of Completion",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            {
              label: "Feedback Score",
              value: data.booking.rating ? data.booking.rating : "",
            },
            {
              label: "Feedback Details",
              value: data.booking.feedback ? data.booking.feedback : "",
            },
          ]}
        />
      </View>
    </View>
  );
};

const Image = styled.img<{ maxHeight?: number; square: boolean }>`
  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 1 / 1;
        `}
  width: 100%;
  object-fit: cover;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : "none")};
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }
`;
