import * as React from "react";
import styled from "../../styles";

const MobileMenuIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
      clip-rule="evenodd"
      fill="#000"
    />
  </svg>
);

export const MobileMenuIcon = styled(MobileMenuIconSVG)`
  width: 2rem;
  min-width: 2rem;
  top: 6px;
  path {
    fill: ${(p) => p.theme.color.typography.secondary};
  }
`;
