import { ThemeProvider } from "styled-components";
import { useTheme } from "../hooks/useTheme";

const DarkThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DarkThemeProvider;
