import { GraphQLClient } from 'graphql-request';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  appleId: Scalars['String'];
  awaitingApprovalNotification: Scalars['Boolean'];
  brands?: Maybe<Array<Brand>>;
  createdAt: Scalars['Int'];
  creators?: Maybe<Array<Creator>>;
  email: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['Int']>;
  fbConnectionExpired: Scalars['Boolean'];
  fbTokenExpiry: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  rightContent: Scalars['Boolean'];
  rightLocation: Scalars['Boolean'];
  status: AccountStatus;
  statusLastReviewed?: Maybe<Scalars['Int']>;
  statusReviewRequestedAt?: Maybe<Scalars['Int']>;
  type: AccountType;
  wellLit: Scalars['Boolean'];
};

export enum AccountStatus {
  Active = 'ACTIVE',
  InReview = 'IN_REVIEW',
  New = 'NEW',
  Pending = 'PENDING',
  Suspicious = 'SUSPICIOUS'
}

export enum AccountType {
  AccountTypeAdmin = 'ACCOUNT_TYPE_ADMIN',
  AccountTypeStaff = 'ACCOUNT_TYPE_STAFF',
  AccountTypeUser = 'ACCOUNT_TYPE_USER'
}

export type ActivateAccountResponse = {
  __typename?: 'ActivateAccountResponse';
  account: Account;
};

export type ActiveBrandsConnection = {
  __typename?: 'ActiveBrandsConnection';
  brands: Array<Brand>;
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  totalCount: Scalars['Int'];
};

export enum ActiveBrandsSortBy {
  Closest = 'CLOSEST',
  Newest = 'NEWEST'
}

export type AddToBrandBlacklistResponse = {
  __typename?: 'AddToBrandBlacklistResponse';
  success: Scalars['Boolean'];
};

export type AddToNotificationQueueResponse = {
  __typename?: 'AddToNotificationQueueResponse';
  success: Scalars['Boolean'];
};

export type ApprovalSettings = {
  __typename?: 'ApprovalSettings';
  id: Scalars['ID'];
  minAvViews?: Maybe<Scalars['Int']>;
  minEngagementRate?: Maybe<Scalars['Int']>;
  minFollowers?: Maybe<Scalars['Int']>;
  minUkAudience?: Maybe<Scalars['Int']>;
  tier?: Maybe<CreatorTier>;
  type: ApprovalType;
  updatedAt: Scalars['Int'];
};

export type ApprovalSettingsInput = {
  minAvViews?: Maybe<Scalars['Int']>;
  minEngagementRate?: Maybe<Scalars['Int']>;
  minFollowers?: Maybe<Scalars['Int']>;
  minUkAudience?: Maybe<Scalars['Int']>;
  tier?: Maybe<CreatorTier>;
  type: ApprovalType;
};

export enum ApprovalType {
  ApprovalTypeAuto = 'APPROVAL_TYPE_AUTO',
  ApprovalTypeAutoWithCriteria = 'APPROVAL_TYPE_AUTO_WITH_CRITERIA',
  ApprovalTypeManual = 'APPROVAL_TYPE_MANUAL'
}

export type AudienceAge = {
  __typename?: 'AudienceAge';
  ageRange: Scalars['String'];
  followers: Scalars['Int'];
  gender: Gender;
  id: Scalars['ID'];
  percentage: Scalars['Float'];
  source: DemographicSource;
};

export type AudienceCity = {
  __typename?: 'AudienceCity';
  city: Scalars['String'];
  followers: Scalars['Int'];
  id: Scalars['ID'];
  percentage: Scalars['Float'];
  source: DemographicSource;
};

export type AudienceCountry = {
  __typename?: 'AudienceCountry';
  country: Scalars['String'];
  followers: Scalars['Int'];
  id: Scalars['ID'];
  percentage: Scalars['Float'];
  source: DemographicSource;
};

export type AudienceDemographics = {
  __typename?: 'AudienceDemographics';
  ages: Array<AudienceAge>;
  cities: Array<AudienceCity>;
  countries: Array<AudienceCountry>;
};

export type AudienceDemographicsResponse = {
  __typename?: 'AudienceDemographicsResponse';
  demographics?: Maybe<AudienceDemographics>;
};

export type AudienceFilters = {
  gender?: Maybe<Gender>;
};

export type AuthTokens = {
  __typename?: 'AuthTokens';
  /**
   * An access token to use to make authenticated requests to this API. This token is
   * generally quite short lived but a new one can be obtained by using the refreshToken
   */
  accessToken: Scalars['String'];
  /**
   * A token that can be used to get a new access token. This token is long-lived and
   * so should be stored securely by the client
   */
  refreshToken: Scalars['String'];
};

export enum AuthType {
  AuthTypeBrand = 'AUTH_TYPE_BRAND',
  AuthTypeCreator = 'AUTH_TYPE_CREATOR'
}

export enum AuthenticateError {
  AuthenticateIncorrectPasswordError = 'AUTHENTICATE_INCORRECT_PASSWORD_ERROR',
  AuthenticateNoAccountFoundError = 'AUTHENTICATE_NO_ACCOUNT_FOUND_ERROR'
}

export type AuthenticateResponse = {
  __typename?: 'AuthenticateResponse';
  account: Account;
  signup: Scalars['Boolean'];
  tokens: AuthTokens;
};

export enum BillingPeriod {
  BillingPeriodAnnual = 'BILLING_PERIOD_ANNUAL',
  BillingPeriodMonth = 'BILLING_PERIOD_MONTH',
  BillingPeriodQuarter = 'BILLING_PERIOD_QUARTER'
}

export type BillingPlan = {
  __typename?: 'BillingPlan';
  customerFacing: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  period: BillingPeriod;
  planType: BillingPlanType;
  price: Scalars['Int'];
};

export enum BillingPlanType {
  BillingPlanTypeBusiness = 'BILLING_PLAN_TYPE_BUSINESS',
  BillingPlanTypeCustom = 'BILLING_PLAN_TYPE_CUSTOM',
  BillingPlanTypeEssential = 'BILLING_PLAN_TYPE_ESSENTIAL',
  BillingPlanTypeManaged = 'BILLING_PLAN_TYPE_MANAGED',
  BillingPlanTypeStarter = 'BILLING_PLAN_TYPE_STARTER'
}

export enum BillingStatus {
  BillingStatusCancelled = 'BILLING_STATUS_CANCELLED',
  BillingStatusFreePlanExpired = 'BILLING_STATUS_FREE_PLAN_EXPIRED',
  BillingStatusInTrial = 'BILLING_STATUS_IN_TRIAL',
  BillingStatusNeedsPayment = 'BILLING_STATUS_NEEDS_PAYMENT',
  BillingStatusPaid = 'BILLING_STATUS_PAID'
}

export type Booking = {
  __typename?: 'Booking';
  additionalInfo?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['Int']>;
  approvedBy?: Maybe<Account>;
  bookingEvents: Array<BookingEvent>;
  bookingPosts: Array<BookingPost>;
  cancellationReason?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['Int']>;
  completionAnimationSeen: Scalars['Boolean'];
  confirmedAttendanceAt?: Maybe<Scalars['Int']>;
  confirmedTimeslot?: Maybe<BookingTimeSlot>;
  createdAt: Scalars['Int'];
  creator: Creator;
  designMyNightBookingID?: Maybe<Scalars['String']>;
  expectedPostDate?: Maybe<Scalars['Int']>;
  expiresAt: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  guests: Scalars['Int'];
  id: Scalars['ID'];
  inProgress: Scalars['Boolean'];
  isToggleBooking: Scalars['Boolean'];
  listing: Listing;
  listingDetails: Scalars['String'];
  location?: Maybe<Location>;
  offer?: Maybe<Scalars['String']>;
  platform: Platform;
  rating?: Maybe<Scalars['Int']>;
  redeemedAt?: Maybe<Scalars['Int']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  remainingContent: ContentRequirements;
  requiredPosts: Scalars['Int'];
  requiredReelAndPost: Scalars['Boolean'];
  requiredReels: Scalars['Int'];
  requiredStories: Scalars['Int'];
  requiredTikToks: Scalars['Int'];
  rescheduleReason?: Maybe<Scalars['String']>;
  rescheduledBy?: Maybe<Account>;
  status: BookingStatus;
  suggestedTimeSlots: Array<BookingTimeSlot>;
  toggleCardReference?: Maybe<Scalars['String']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
  totalInstagramPosts: Scalars['Int'];
  totalReels: Scalars['Int'];
  totalStories: Scalars['Int'];
  totalTikToks: Scalars['Int'];
  type: ListingType;
  updatedAt: Scalars['Int'];
  voucherCode?: Maybe<Scalars['String']>;
};

export type BookingEvent = {
  __typename?: 'BookingEvent';
  approvedDate?: Maybe<Scalars['Int']>;
  bookingPost?: Maybe<BookingPost>;
  description: Scalars['String'];
  id: Scalars['ID'];
  performedBy?: Maybe<Account>;
  performedByType: BookingEventPerformerType;
  timestamp: Scalars['Int'];
  type: BookingEventType;
};

export enum BookingEventPerformerType {
  Automatic = 'AUTOMATIC',
  Brand = 'BRAND',
  Creator = 'CREATOR',
  Internal = 'INTERNAL'
}

export enum BookingEventType {
  AccessCollinsBookingCancelled = 'ACCESS_COLLINS_BOOKING_CANCELLED',
  AccessCollinsBookingCreated = 'ACCESS_COLLINS_BOOKING_CREATED',
  AccessCollinsBookingUpdated = 'ACCESS_COLLINS_BOOKING_UPDATED',
  ApplicationExpired = 'APPLICATION_EXPIRED',
  Applied = 'APPLIED',
  Approved = 'APPROVED',
  BookingExpired = 'BOOKING_EXPIRED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  ContentUploaded = 'CONTENT_UPLOADED',
  CreatorConfirmationPromptSent = 'CREATOR_CONFIRMATION_PROMPT_SENT',
  CreatorConfirmedAttendance = 'CREATOR_CONFIRMED_ATTENDANCE',
  CreatorContactedVenue = 'CREATOR_CONTACTED_VENUE',
  CreatorFeedbackSubmitted = 'CREATOR_FEEDBACK_SUBMITTED',
  ExpectedPostDateSet = 'EXPECTED_POST_DATE_SET',
  Expired = 'EXPIRED',
  IssueReported = 'ISSUE_REPORTED',
  IssueResponse = 'ISSUE_RESPONSE',
  LateCancellation = 'LATE_CANCELLATION',
  LateReschedule = 'LATE_RESCHEDULE',
  Milestone = 'MILESTONE',
  NoShow = 'NO_SHOW',
  Redeemed = 'REDEEMED',
  Rejected = 'REJECTED',
  Rescheduled = 'RESCHEDULED',
  RescheduleRequested = 'RESCHEDULE_REQUESTED',
  ToggleVoucherCancelled = 'TOGGLE_VOUCHER_CANCELLED',
  ToggleVoucherCreated = 'TOGGLE_VOUCHER_CREATED'
}

export type BookingPost = {
  __typename?: 'BookingPost';
  booking: Booking;
  brand: Brand;
  comments: Scalars['Int'];
  createdAt: Scalars['Int'];
  creator: Creator;
  engagement: Scalars['Int'];
  id: Scalars['ID'];
  impressions: Scalars['Int'];
  likes: Scalars['Int'];
  mediaCaption: Scalars['String'];
  mediaId: Scalars['String'];
  mediaType: BookingPostMediaType;
  mediaUrl: Scalars['String'];
  permalink: Scalars['String'];
  postType: BookingPostType;
  reach: Scalars['Int'];
  saves: Scalars['Int'];
  shares: Scalars['Int'];
  thumbnailUrl: Scalars['String'];
};

export enum BookingPostMediaType {
  BookingPostMediaTypeImage = 'BOOKING_POST_MEDIA_TYPE_IMAGE',
  BookingPostMediaTypeVideo = 'BOOKING_POST_MEDIA_TYPE_VIDEO'
}

export enum BookingPostType {
  BookingPostTypeFeed = 'BOOKING_POST_TYPE_FEED',
  BookingPostTypeReel = 'BOOKING_POST_TYPE_REEL',
  BookingPostTypeStory = 'BOOKING_POST_TYPE_STORY',
  BookingPostTypeTiktok = 'BOOKING_POST_TYPE_TIKTOK'
}

export type BookingPostsResponse = {
  __typename?: 'BookingPostsResponse';
  bookingPosts: Array<BookingPost>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  totalComments: Scalars['Int'];
  totalLikes: Scalars['Int'];
  totalPosts: Scalars['Int'];
  totalReels: Scalars['Int'];
  totalShares: Scalars['Int'];
  totalStories: Scalars['Int'];
  totalTikToks: Scalars['Int'];
};

export enum BookingPostsSortBy {
  BookingPostsSortByCreatedAt = 'BOOKING_POSTS_SORT_BY_CREATED_AT',
  BookingPostsSortByEngagement = 'BOOKING_POSTS_SORT_BY_ENGAGEMENT',
  BookingPostsSortByImpressions = 'BOOKING_POSTS_SORT_BY_IMPRESSIONS',
  BookingPostsSortByReach = 'BOOKING_POSTS_SORT_BY_REACH'
}

export enum BookingStatus {
  BookingStatusApproved = 'BOOKING_STATUS_APPROVED',
  BookingStatusCancelled = 'BOOKING_STATUS_CANCELLED',
  BookingStatusCompleted = 'BOOKING_STATUS_COMPLETED',
  BookingStatusCreatorRescheduled = 'BOOKING_STATUS_CREATOR_RESCHEDULED',
  BookingStatusExpired = 'BOOKING_STATUS_EXPIRED',
  BookingStatusPending = 'BOOKING_STATUS_PENDING',
  BookingStatusRejected = 'BOOKING_STATUS_REJECTED',
  BookingStatusRescheduled = 'BOOKING_STATUS_RESCHEDULED'
}

export type BookingTimeSlot = {
  __typename?: 'BookingTimeSlot';
  date: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type BookingsConnection = {
  __typename?: 'BookingsConnection';
  bookings: Array<Booking>;
  cursor?: Maybe<Scalars['ID']>;
};

export type BookingsMetricsResponse = {
  __typename?: 'BookingsMetricsResponse';
  totalApplications: Scalars['Int'];
  totalBookings: Scalars['Int'];
  totalCancelledBookings: Scalars['Int'];
  totalComments: Scalars['Int'];
  totalCompletedBookings: Scalars['Int'];
  totalCreatorRescheduledBookings: Scalars['Int'];
  totalLikes: Scalars['Int'];
  totalOverdueBookings: Scalars['Int'];
  totalPendingBookings: Scalars['Int'];
  totalPosts: Scalars['Int'];
  totalReels: Scalars['Int'];
  totalRejectedBookings: Scalars['Int'];
  totalSaves: Scalars['Int'];
  totalShares: Scalars['Int'];
  totalStories: Scalars['Int'];
  totalTiktoks: Scalars['Int'];
  totalUpcomingBookings: Scalars['Int'];
};

export enum BookingsSortBy {
  BookingsSortByCompletedAt = 'BOOKINGS_SORT_BY_COMPLETED_AT',
  BookingsSortByConfirmedTimeslot = 'BOOKINGS_SORT_BY_CONFIRMED_TIMESLOT',
  BookingsSortByCreatedAtAsc = 'BOOKINGS_SORT_BY_CREATED_AT_ASC',
  BookingsSortByCreatedAtDesc = 'BOOKINGS_SORT_BY_CREATED_AT_DESC',
  BookingsSortByUpdatedAt = 'BOOKINGS_SORT_BY_UPDATED_AT'
}

export type Brand = {
  __typename?: 'Brand';
  accounts: Array<Account>;
  billingPlanId?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<BillingStatus>;
  bookingStatus: BrandBookingStatus;
  cancellationNotificationPolicy: CancellationNotificationPolicy;
  categories: Array<BrandCategory>;
  contacts: Array<BrandContact>;
  createdAt: Scalars['Int'];
  description: Scalars['String'];
  dmnBookingsEnabled: Scalars['Boolean'];
  dmnEnabled: Scalars['Boolean'];
  facebook?: Maybe<Scalars['String']>;
  fbReportingEnabled: Scalars['Boolean'];
  hasOpenBooking: Scalars['Boolean'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  instagram?: Maybe<InstagramAccount>;
  instagramHandle?: Maybe<Scalars['String']>;
  instantBook: Scalars['Boolean'];
  invites: Array<BrandInvite>;
  lastVisited?: Maybe<Scalars['Int']>;
  listings: Array<Listing>;
  locations: LocationsConnection;
  logo?: Maybe<Scalars['String']>;
  longDescription: Scalars['String'];
  minFollowers?: Maybe<MinFollowersResult>;
  name: Scalars['String'];
  numApplicationsToReview: Scalars['Int'];
  numListings: Scalars['Int'];
  numLocations: Scalars['Int'];
  similarityScore: Scalars['Float'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  tikTok?: Maybe<TikTokAccount>;
  tikTokHandle?: Maybe<Scalars['String']>;
  timeSlots: Array<TimeSlot>;
  toggleIntegrationEnabled: Scalars['Boolean'];
  toggleProducts: Array<ToggleProduct>;
  trialEndDate?: Maybe<Scalars['Int']>;
  ttReportingEnabled: Scalars['Boolean'];
  twitter?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type BrandListingsArgs = {
  center?: Maybe<Point>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
};


export type BrandLocationsArgs = {
  ascending?: Maybe<Scalars['Boolean']>;
  center?: Maybe<Point>;
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  maxDistanceKm?: Maybe<Scalars['Int']>;
};

export enum BrandBookingStatus {
  BrandBookingStatusLimitReached = 'BRAND_BOOKING_STATUS_LIMIT_REACHED',
  BrandBookingStatusOpen = 'BRAND_BOOKING_STATUS_OPEN'
}

export type BrandCategory = {
  __typename?: 'BrandCategory';
  category: Scalars['String'];
  id: Scalars['ID'];
};

export type BrandContact = {
  __typename?: 'BrandContact';
  email: Scalars['String'];
};

export type BrandInvite = {
  __typename?: 'BrandInvite';
  brand: Brand;
  email: Scalars['String'];
  id: Scalars['ID'];
  invitedBy: Account;
};

export type CancelBookingResponse = {
  __typename?: 'CancelBookingResponse';
  booking?: Maybe<Booking>;
};

export enum CancellationNotificationPolicy {
  CancellationNotificationPolicy_24Hours = 'CANCELLATION_NOTIFICATION_POLICY_24_HOURS',
  CancellationNotificationPolicyAlways = 'CANCELLATION_NOTIFICATION_POLICY_ALWAYS',
  CancellationNotificationPolicyNever = 'CANCELLATION_NOTIFICATION_POLICY_NEVER'
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ConnectToSocialResponse = {
  __typename?: 'ConnectToSocialResponse';
  account: Account;
};

export type ContentRequirements = {
  __typename?: 'ContentRequirements';
  instagramPosts: Scalars['Int'];
  instagramReels: Scalars['Int'];
  instagramStories: Scalars['Int'];
  tikToks: Scalars['Int'];
};

export type CreateBookingResponse = {
  __typename?: 'CreateBookingResponse';
  booking?: Maybe<Booking>;
};

export type CreateBrandInput = {
  categories?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type CreateListingResponse = {
  __typename?: 'CreateListingResponse';
  listing?: Maybe<Listing>;
};

export type CreateLocationResponse = {
  __typename?: 'CreateLocationResponse';
  location?: Maybe<Location>;
};

export type CreatePasskitPassResponse = {
  __typename?: 'CreatePasskitPassResponse';
  message: Scalars['String'];
  passData: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateToggleUserResponse = {
  __typename?: 'CreateToggleUserResponse';
  username: Scalars['String'];
};

export type Creator = {
  __typename?: 'Creator';
  accounts: Array<Account>;
  awaitingTierReviewNotification: Scalars['Boolean'];
  bookingPosts: Array<BookingPost>;
  categories?: Maybe<Array<Scalars['String']>>;
  demographics?: Maybe<AudienceDemographics>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  fbPageName: Scalars['String'];
  fbTokenExpiry?: Maybe<Scalars['Int']>;
  followerCount: Scalars['Int'];
  id: Scalars['ID'];
  instagram?: Maybe<InstagramAccount>;
  marketingEmailsOptedOut: Scalars['Boolean'];
  marketingPushOptedOut: Scalars['Boolean'];
  name: Scalars['String'];
  overdueBookingsCount: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredProfile?: Maybe<PreferredProfile>;
  /** This will be the highest between TikTok/Instagram */
  tier: CreatorTier;
  tierLastReviewed?: Maybe<Scalars['Int']>;
  tierReviewRequestedAt?: Maybe<Scalars['Int']>;
  tikTok?: Maybe<TikTokAccount>;
  ttConnectionExpired: Scalars['Boolean'];
  ttEnabled: Scalars['Boolean'];
  ttTokenExpiry?: Maybe<Scalars['Int']>;
};

export type CreatorConfirmBookingResponse = {
  __typename?: 'CreatorConfirmBookingResponse';
  success: Scalars['Boolean'];
};

export enum CreatorTier {
  CreatorTierBasic = 'CREATOR_TIER_BASIC',
  CreatorTierPoor = 'CREATOR_TIER_POOR',
  CreatorTierPremium = 'CREATOR_TIER_PREMIUM',
  CreatorTierStandard = 'CREATOR_TIER_STANDARD'
}

export enum Days {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum DemographicSource {
  DemographicSourceEngaged = 'DEMOGRAPHIC_SOURCE_ENGAGED',
  DemographicSourceFollowers = 'DEMOGRAPHIC_SOURCE_FOLLOWERS',
  DemographicSourceReached = 'DEMOGRAPHIC_SOURCE_REACHED'
}

export type DietaryPreference = {
  __typename?: 'DietaryPreference';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type DmnBookingType = {
  __typename?: 'DmnBookingType';
  id: Scalars['ID'];
  missingLocations: Array<Location>;
  name: Scalars['String'];
  validLocations: Array<Location>;
};

export type EmailSubscriptions = {
  __typename?: 'EmailSubscriptions';
  bookingCancelled: Scalars['Boolean'];
  bookingConfirmation: Scalars['Boolean'];
  bookingReschedule: Scalars['Boolean'];
  dailyDigest: Scalars['Boolean'];
  newApplication: Scalars['Boolean'];
};

export type ExcludedHours = {
  __typename?: 'ExcludedHours';
  dayOfWeek: Scalars['String'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type ExcludedHoursInput = {
  dayOfWeek: Scalars['String'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export enum Gender {
  GenderFemale = 'GENDER_FEMALE',
  GenderMale = 'GENDER_MALE',
  GenderOverall = 'GENDER_OVERALL',
  GenderUnknown = 'GENDER_UNKNOWN'
}

export type InstagramAccount = {
  __typename?: 'InstagramAccount';
  avatar: Scalars['String'];
  biography?: Maybe<Scalars['String']>;
  engagement28Day?: Maybe<Scalars['Int']>;
  fbId: Scalars['String'];
  followersCount?: Maybe<Scalars['Int']>;
  followsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  impressions28Day?: Maybe<Scalars['Int']>;
  mediaCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  prevImpressions28Day?: Maybe<Scalars['Int']>;
  prevReach28Day?: Maybe<Scalars['Int']>;
  reach28Day?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
};

export type InstagramBusinessAccount = {
  __typename?: 'InstagramBusinessAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
  profilePictureUrl: Scalars['String'];
  username: Scalars['String'];
};

export type LatLngWindow = {
  endLat: Scalars['Float'];
  endLng: Scalars['Float'];
  startLat: Scalars['Float'];
  startLng: Scalars['Float'];
};

export type Latlng = {
  __typename?: 'Latlng';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Listing = {
  __typename?: 'Listing';
  applicationsCount: Scalars['Int'];
  approvalSettings?: Maybe<ApprovalSettings>;
  awaitingNotification: Scalars['Boolean'];
  bookingOffset?: Maybe<Scalars['Int']>;
  bookingsCount: Scalars['Int'];
  brand: Brand;
  categories: Array<Scalars['String']>;
  collinsBookingAutoCreate: Scalars['Boolean'];
  collinsBookingCreationTypeId: Scalars['String'];
  createdAt: Scalars['Int'];
  days: Array<Days>;
  details?: Maybe<Scalars['String']>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  dmnBookingTypeID: Scalars['String'];
  endDate?: Maybe<Scalars['Int']>;
  eventTimeSlots: Array<Scalars['Int']>;
  featuredVideo: Scalars['String'];
  followerGate: Scalars['Boolean'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  instagramHandles: Array<Scalars['String']>;
  instagramTags: Array<Scalars['String']>;
  instantBook: Scalars['Boolean'];
  internalBookingNotes?: Maybe<Scalars['String']>;
  isDesignMyNightListing: Scalars['Boolean'];
  locations: Array<Location>;
  maxFollowers?: Maybe<Scalars['Int']>;
  maxSpend?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minEngagementRate?: Maybe<Scalars['Float']>;
  minFollowers?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfPeople?: Maybe<Scalars['Int']>;
  offers: Array<ListingOffer>;
  publishDate?: Maybe<Scalars['Int']>;
  queue?: Maybe<Queue>;
  requirements?: Maybe<ListingRequirements>;
  requiresPhone: Scalars['Boolean'];
  schedules: Array<Schedule>;
  startDate?: Maybe<Scalars['Int']>;
  status: ListingStatus;
  terms?: Maybe<Scalars['String']>;
  tikTokHandles: Array<Scalars['String']>;
  tikTokTags: Array<Scalars['String']>;
  timeSlot?: Maybe<Scalars['Int']>;
  timeSlots: Array<TimeSlot>;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
  type: ListingType;
  visibleDate?: Maybe<Scalars['Int']>;
};

export type ListingInvite = {
  __typename?: 'ListingInvite';
  creator: Creator;
  nearestLocation: Scalars['String'];
  nearestLocationDistance: Scalars['Int'];
  similarityScore: Scalars['Float'];
};

export type ListingInviteConnection = {
  __typename?: 'ListingInviteConnection';
  creators: Array<ListingInvite>;
  cursor?: Maybe<Scalars['ID']>;
  hasMore: Scalars['Boolean'];
  totalCount: Scalars['Int'];
};

export type ListingKeyDate = {
  __typename?: 'ListingKeyDate';
  endDate: Scalars['Int'];
  name: Scalars['String'];
  priority: Scalars['Boolean'];
  startDate: Scalars['Int'];
};

export type ListingMarker = {
  __typename?: 'ListingMarker';
  availability: Array<Days>;
  brandId: Scalars['ID'];
  brandName: Scalars['String'];
  lat: Scalars['Float'];
  listingIds: Array<Scalars['String']>;
  lng: Scalars['Float'];
  locationId: Scalars['ID'];
  locationName: Scalars['String'];
  qualified: Scalars['Boolean'];
};

export type ListingMarkersResponse = {
  __typename?: 'ListingMarkersResponse';
  markers: Array<ListingMarker>;
};

export type ListingOffer = {
  __typename?: 'ListingOffer';
  id: Scalars['ID'];
  maxFollowers?: Maybe<Scalars['Int']>;
  maxGuests: Scalars['Int'];
  minFollowers: Scalars['Int'];
  offer: Scalars['String'];
  platform: Platform;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
};

export type ListingOfferInput = {
  maxFollowers?: Maybe<Scalars['Int']>;
  maxGuests: Scalars['Int'];
  minFollowers: Scalars['Int'];
  offer: Scalars['String'];
  platform: Platform;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
};

export type ListingRequirements = {
  __typename?: 'ListingRequirements';
  requiredPosts: Scalars['Int'];
  requiredReelAndPost: Scalars['Boolean'];
  requiredReelAndTiktok: Scalars['Boolean'];
  requiredReels: Scalars['Int'];
  requiredStories: Scalars['Int'];
  requiredTikToks: Scalars['Int'];
};

export enum ListingStatus {
  StatusActive = 'STATUS_ACTIVE',
  StatusDraft = 'STATUS_DRAFT',
  StatusExpired = 'STATUS_EXPIRED',
  StatusScheduled = 'STATUS_SCHEDULED',
  StatusUnknown = 'STATUS_UNKNOWN'
}

export enum ListingType {
  AccessCollins = 'ACCESS_COLLINS',
  Event = 'EVENT',
  Ongoing = 'ONGOING',
  RedeemAnytime = 'REDEEM_ANYTIME',
  Scheduled = 'SCHEDULED'
}

export type ListingsConnection = {
  __typename?: 'ListingsConnection';
  cursor?: Maybe<Scalars['ID']>;
  listings: Array<Listing>;
  totalCount: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  availability: Array<Scalars['String']>;
  brand: Brand;
  dmnVenueId: Scalars['String'];
  email: Scalars['String'];
  excludedHours: Array<ExcludedHours>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  listings: Array<Listing>;
  lng: Scalars['Float'];
  name: Scalars['String'];
  openingHours: Array<OpeningHours>;
  phone: Scalars['String'];
  placeId: Scalars['String'];
  tikTokHandle?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['Int']>;
};

export type LocationsConnection = {
  __typename?: 'LocationsConnection';
  cursor?: Maybe<Scalars['ID']>;
  locations: Array<Location>;
  totalCount: Scalars['Int'];
};

export type MarkBookingCompletionAnimationSeenResponse = {
  __typename?: 'MarkBookingCompletionAnimationSeenResponse';
  booking?: Maybe<Booking>;
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MinFollowersResult = {
  __typename?: 'MinFollowersResult';
  all: Scalars['Int'];
  instagram: Scalars['Int'];
  tikTok: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptBrandInvite: Brand;
  activateAccount: ActivateAccountResponse;
  addToBrandBlackList: AddToBrandBlacklistResponse;
  addToNotificationQueue: AddToNotificationQueueResponse;
  authenticate: AuthenticateResponse;
  bookingApproval: Booking;
  cancelBooking: CancelBookingResponse;
  connectToFacebook: ConnectToSocialResponse;
  connectToTikTok: ConnectToSocialResponse;
  createBooking: CreateBookingResponse;
  createBookingPosts: Booking;
  createBrand: Brand;
  createBrandInvite: Brand;
  createListing: CreateListingResponse;
  createLocation: CreateLocationResponse;
  createPasskitPass: CreatePasskitPassResponse;
  createStripeCheckout: StripeResponse;
  createStripePortal: StripeResponse;
  createToggleUser: CreateToggleUserResponse;
  creatorConfirmBookingAttendance: CreatorConfirmBookingResponse;
  deleteAccount: Account;
  deleteBrandAccount: Brand;
  deleteBrandInvite: BrandInvite;
  deleteListing: Listing;
  deleteLocation: Location;
  duplicateListing: Listing;
  markBookingCompletionAnimationSeen: MarkBookingCompletionAnimationSeenResponse;
  progressBooking: Booking;
  refreshAccessToken: RefreshAccessTokenResponse;
  reportBookingIssue: ReportBookingIssueResponse;
  resendEmailVerification: Account;
  resetPassword: Account;
  sendResetPassword: Scalars['String'];
  setAppleId: Account;
  setPhoneNumber?: Maybe<SetPhoneNumberReponse>;
  setPushToken: Account;
  updateAccount: Account;
  updateBooking: Booking;
  updateBrand: Brand;
  updateCreator: Creator;
  /** refreshAuthToken(refreshToken: String!): AuthTokens! */
  updateEmailSubscription: Scalars['Boolean'];
  updateListing: Listing;
  updateLocation?: Maybe<Location>;
  updateRejectionReasons: Array<Maybe<RejectionReason>>;
  verifyEmail: Account;
};


export type MutationAcceptBrandInviteArgs = {
  token: Scalars['String'];
};


export type MutationActivateAccountArgs = {
  accessCode: Scalars['String'];
};


export type MutationAddToBrandBlackListArgs = {
  creatorId: Scalars['ID'];
};


export type MutationAddToNotificationQueueArgs = {
  accountId?: Maybe<Scalars['ID']>;
  brandId?: Maybe<Scalars['ID']>;
  listingId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  type: NotificationQueueType;
};


export type MutationAuthenticateArgs = {
  accountId?: Maybe<Scalars['String']>;
  appleCode?: Maybe<Scalars['String']>;
  authType: AuthType;
  brandInviteToken?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fbToken?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  isSignup?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  recaptchaToken?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  ttCode?: Maybe<Scalars['String']>;
};


export type MutationBookingApprovalArgs = {
  additionalInfo?: Maybe<Scalars['String']>;
  bookingId: Scalars['String'];
  rejectionMessage?: Maybe<Scalars['String']>;
  status: BookingStatus;
  timeSlotId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type MutationCancelBookingArgs = {
  bookingId: Scalars['ID'];
  cancellationReason?: Maybe<Scalars['String']>;
};


export type MutationConnectToFacebookArgs = {
  fbToken: Scalars['String'];
  instagramAccountID?: Maybe<Scalars['String']>;
};


export type MutationConnectToTikTokArgs = {
  redirectUri: Scalars['String'];
  ttCode: Scalars['String'];
};


export type MutationCreateBookingArgs = {
  creatorId: Scalars['String'];
  listingId: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  timeSlots: Array<Scalars['Int']>;
};


export type MutationCreateBookingPostsArgs = {
  bookingId: Scalars['ID'];
  instagramMediaIds: Array<Scalars['ID']>;
  storyMediaIds: Array<Scalars['ID']>;
  tikTokMediaIds: Array<Scalars['ID']>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateBrandInviteArgs = {
  brandId: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationCreateListingArgs = {
  approvalSettings: ApprovalSettingsInput;
  bookingOffset?: Maybe<Scalars['Int']>;
  brandId: Scalars['String'];
  categories: Array<Scalars['String']>;
  collinsBookingAutoCreate?: Maybe<Scalars['Boolean']>;
  collinsBookingCreationTypeID?: Maybe<Scalars['String']>;
  days: Array<Days>;
  details: Scalars['String'];
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  dmnBookingTypeID?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Int']>;
  featuredVideo: Scalars['String'];
  images: Array<Scalars['String']>;
  instagramHandles?: Maybe<Array<Scalars['String']>>;
  instagramTags?: Maybe<Array<Scalars['String']>>;
  internalBookingNotes?: Maybe<Scalars['String']>;
  locations: Array<Scalars['ID']>;
  maxFollowers?: Maybe<Scalars['Int']>;
  maxSpend?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minEngagementRate?: Maybe<Scalars['Float']>;
  minFollowers?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfPeople?: Maybe<Scalars['Int']>;
  offers: Array<ListingOfferInput>;
  requiredPostAndReel?: Maybe<Scalars['Boolean']>;
  requiredPosts?: Maybe<Scalars['Int']>;
  requiredReels?: Maybe<Scalars['Int']>;
  requiredStories?: Maybe<Scalars['Int']>;
  requiredTikToks?: Maybe<Scalars['Int']>;
  requiresPhone: Scalars['Boolean'];
  schedules?: Maybe<Array<ScheduleInput>>;
  startDate?: Maybe<Scalars['Int']>;
  status: ListingStatus;
  tikTokHandles?: Maybe<Array<Scalars['String']>>;
  tikTokTags?: Maybe<Array<Scalars['String']>>;
  timeSlots?: Maybe<Array<Scalars['Int']>>;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
  type: ListingType;
  visibleDate?: Maybe<Scalars['Int']>;
};


export type MutationCreateLocationArgs = {
  address: Scalars['String'];
  brandId: Scalars['String'];
  email: Scalars['String'];
  excludedHours?: Maybe<Array<ExcludedHoursInput>>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  name: Scalars['String'];
  openingHours?: Maybe<Array<OpeningHoursInput>>;
  phone: Scalars['String'];
  placesId: Scalars['String'];
};


export type MutationCreatePasskitPassArgs = {
  bookingId: Scalars['String'];
};


export type MutationCreateStripeCheckoutArgs = {
  additionalBrands: Scalars['Int'];
  additionalRegions: Scalars['Int'];
  billingPlanId: Scalars['ID'];
  integrations: Scalars['Int'];
  managedPlan: Scalars['Boolean'];
};


export type MutationCreateToggleUserArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCreatorConfirmBookingAttendanceArgs = {
  bookingId: Scalars['String'];
};


export type MutationDeleteBrandAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationDeleteBrandInviteArgs = {
  brandInviteId: Scalars['ID'];
};


export type MutationDeleteListingArgs = {
  listingId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  locationId: Scalars['ID'];
};


export type MutationDuplicateListingArgs = {
  id: Scalars['String'];
};


export type MutationMarkBookingCompletionAnimationSeenArgs = {
  bookingId: Scalars['ID'];
};


export type MutationProgressBookingArgs = {
  additionalInfo?: Maybe<Scalars['String']>;
  bookingId: Scalars['String'];
  internalRejectionReason?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  rescheduleReason?: Maybe<Scalars['String']>;
  status: BookingStatus;
  timeSlotId?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<Array<Scalars['Int']>>;
  token?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationReportBookingIssueArgs = {
  bookingId: Scalars['String'];
  issue: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSendResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSetAppleIdArgs = {
  appleToken: Scalars['String'];
};


export type MutationSetPhoneNumberArgs = {
  creatorId: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSetPushTokenArgs = {
  pushToken: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<AccountStatus>;
  statusReviewRequestedAt?: Maybe<Scalars['Int']>;
  type?: Maybe<AccountType>;
};


export type MutationUpdateBookingArgs = {
  bookingId: Scalars['ID'];
  expectedPostDate?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  redeemedAt?: Maybe<Scalars['Int']>;
  timeSlots?: Maybe<Array<Scalars['Int']>>;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateCreatorArgs = {
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  marketingEmailsOptedOut?: Maybe<Scalars['Boolean']>;
  marketingPushOptedOut?: Maybe<Scalars['Boolean']>;
  tierReviewRequestedAt?: Maybe<Scalars['Int']>;
};


export type MutationUpdateEmailSubscriptionArgs = {
  input: UpdateEmailSubscriptionInput;
};


export type MutationUpdateListingArgs = {
  approvalSettings?: Maybe<ApprovalSettingsInput>;
  bookingOffset?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<Scalars['String']>>;
  collinsBookingAutoCreate?: Maybe<Scalars['Boolean']>;
  collinsBookingCreationTypeID?: Maybe<Scalars['String']>;
  days?: Maybe<Array<Days>>;
  details?: Maybe<Scalars['String']>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  dmnBookingTypeID?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Int']>;
  featuredVideo: Scalars['String'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  instagramHandles?: Maybe<Array<Scalars['String']>>;
  instagramTags?: Maybe<Array<Scalars['String']>>;
  internalBookingNotes?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['ID']>>;
  maxFollowers?: Maybe<Scalars['Int']>;
  maxSpend?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minEngagementRate?: Maybe<Scalars['Float']>;
  minFollowers?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numberOfPeople?: Maybe<Scalars['Int']>;
  offers: Array<ListingOfferInput>;
  requiredPostAndReel?: Maybe<Scalars['Boolean']>;
  requiredPosts?: Maybe<Scalars['Int']>;
  requiredReels?: Maybe<Scalars['Int']>;
  requiredStories?: Maybe<Scalars['Int']>;
  requiredTikToks?: Maybe<Scalars['Int']>;
  requiresPhone: Scalars['Boolean'];
  schedules?: Maybe<Array<ScheduleInput>>;
  startDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ListingStatus>;
  terms?: Maybe<Scalars['String']>;
  tikTokHandles?: Maybe<Array<Scalars['String']>>;
  tikTokTags?: Maybe<Array<Scalars['String']>>;
  timeSlots?: Maybe<Array<Scalars['Int']>>;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
  type?: Maybe<ListingType>;
  visibleDate?: Maybe<Scalars['Int']>;
};


export type MutationUpdateLocationArgs = {
  address?: Maybe<Scalars['String']>;
  dmnVenueId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  excludedHours?: Maybe<Array<ExcludedHoursInput>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Array<OpeningHoursInput>>;
  phone?: Maybe<Scalars['String']>;
};


export type MutationUpdateRejectionReasonsArgs = {
  reasons: Array<RejectionReasonInput>;
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};

export enum NotificationQueueType {
  NotificationQueueTypeAwaitingAccountReview = 'NOTIFICATION_QUEUE_TYPE_AWAITING_ACCOUNT_REVIEW',
  NotificationQueueTypeAwaitingBrand = 'NOTIFICATION_QUEUE_TYPE_AWAITING_BRAND',
  NotificationQueueTypeAwaitingTierReview = 'NOTIFICATION_QUEUE_TYPE_AWAITING_TIER_REVIEW',
  NotificationQueueTypePendingAccount = 'NOTIFICATION_QUEUE_TYPE_PENDING_ACCOUNT'
}

export type OpeningHours = {
  __typename?: 'OpeningHours';
  closeHour: Scalars['Int'];
  closeMinute: Scalars['Int'];
  closeTime: Scalars['String'];
  dayOfWeek: Scalars['String'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  openHour: Scalars['Int'];
  openMinute: Scalars['Int'];
  openTime: Scalars['String'];
};

export type OpeningHoursInput = {
  dayOfWeek: Scalars['String'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type Place = {
  __typename?: 'Place';
  address: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  county: Scalars['String'];
  id: Scalars['ID'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  openingHours: Array<PlaceOpeningHours>;
  phone: Scalars['String'];
  postcode: Scalars['String'];
  town: Scalars['String'];
};

export type PlaceOpeningHours = {
  __typename?: 'PlaceOpeningHours';
  closeTime: Scalars['String'];
  dayOfWeek: Scalars['String'];
  openTime: Scalars['String'];
};

export type PlaceSearchResult = {
  __typename?: 'PlaceSearchResult';
  description: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  placeId: Scalars['String'];
};

export enum Platform {
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK'
}

export type Point = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Post = {
  __typename?: 'Post';
  caption: Scalars['String'];
  impressions: Scalars['Int'];
  mediaId: Scalars['ID'];
  mediaType: MediaType;
  mediaUrl: Scalars['String'];
  permalink: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  timestamp: Scalars['Int'];
  type: PostType;
};

export enum PostType {
  Feed = 'FEED',
  Reels = 'REELS',
  Story = 'STORY',
  Tiktok = 'TIKTOK'
}

export type PostsInput = {
  bookingId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  types: Array<PostType>;
};

export type PostsResponse = {
  __typename?: 'PostsResponse';
  cursor: Scalars['String'];
  posts: Array<Post>;
};

export type PreferredProfile = {
  __typename?: 'PreferredProfile';
  avatar: Scalars['String'];
  biography: Scalars['String'];
  engagement28Day: Scalars['Int'];
  followers: Scalars['Int'];
  following: Scalars['Int'];
  impressions28Day: Scalars['Int'];
  name: Scalars['String'];
  permalink: Scalars['String'];
  reach28Day: Scalars['Int'];
  type: PreferredProfileType;
  username: Scalars['String'];
};

export enum PreferredProfileType {
  PreferredProfileTypeInstagram = 'PREFERRED_PROFILE_TYPE_INSTAGRAM',
  PreferredProfileTypeTiktok = 'PREFERRED_PROFILE_TYPE_TIKTOK'
}

export enum ProgressBookingError {
  ProgressBookingDesignMyNightError = 'PROGRESS_BOOKING_DESIGN_MY_NIGHT_ERROR',
  ProgressBookingToggleError = 'PROGRESS_BOOKING_TOGGLE_ERROR'
}

export type Query = {
  __typename?: 'Query';
  account: Account;
  /** Brand with active listings */
  activeBrands: ActiveBrandsConnection;
  audienceDemographics: AudienceDemographicsResponse;
  billingPlans: Array<BillingPlan>;
  booking: Booking;
  bookingPost: BookingPost;
  bookingPosts: BookingPostsResponse;
  bookings: BookingsConnection;
  bookingsMetrics: BookingsMetricsResponse;
  brand?: Maybe<Brand>;
  brandInvite: BrandInvite;
  categories: Array<Category>;
  creator: Creator;
  dietaryPreferences: Array<DietaryPreference>;
  dmnBookingTypes: Array<DmnBookingType>;
  emailSubscriptions: EmailSubscriptions;
  featuredBrands: Array<Brand>;
  generateBookingNote: Scalars['String'];
  instagramBusinessAccounts: Array<InstagramBusinessAccount>;
  listing: Listing;
  listingInvites: ListingInviteConnection;
  listingKeyDates: Array<ListingKeyDate>;
  listingMarkers: ListingMarkersResponse;
  listings: ListingsConnection;
  location: Location;
  place: Place;
  posts: Array<Post>;
  posts2: PostsResponse;
  rejectionReasons: Array<RejectionReason>;
  reportMetrics: ReportMetricsResponse;
  searchPlaces: SearchPlacesResponse;
  timeSlots: Array<TimeSlotGroup>;
  toggleProducts: Array<ToggleProduct>;
};


export type QueryActiveBrandsArgs = {
  center?: Maybe<Point>;
  count?: Maybe<Scalars['Int']>;
  createdAfter?: Maybe<Scalars['Int']>;
  createdBefore?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  distanceKm?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<ActiveBrandsSortBy>;
};


export type QueryAudienceDemographicsArgs = {
  creatorId: Scalars['String'];
  filters?: Maybe<AudienceFilters>;
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
};


export type QueryBookingPostArgs = {
  id: Scalars['ID'];
};


export type QueryBookingPostsArgs = {
  cursor?: Maybe<Scalars['String']>;
  filters: ReportFilters;
};


export type QueryBookingsArgs = {
  ascending?: Maybe<Scalars['Boolean']>;
  bookingStartDate?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  isAwaitingContent?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<BookingsSortBy>;
  statuses?: Maybe<Array<BookingStatus>>;
};


export type QueryBookingsMetricsArgs = {
  filters: ReportFilters;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandInviteArgs = {
  token: Scalars['String'];
};


export type QueryCreatorArgs = {
  RefreshData?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryDmnBookingTypesArgs = {
  locationIDs: Array<Scalars['String']>;
  numberOfPeople: Scalars['Int'];
};


export type QueryFeaturedBrandsArgs = {
  center?: Maybe<Point>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  distanceKm?: Maybe<Scalars['Int']>;
};


export type QueryGenerateBookingNoteArgs = {
  approvedTimeslotID?: Maybe<Scalars['String']>;
  bookingId: Scalars['ID'];
};


export type QueryInstagramBusinessAccountsArgs = {
  fbToken: Scalars['String'];
};


export type QueryListingArgs = {
  id: Scalars['ID'];
};


export type QueryListingInvitesArgs = {
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  listingId?: Maybe<Scalars['String']>;
};


export type QueryListingMarkersArgs = {
  brandId?: Maybe<Scalars['String']>;
  center?: Maybe<Point>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  distanceKm?: Maybe<Scalars['Int']>;
};


export type QueryListingsArgs = {
  ascending?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['String']>;
  center?: Maybe<Point>;
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  dietaryPreferences?: Maybe<Array<Scalars['String']>>;
  distanceKm?: Maybe<Scalars['Int']>;
  publishedAfter?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryPlaceArgs = {
  id: Scalars['ID'];
};


export type QueryPostsArgs = {
  input: PostsInput;
};


export type QueryPosts2Args = {
  input: PostsInput;
};


export type QueryReportMetricsArgs = {
  filters: ReportFilters;
};


export type QuerySearchPlacesArgs = {
  input: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  returnCoords?: Maybe<Scalars['Boolean']>;
};


export type QueryTimeSlotsArgs = {
  date?: Maybe<Scalars['Int']>;
  listingId: Scalars['ID'];
  locationId: Scalars['ID'];
  type?: Maybe<TimeSlotRequestType>;
};

export type Queue = {
  __typename?: 'Queue';
  awaitingNotification: Scalars['Boolean'];
  hasRecentNotification: Scalars['Boolean'];
  lastNotifiedAt?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
};

export type RefreshAccessTokenResponse = {
  __typename?: 'RefreshAccessTokenResponse';
  accessToken: Scalars['String'];
};

export type RejectionReason = {
  __typename?: 'RejectionReason';
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type RejectionReasonInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type ReportBookingIssueResponse = {
  __typename?: 'ReportBookingIssueResponse';
  success: Scalars['Boolean'];
};

export type ReportFilters = {
  bookingID?: Maybe<Scalars['String']>;
  contentTypes?: Maybe<Array<BookingPostType>>;
  endDate?: Maybe<Scalars['Int']>;
  listingIDs?: Maybe<Array<Scalars['String']>>;
  locationIDs?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<BookingPostsSortBy>;
  startDate?: Maybe<Scalars['Int']>;
  type?: Maybe<BookingPostType>;
};

export type ReportMetric = {
  __typename?: 'ReportMetric';
  engagement: Scalars['Int'];
  impressions: Scalars['Int'];
  reach: Scalars['Int'];
  timestamp: Scalars['Int'];
};

export enum ReportMetricTimeBucket {
  ReportMetricTimeBucketDaily = 'REPORT_METRIC_TIME_BUCKET_DAILY',
  ReportMetricTimeBucketHourly = 'REPORT_METRIC_TIME_BUCKET_HOURLY',
  ReportMetricTimeBucketSixHours = 'REPORT_METRIC_TIME_BUCKET_SIX_HOURS',
  ReportMetricTimeBucketTwelveHours = 'REPORT_METRIC_TIME_BUCKET_TWELVE_HOURS',
  ReportMetricTimeBucketWeekly = 'REPORT_METRIC_TIME_BUCKET_WEEKLY'
}

export type ReportMetricsFilters = {
  brandID: Scalars['ID'];
  endDate?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
  timeBucket?: Maybe<ReportMetricTimeBucket>;
};

export type ReportMetricsResponse = {
  __typename?: 'ReportMetricsResponse';
  metrics: Array<ReportMetric>;
  prevTotalEngagement: Scalars['Int'];
  prevTotalImpressions: Scalars['Int'];
  prevTotalReach: Scalars['Int'];
  totalEngagement: Scalars['Int'];
  totalImpressions: Scalars['Int'];
  totalReach: Scalars['Int'];
  trailingMetrics: Array<ReportMetric>;
};

export type Schedule = {
  __typename?: 'Schedule';
  days: Array<Days>;
  id: Scalars['ID'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type ScheduleInput = {
  days: Array<Days>;
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type SearchPlacesResponse = {
  __typename?: 'SearchPlacesResponse';
  places: Array<PlaceSearchResult>;
};

export type SetPhoneNumberReponse = {
  __typename?: 'SetPhoneNumberReponse';
  id: Scalars['ID'];
};

export type StripeResponse = {
  __typename?: 'StripeResponse';
  redirectUrl: Scalars['String'];
};

export type TikTokAccount = {
  __typename?: 'TikTokAccount';
  avatar: Scalars['String'];
  biography: Scalars['String'];
  comments28Day?: Maybe<Scalars['Int']>;
  displayName: Scalars['String'];
  engagement28Day?: Maybe<Scalars['Int']>;
  followersCount?: Maybe<Scalars['Int']>;
  followsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  impressions28Day?: Maybe<Scalars['Int']>;
  likes28Day?: Maybe<Scalars['Int']>;
  prevImpressions28Day?: Maybe<Scalars['Int']>;
  prevReach28Day?: Maybe<Scalars['Int']>;
  reach28Day?: Maybe<Scalars['Int']>;
  shares28Day?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TimeSlotAndDate = {
  date: Scalars['Int'];
  timeslotId: Scalars['ID'];
};

export type TimeSlotGroup = {
  __typename?: 'TimeSlotGroup';
  date: Scalars['Int'];
  slots: Array<Scalars['Int']>;
};

export enum TimeSlotRequestType {
  TimeslotRequestTypeDays = 'TIMESLOT_REQUEST_TYPE_DAYS',
  TimeslotRequestTypeTimes = 'TIMESLOT_REQUEST_TYPE_TIMES'
}

export type TogglePriceModifier = {
  __typename?: 'TogglePriceModifier';
  description: Scalars['String'];
  id: Scalars['Int'];
  value: Scalars['Int'];
};

export type ToggleProduct = {
  __typename?: 'ToggleProduct';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  priceModifiers: Array<TogglePriceModifier>;
  shortDescription: Scalars['String'];
};

export type UpdateBrandInput = {
  cancellationPolicy?: Maybe<CancellationNotificationPolicy>;
  categories?: Maybe<Array<Scalars['String']>>;
  contacts?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  dmnEnabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  logo?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateEmailSubscriptionInput = {
  bookingCancelled: Scalars['Boolean'];
  bookingConfirmation: Scalars['Boolean'];
  bookingReschedule: Scalars['Boolean'];
  dailyDigest: Scalars['Boolean'];
  newApplication: Scalars['Boolean'];
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type ListingMarkersQueryVariables = Exact<{
  center?: Maybe<Point>;
  dietaryPreferences?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  distanceKm?: Maybe<Scalars['Int']>;
}>;


export type ListingMarkersQuery = (
  { __typename?: 'Query' }
  & { listingMarkers: (
    { __typename?: 'ListingMarkersResponse' }
    & { markers: Array<(
      { __typename?: 'ListingMarker' }
      & Pick<ListingMarker, 'locationId' | 'listingIds' | 'locationName' | 'brandId' | 'brandName' | 'lat' | 'lng' | 'availability' | 'qualified'>
    )> }
  ) }
);

export type AcceptBrandInviteMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AcceptBrandInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptBrandInvite: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ) }
);

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'firstName' | 'lastName' | 'email' | 'type' | 'status' | 'fbTokenExpiry' | 'emailVerifiedAt' | 'createdAt'>
    & { creators?: Maybe<Array<(
      { __typename?: 'Creator' }
      & Pick<Creator, 'name' | 'id'>
      & { instagram?: Maybe<(
        { __typename?: 'InstagramAccount' }
        & Pick<InstagramAccount, 'name' | 'username' | 'avatar' | 'followersCount' | 'reach28Day' | 'engagement28Day'>
      )>, tikTok?: Maybe<(
        { __typename?: 'TikTokAccount' }
        & Pick<TikTokAccount, 'username' | 'displayName' | 'reach28Day' | 'engagement28Day'>
      )>, preferredProfile?: Maybe<(
        { __typename?: 'PreferredProfile' }
        & Pick<PreferredProfile, 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day'>
      )> }
    )>>, brands?: Maybe<Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name' | 'logo' | 'dmnEnabled' | 'numApplicationsToReview' | 'createdAt'>
      & { tikTok?: Maybe<(
        { __typename?: 'TikTokAccount' }
        & Pick<TikTokAccount, 'avatar'>
      )>, instagram?: Maybe<(
        { __typename?: 'InstagramAccount' }
        & Pick<InstagramAccount, 'name' | 'username' | 'avatar' | 'followersCount' | 'reach28Day' | 'engagement28Day'>
      )>, locations: (
        { __typename?: 'LocationsConnection' }
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'id'>
        )> }
      ), categories: Array<(
        { __typename?: 'BrandCategory' }
        & Pick<BrandCategory, 'id' | 'category'>
      )> }
    )>> }
  ) }
);

export type ActivateAccountMutationVariables = Exact<{
  accessCode: Scalars['String'];
}>;


export type ActivateAccountMutation = (
  { __typename?: 'Mutation' }
  & { activateAccount: (
    { __typename?: 'ActivateAccountResponse' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'status'>
    ) }
  ) }
);

export type AddToBrandBlackListMutationVariables = Exact<{
  creatorId: Scalars['ID'];
}>;


export type AddToBrandBlackListMutation = (
  { __typename?: 'Mutation' }
  & { addToBrandBlackList: (
    { __typename?: 'AddToBrandBlacklistResponse' }
    & Pick<AddToBrandBlacklistResponse, 'success'>
  ) }
);

export type AddToNotificationQueueMutationVariables = Exact<{
  brandId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  listingId?: Maybe<Scalars['ID']>;
  type: NotificationQueueType;
}>;


export type AddToNotificationQueueMutation = (
  { __typename?: 'Mutation' }
  & { addToNotificationQueue: (
    { __typename?: 'AddToNotificationQueueResponse' }
    & Pick<AddToNotificationQueueResponse, 'success'>
  ) }
);

export type BillingQueryVariables = Exact<{
  brandID: Scalars['ID'];
}>;


export type BillingQuery = (
  { __typename?: 'Query' }
  & { billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period' | 'customerFacing'>
  )>, brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'billingStatus' | 'billingPlanId' | 'stripeCustomerId'>
  )> }
);

export type BillingBannerQueryVariables = Exact<{
  brandID: Scalars['ID'];
}>;


export type BillingBannerQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'billingStatus'>
  )> }
);

export type BookingApprovalMutationVariables = Exact<{
  bookingId: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  status: BookingStatus;
  timeSlotId?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
}>;


export type BookingApprovalMutation = (
  { __typename?: 'Mutation' }
  & { bookingApproval: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
  ) }
);

export type GetBookingPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingPostQuery = (
  { __typename?: 'Query' }
  & { bookingPost: (
    { __typename?: 'BookingPost' }
    & Pick<BookingPost, 'createdAt' | 'engagement' | 'impressions' | 'reach' | 'likes' | 'comments' | 'saves' | 'shares' | 'mediaUrl' | 'mediaCaption' | 'mediaType' | 'postType' | 'permalink' | 'thumbnailUrl'>
    & { creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'name'>
      & { preferredProfile?: Maybe<(
        { __typename?: 'PreferredProfile' }
        & Pick<PreferredProfile, 'biography' | 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day' | 'permalink' | 'type'>
      )>, instagram?: Maybe<(
        { __typename?: 'InstagramAccount' }
        & Pick<InstagramAccount, 'name' | 'avatar' | 'username'>
      )>, tikTok?: Maybe<(
        { __typename?: 'TikTokAccount' }
        & Pick<TikTokAccount, 'displayName' | 'avatar' | 'username'>
      )> }
    ) }
  ) }
);

export type BrandBillingQueryVariables = Exact<{
  brandID: Scalars['ID'];
}>;


export type BrandBillingQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'stripeCustomerId' | 'billingPlanId' | 'billingStatus' | 'numLocations' | 'numListings'>
  )>, billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period'>
  )> }
);

export type BrandBookingsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<BookingStatus> | BookingStatus>;
  isAwaitingContent?: Maybe<Scalars['Boolean']>;
  count: Scalars['Int'];
  sortBy?: Maybe<BookingsSortBy>;
  bookingStartDate?: Maybe<Scalars['Int']>;
}>;


export type BrandBookingsQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'cursor'>
    & { bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'type' | 'rating' | 'feedback' | 'status' | 'inProgress' | 'createdAt' | 'updatedAt' | 'expiresAt' | 'redeemedAt' | 'approvedAt' | 'completedAt'>
      & { listing: (
        { __typename?: 'Listing' }
        & Pick<Listing, 'name'>
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      )>, confirmedTimeslot?: Maybe<(
        { __typename?: 'BookingTimeSlot' }
        & Pick<BookingTimeSlot, 'date' | 'title'>
      )>, creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'name'>
        & { preferredProfile?: Maybe<(
          { __typename?: 'PreferredProfile' }
          & Pick<PreferredProfile, 'biography' | 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day' | 'permalink' | 'type'>
        )> }
      ) }
    )> }
  ) }
);

export type BrandBookingNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandBookingNotesQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'type'>
  ), bookings: (
    { __typename?: 'BookingsConnection' }
    & { bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'createdAt' | 'additionalInfo'>
      & { listing: (
        { __typename?: 'Listing' }
        & Pick<Listing, 'id'>
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id'>
      )> }
    )> }
  ) }
);

export type BrandInviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type BrandInviteQuery = (
  { __typename?: 'Query' }
  & { brandInvite: (
    { __typename?: 'BrandInvite' }
    & Pick<BrandInvite, 'email'>
    & { invitedBy: (
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName'>
    ), brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name' | 'logo' | 'description'>
      & { accounts: Array<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'firstName'>
      )> }
    ) }
  ) }
);

export type BrandListingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BrandListingQuery = (
  { __typename?: 'Query' }
  & { billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period'>
  )>, listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id' | 'name' | 'details' | 'terms' | 'maxSpend' | 'startDate' | 'endDate' | 'status' | 'minFollowers' | 'maxFollowers' | 'minEngagementRate' | 'images' | 'type' | 'dmnBookingTypeID' | 'collinsBookingAutoCreate' | 'internalBookingNotes' | 'publishDate' | 'numberOfPeople' | 'eventTimeSlots' | 'collinsBookingCreationTypeId' | 'instagramHandles' | 'tikTokHandles' | 'instagramTags' | 'tikTokTags' | 'minAge' | 'featuredVideo' | 'visibleDate' | 'days' | 'categories' | 'dietaryPreferences' | 'bookingOffset' | 'toggleProductId' | 'toggleProductValue' | 'requiresPhone' | 'applicationsCount' | 'timeSlot'>
    & { requirements?: Maybe<(
      { __typename?: 'ListingRequirements' }
      & Pick<ListingRequirements, 'requiredPosts' | 'requiredReels' | 'requiredReelAndPost' | 'requiredStories' | 'requiredTikToks'>
    )>, offers: Array<(
      { __typename?: 'ListingOffer' }
      & Pick<ListingOffer, 'minFollowers' | 'maxFollowers' | 'maxGuests' | 'offer' | 'platform' | 'toggleProductId' | 'toggleProductValue'>
    )>, approvalSettings?: Maybe<(
      { __typename?: 'ApprovalSettings' }
      & Pick<ApprovalSettings, 'id' | 'type' | 'minFollowers' | 'minEngagementRate' | 'minUkAudience' | 'tier' | 'minAvViews'>
    )>, brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'toggleIntegrationEnabled' | 'billingStatus' | 'billingPlanId'>
      & { locations: (
        { __typename?: 'LocationsConnection' }
        & { locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'id' | 'name'>
        )> }
      ) }
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, schedules: Array<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'id' | 'days' | 'startHour' | 'startMinute'>
    )> }
  ) }
);

export type BrandListingsQueryVariables = Exact<{
  brandId?: Maybe<Scalars['String']>;
}>;


export type BrandListingsQuery = (
  { __typename?: 'Query' }
  & { listings: (
    { __typename?: 'ListingsConnection' }
    & { listings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'name' | 'startDate' | 'publishDate' | 'endDate' | 'status' | 'minFollowers' | 'maxFollowers' | 'bookingsCount' | 'applicationsCount' | 'type' | 'eventTimeSlots'>
      & { locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type BrandLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BrandLocationQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'name' | 'id' | 'lat' | 'lng' | 'address' | 'email' | 'phone' | 'dmnVenueId' | 'instagramHandle'>
    & { openingHours: Array<(
      { __typename?: 'OpeningHours' }
      & Pick<OpeningHours, 'dayOfWeek' | 'openHour' | 'openMinute' | 'closeHour' | 'closeMinute'>
    )>, excludedHours: Array<(
      { __typename?: 'ExcludedHours' }
      & Pick<ExcludedHours, 'id' | 'dayOfWeek' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
    )> }
  ) }
);

export type BrandLocationsQueryVariables = Exact<{
  brandId: Scalars['ID'];
}>;


export type BrandLocationsQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'toggleIntegrationEnabled'>
    & { locations: (
      { __typename?: 'LocationsConnection' }
      & { locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'name' | 'id' | 'lat' | 'lng' | 'address' | 'email' | 'phone' | 'dmnVenueId'>
        & { openingHours: Array<(
          { __typename?: 'OpeningHours' }
          & Pick<OpeningHours, 'dayOfWeek'>
        )> }
      )> }
    ) }
  )> }
);

export type BrandNotificationCountQueryVariables = Exact<{
  brandID: Scalars['ID'];
}>;


export type BrandNotificationCountQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'numApplicationsToReview'>
  )> }
);

export type BrandScheduleSnapshotQueryVariables = Exact<{
  statuses?: Maybe<Array<BookingStatus> | BookingStatus>;
  count: Scalars['Int'];
}>;


export type BrandScheduleSnapshotQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'cursor'>
    & { bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'type' | 'rating' | 'feedback' | 'status' | 'inProgress' | 'createdAt' | 'updatedAt' | 'expiresAt' | 'redeemedAt' | 'approvedAt'>
      & { confirmedTimeslot?: Maybe<(
        { __typename?: 'BookingTimeSlot' }
        & Pick<BookingTimeSlot, 'date' | 'title'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      )>, creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'name'>
        & { preferredProfile?: Maybe<(
          { __typename?: 'PreferredProfile' }
          & Pick<PreferredProfile, 'biography' | 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day' | 'permalink' | 'type'>
        )> }
      ) }
    )> }
  ) }
);

export type CancelBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  cancellationReason?: Maybe<Scalars['String']>;
}>;


export type CancelBookingMutation = (
  { __typename?: 'Mutation' }
  & { cancelBooking: (
    { __typename?: 'CancelBookingResponse' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )> }
  ) }
);

export type ConnectToFacebookMutationVariables = Exact<{
  fbToken: Scalars['String'];
  instagramAccountId?: Maybe<Scalars['String']>;
}>;


export type ConnectToFacebookMutation = (
  { __typename?: 'Mutation' }
  & { connectToFacebook: (
    { __typename?: 'ConnectToSocialResponse' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    ) }
  ) }
);

export type ConnectToTikTokMutationVariables = Exact<{
  ttCode: Scalars['String'];
  redirectUri: Scalars['String'];
}>;


export type ConnectToTikTokMutation = (
  { __typename?: 'Mutation' }
  & { connectToTikTok: (
    { __typename?: 'ConnectToSocialResponse' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'status'>
    ) }
  ) }
);

export type CreateBookingMutationVariables = Exact<{
  creatorId: Scalars['String'];
  listingId: Scalars['String'];
  locationId: Scalars['String'];
  timeSlots: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateBookingMutation = (
  { __typename?: 'Mutation' }
  & { createBooking: (
    { __typename?: 'CreateBookingResponse' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    )> }
  ) }
);

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = (
  { __typename?: 'Mutation' }
  & { createBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ) }
);

export type CreateBrandInviteMutationVariables = Exact<{
  brandId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type CreateBrandInviteMutation = (
  { __typename?: 'Mutation' }
  & { createBrandInvite: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id'>
    & { invites: Array<(
      { __typename?: 'BrandInvite' }
      & Pick<BrandInvite, 'email'>
    )> }
  ) }
);

export type CreateListingMutationVariables = Exact<{
  brandId: Scalars['String'];
  name: Scalars['String'];
  details: Scalars['String'];
  maxSpend?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  status: ListingStatus;
  locations: Array<Scalars['ID']> | Scalars['ID'];
  days: Array<Days> | Days;
  bookingOffset?: Maybe<Scalars['Int']>;
  minFollowers?: Maybe<Scalars['Int']>;
  maxFollowers?: Maybe<Scalars['Int']>;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
  requiresPhone: Scalars['Boolean'];
  timeSlots?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  schedules?: Maybe<Array<ScheduleInput> | ScheduleInput>;
  images: Array<Scalars['String']> | Scalars['String'];
  type: ListingType;
  approvalSettings: ApprovalSettingsInput;
  dmnBookingTypeID?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']> | Scalars['String'];
  minEngagementRate?: Maybe<Scalars['Float']>;
  dietaryPreferences?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  numberOfPeople?: Maybe<Scalars['Int']>;
  internalBookingNotes?: Maybe<Scalars['String']>;
  collinsBookingAutoCreate?: Maybe<Scalars['Boolean']>;
  instagramHandles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tikTokHandles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  instagramTags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  requiredPosts?: Maybe<Scalars['Int']>;
  requiredReels?: Maybe<Scalars['Int']>;
  requiredPostAndReel?: Maybe<Scalars['Boolean']>;
  requiredStories?: Maybe<Scalars['Int']>;
  requiredTikToks?: Maybe<Scalars['Int']>;
  tikTokTags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  collinsBookingCreationTypeID?: Maybe<Scalars['String']>;
  minAge?: Maybe<Scalars['Int']>;
  featuredVideo: Scalars['String'];
  offers: Array<ListingOfferInput> | ListingOfferInput;
  visibleDate?: Maybe<Scalars['Int']>;
}>;


export type CreateListingMutation = (
  { __typename?: 'Mutation' }
  & { createListing: (
    { __typename?: 'CreateListingResponse' }
    & { listing?: Maybe<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id'>
    )> }
  ) }
);

export type CreateLocationMutationVariables = Exact<{
  brandId: Scalars['String'];
  name: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  address: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  placesId: Scalars['String'];
  excludedHours?: Maybe<Array<ExcludedHoursInput> | ExcludedHoursInput>;
  openingHours?: Maybe<Array<OpeningHoursInput> | OpeningHoursInput>;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'CreateLocationResponse' }
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
    )> }
  ) }
);

export type CreateStripeCheckoutMutationVariables = Exact<{
  additionalBrands: Scalars['Int'];
  additionalRegions: Scalars['Int'];
  managedPlan: Scalars['Boolean'];
  integrations: Scalars['Int'];
  billingPlanId: Scalars['ID'];
}>;


export type CreateStripeCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { createStripeCheckout: (
    { __typename?: 'StripeResponse' }
    & Pick<StripeResponse, 'redirectUrl'>
  ) }
);

export type CreateStripePortalMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripePortalMutation = (
  { __typename?: 'Mutation' }
  & { createStripePortal: (
    { __typename?: 'StripeResponse' }
    & Pick<StripeResponse, 'redirectUrl'>
  ) }
);

export type CreateToggleUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateToggleUserMutation = (
  { __typename?: 'Mutation' }
  & { createToggleUser: (
    { __typename?: 'CreateToggleUserResponse' }
    & Pick<CreateToggleUserResponse, 'username'>
  ) }
);

export type DeleteBrandAccountMutationVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type DeleteBrandAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrandAccount: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id'>
  ) }
);

export type DeleteBrandInviteMutationVariables = Exact<{
  brandInviteId: Scalars['ID'];
}>;


export type DeleteBrandInviteMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrandInvite: (
    { __typename?: 'BrandInvite' }
    & Pick<BrandInvite, 'id'>
  ) }
);

export type DeleteListingMutationVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type DeleteListingMutation = (
  { __typename?: 'Mutation' }
  & { deleteListing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
  ) }
);

export type DeleteLocationMutationVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type DietaryPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type DietaryPreferencesQuery = (
  { __typename?: 'Query' }
  & { dietaryPreferences: Array<(
    { __typename?: 'DietaryPreference' }
    & Pick<DietaryPreference, 'id' | 'label'>
  )> }
);

export type DmnBookingTypesQueryVariables = Exact<{
  locationIDs: Array<Scalars['String']> | Scalars['String'];
  numberOfPeople: Scalars['Int'];
}>;


export type DmnBookingTypesQuery = (
  { __typename?: 'Query' }
  & { dmnBookingTypes: Array<(
    { __typename?: 'DmnBookingType' }
    & Pick<DmnBookingType, 'id' | 'name'>
    & { missingLocations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, validLocations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )> }
  )> }
);

export type DuplicateListingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DuplicateListingMutation = (
  { __typename?: 'Mutation' }
  & { duplicateListing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
  ) }
);

export type EmailSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { emailSubscriptions: (
    { __typename?: 'EmailSubscriptions' }
    & Pick<EmailSubscriptions, 'dailyDigest' | 'newApplication' | 'bookingConfirmation' | 'bookingCancelled' | 'bookingReschedule'>
  ) }
);

export type GenerateBookingNoteQueryVariables = Exact<{
  bookingId: Scalars['ID'];
  approvedTimeslot?: Maybe<Scalars['String']>;
}>;


export type GenerateBookingNoteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateBookingNote'>
);

export type GetBookingQueryVariables = Exact<{
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
}>;


export type GetBookingQuery = (
  { __typename?: 'Query' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'createdAt' | 'updatedAt' | 'inProgress' | 'additionalInfo' | 'toggleCardReference' | 'rescheduleReason' | 'cancellationReason' | 'rejectionMessage' | 'type' | 'rating' | 'feedback' | 'approvedAt' | 'redeemedAt' | 'isToggleBooking' | 'toggleProductValue' | 'platform'>
    & { approvedBy?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName'>
    )>, remainingContent: (
      { __typename?: 'ContentRequirements' }
      & Pick<ContentRequirements, 'instagramPosts' | 'instagramStories' | 'instagramReels' | 'tikToks'>
    ), suggestedTimeSlots: Array<(
      { __typename?: 'BookingTimeSlot' }
      & Pick<BookingTimeSlot, 'id' | 'date' | 'title' | 'description'>
    )>, confirmedTimeslot?: Maybe<(
      { __typename?: 'BookingTimeSlot' }
      & Pick<BookingTimeSlot, 'id' | 'date' | 'title' | 'description'>
    )>, creator: (
      { __typename?: 'Creator' }
      & Pick<Creator, 'id' | 'name' | 'phoneNumber' | 'ttConnectionExpired' | 'fbTokenExpiry'>
      & { instagram?: Maybe<(
        { __typename?: 'InstagramAccount' }
        & Pick<InstagramAccount, 'name' | 'username' | 'biography' | 'avatar' | 'mediaCount' | 'followersCount' | 'followsCount' | 'reach28Day' | 'prevReach28Day' | 'impressions28Day' | 'prevImpressions28Day' | 'engagement28Day'>
      )>, tikTok?: Maybe<(
        { __typename?: 'TikTokAccount' }
        & Pick<TikTokAccount, 'displayName' | 'username' | 'reach28Day' | 'impressions28Day' | 'prevImpressions28Day' | 'engagement28Day' | 'likes28Day' | 'comments28Day' | 'shares28Day' | 'avatar' | 'followersCount' | 'biography'>
      )>, preferredProfile?: Maybe<(
        { __typename?: 'PreferredProfile' }
        & Pick<PreferredProfile, 'biography' | 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day' | 'permalink' | 'type'>
      )>, demographics?: Maybe<(
        { __typename?: 'AudienceDemographics' }
        & { cities: Array<(
          { __typename?: 'AudienceCity' }
          & Pick<AudienceCity, 'id' | 'city' | 'percentage' | 'source'>
        )>, countries: Array<(
          { __typename?: 'AudienceCountry' }
          & Pick<AudienceCountry, 'id' | 'country' | 'percentage' | 'source'>
        )>, ages: Array<(
          { __typename?: 'AudienceAge' }
          & Pick<AudienceAge, 'id' | 'ageRange' | 'gender' | 'percentage' | 'source'>
        )> }
      )>, accounts: Array<(
        { __typename?: 'Account' }
        & Pick<Account, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      )> }
    ), listing: (
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'days' | 'name' | 'details' | 'maxSpend' | 'minFollowers' | 'status' | 'terms' | 'startDate' | 'endDate' | 'toggleProductValue' | 'bookingOffset' | 'isDesignMyNightListing'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'name' | 'id' | 'logo' | 'url'>
      ) }
    ), location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'email' | 'phone' | 'address' | 'lat' | 'lng' | 'placeId' | 'images'>
      & { openingHours: Array<(
        { __typename?: 'OpeningHours' }
        & Pick<OpeningHours, 'id' | 'dayOfWeek' | 'openHour' | 'openMinute' | 'closeHour' | 'closeMinute'>
      )>, excludedHours: Array<(
        { __typename?: 'ExcludedHours' }
        & Pick<ExcludedHours, 'id' | 'dayOfWeek' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
      )> }
    )>, bookingPosts: Array<(
      { __typename?: 'BookingPost' }
      & Pick<BookingPost, 'id' | 'createdAt' | 'impressions' | 'reach' | 'engagement' | 'likes' | 'comments' | 'saves' | 'shares' | 'mediaUrl' | 'mediaCaption' | 'mediaType' | 'postType' | 'permalink' | 'thumbnailUrl'>
    )>, bookingEvents: Array<(
      { __typename?: 'BookingEvent' }
      & Pick<BookingEvent, 'id' | 'timestamp' | 'type' | 'description' | 'performedByType' | 'approvedDate'>
      & { performedBy?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'firstName' | 'lastName' | 'email'>
      )>, bookingPost?: Maybe<(
        { __typename?: 'BookingPost' }
        & Pick<BookingPost, 'id' | 'postType'>
      )> }
    )> }
  ) }
);

export type GetBrandQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBrandQuery = (
  { __typename?: 'Query' }
  & { billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period'>
  )>, brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'name' | 'description' | 'longDescription' | 'fbReportingEnabled' | 'dmnEnabled' | 'dmnBookingsEnabled' | 'ttReportingEnabled' | 'toggleIntegrationEnabled' | 'instagramHandle' | 'cancellationNotificationPolicy' | 'logo' | 'billingStatus' | 'billingPlanId' | 'images'>
    & { categories: Array<(
      { __typename?: 'BrandCategory' }
      & Pick<BrandCategory, 'category' | 'id'>
    )>, instagram?: Maybe<(
      { __typename?: 'InstagramAccount' }
      & Pick<InstagramAccount, 'avatar' | 'name'>
    )>, tikTok?: Maybe<(
      { __typename?: 'TikTokAccount' }
      & Pick<TikTokAccount, 'avatar' | 'displayName'>
    )>, accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'email' | 'firstName' | 'lastName' | 'type'>
    )>, invites: Array<(
      { __typename?: 'BrandInvite' }
      & Pick<BrandInvite, 'id' | 'email'>
    )> }
  )> }
);

export type GetBrandBillingStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBrandBillingStatusQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'billingStatus' | 'billingPlanId'>
  )>, billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period'>
  )> }
);

export type BrandContentQueryVariables = Exact<{
  filters: ReportFilters;
  brandID: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
}>;


export type BrandContentQuery = (
  { __typename?: 'Query' }
  & { bookingPosts: (
    { __typename?: 'BookingPostsResponse' }
    & Pick<BookingPostsResponse, 'hasMore' | 'cursor'>
    & { bookingPosts: Array<(
      { __typename?: 'BookingPost' }
      & Pick<BookingPost, 'id' | 'createdAt' | 'impressions' | 'reach' | 'engagement' | 'mediaUrl' | 'mediaCaption' | 'mediaType' | 'postType' | 'permalink' | 'thumbnailUrl'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'name'>
        & { instagram?: Maybe<(
          { __typename?: 'InstagramAccount' }
          & Pick<InstagramAccount, 'name' | 'username'>
        )>, tikTok?: Maybe<(
          { __typename?: 'TikTokAccount' }
          & Pick<TikTokAccount, 'displayName' | 'username'>
        )> }
      ) }
    )> }
  ), brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'fbReportingEnabled' | 'billingPlanId' | 'bookingStatus' | 'billingStatus'>
  )>, billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period'>
  )> }
);

export type GetBrandHandlesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBrandHandlesQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'instagramHandle' | 'tikTokHandle'>
  )> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'label'>
  )> }
);

export type GetCreatorQueryVariables = Exact<{
  id: Scalars['ID'];
  refreshData?: Maybe<Scalars['Boolean']>;
}>;


export type GetCreatorQuery = (
  { __typename?: 'Query' }
  & { creator: (
    { __typename?: 'Creator' }
    & Pick<Creator, 'id' | 'name' | 'phoneNumber' | 'ttEnabled' | 'followerCount' | 'tier'>
    & { tikTok?: Maybe<(
      { __typename?: 'TikTokAccount' }
      & Pick<TikTokAccount, 'id' | 'displayName' | 'username' | 'reach28Day' | 'engagement28Day' | 'likes28Day' | 'comments28Day' | 'shares28Day' | 'avatar' | 'followersCount' | 'biography'>
    )>, instagram?: Maybe<(
      { __typename?: 'InstagramAccount' }
      & Pick<InstagramAccount, 'id' | 'name' | 'username' | 'avatar' | 'biography' | 'mediaCount' | 'followersCount' | 'followsCount' | 'reach28Day' | 'impressions28Day' | 'engagement28Day'>
    )>, accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'id' | 'lastName' | 'email' | 'type' | 'status' | 'createdAt' | 'fbTokenExpiry' | 'awaitingApprovalNotification' | 'wellLit' | 'rightContent' | 'rightLocation'>
    )> }
  ) }
);

export type GetDeclineReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeclineReasonsQuery = (
  { __typename?: 'Query' }
  & { rejectionReasons: Array<(
    { __typename?: 'RejectionReason' }
    & Pick<RejectionReason, 'id' | 'reason'>
  )> }
);

export type GetListingInvitesQueryVariables = Exact<{
  listingId?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
}>;


export type GetListingInvitesQuery = (
  { __typename?: 'Query' }
  & { listingInvites: (
    { __typename?: 'ListingInviteConnection' }
    & Pick<ListingInviteConnection, 'hasMore' | 'cursor'>
    & { creators: Array<(
      { __typename?: 'ListingInvite' }
      & Pick<ListingInvite, 'nearestLocation' | 'nearestLocationDistance' | 'similarityScore'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'dietaryPreferences' | 'categories'>
        & { preferredProfile?: Maybe<(
          { __typename?: 'PreferredProfile' }
          & Pick<PreferredProfile, 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day' | 'permalink'>
        )>, accounts: Array<(
          { __typename?: 'Account' }
          & Pick<Account, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
        )> }
      ) }
    )> }
  ) }
);

export type GetLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'email' | 'phone' | 'address' | 'lat' | 'placeId' | 'lng' | 'utcOffset' | 'images'>
    & { openingHours: Array<(
      { __typename?: 'OpeningHours' }
      & Pick<OpeningHours, 'id' | 'dayOfWeek' | 'openHour' | 'openMinute' | 'closeHour' | 'closeMinute'>
    )>, excludedHours: Array<(
      { __typename?: 'ExcludedHours' }
      & Pick<ExcludedHours, 'id' | 'dayOfWeek' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
    )>, listings: Array<(
      { __typename?: 'Listing' }
      & Pick<Listing, 'id' | 'name' | 'details' | 'maxSpend' | 'status' | 'terms' | 'startDate' | 'endDate' | 'days' | 'bookingOffset' | 'requiresPhone' | 'followerGate' | 'isDesignMyNightListing' | 'minFollowers' | 'awaitingNotification'>
      & { timeSlots: Array<(
        { __typename?: 'TimeSlot' }
        & Pick<TimeSlot, 'id' | 'title' | 'description'>
      )>, brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'name' | 'id' | 'logo' | 'url'>
      ) }
    )>, brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'name' | 'id' | 'images' | 'bookingStatus' | 'description'>
      & { instagram?: Maybe<(
        { __typename?: 'InstagramAccount' }
        & Pick<InstagramAccount, 'name' | 'username'>
      )> }
    ) }
  ) }
);

export type ReportsQueryVariables = Exact<{
  filters: ReportFilters;
  brandID: Scalars['ID'];
}>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { bookingPosts: (
    { __typename?: 'BookingPostsResponse' }
    & { bookingPosts: Array<(
      { __typename?: 'BookingPost' }
      & Pick<BookingPost, 'id' | 'createdAt' | 'impressions' | 'reach' | 'engagement' | 'mediaUrl' | 'mediaCaption' | 'mediaType' | 'postType' | 'permalink' | 'thumbnailUrl'>
      & { creator: (
        { __typename?: 'Creator' }
        & Pick<Creator, 'id' | 'name'>
        & { instagram?: Maybe<(
          { __typename?: 'InstagramAccount' }
          & Pick<InstagramAccount, 'name' | 'username'>
        )>, tikTok?: Maybe<(
          { __typename?: 'TikTokAccount' }
          & Pick<TikTokAccount, 'displayName' | 'username'>
        )> }
      ) }
    )> }
  ), bookingsMetrics: (
    { __typename?: 'BookingsMetricsResponse' }
    & Pick<BookingsMetricsResponse, 'totalApplications' | 'totalBookings' | 'totalRejectedBookings' | 'totalCompletedBookings' | 'totalUpcomingBookings' | 'totalOverdueBookings' | 'totalCancelledBookings' | 'totalReels' | 'totalStories' | 'totalPosts' | 'totalLikes' | 'totalComments' | 'totalShares' | 'totalSaves' | 'totalTiktoks'>
  ), reportMetrics: (
    { __typename?: 'ReportMetricsResponse' }
    & Pick<ReportMetricsResponse, 'totalImpressions' | 'totalReach' | 'totalEngagement' | 'prevTotalImpressions' | 'prevTotalReach' | 'prevTotalEngagement'>
    & { metrics: Array<(
      { __typename?: 'ReportMetric' }
      & Pick<ReportMetric, 'timestamp' | 'impressions' | 'engagement' | 'reach'>
    )>, trailingMetrics: Array<(
      { __typename?: 'ReportMetric' }
      & Pick<ReportMetric, 'timestamp' | 'impressions' | 'engagement' | 'reach'>
    )> }
  ), brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'fbReportingEnabled' | 'billingPlanId' | 'bookingStatus'>
  )>, billingPlans: Array<(
    { __typename?: 'BillingPlan' }
    & Pick<BillingPlan, 'id' | 'name' | 'price' | 'planType' | 'period'>
  )> }
);

export type InstagramBusinessAccountsQueryVariables = Exact<{
  fbToken: Scalars['String'];
}>;


export type InstagramBusinessAccountsQuery = (
  { __typename?: 'Query' }
  & { instagramBusinessAccounts: Array<(
    { __typename?: 'InstagramBusinessAccount' }
    & Pick<InstagramBusinessAccount, 'id' | 'name' | 'username' | 'profilePictureUrl'>
  )> }
);

export type ListingKeyDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListingKeyDatesQuery = (
  { __typename?: 'Query' }
  & { listingKeyDates: Array<(
    { __typename?: 'ListingKeyDate' }
    & Pick<ListingKeyDate, 'name' | 'startDate' | 'endDate' | 'priority'>
  )> }
);

export type LoginMutationVariables = Exact<{
  fbToken?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  authType: AuthType;
  brandInviteToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isSignup?: Maybe<Scalars['Boolean']>;
  recaptchaToken?: Maybe<Scalars['String']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { authenticate: (
    { __typename?: 'AuthenticateResponse' }
    & Pick<AuthenticateResponse, 'signup'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'firstName' | 'lastName' | 'email' | 'type' | 'createdAt' | 'status' | 'fbTokenExpiry' | 'emailVerifiedAt'>
      & { creators?: Maybe<Array<(
        { __typename?: 'Creator' }
        & Pick<Creator, 'name' | 'id'>
        & { instagram?: Maybe<(
          { __typename?: 'InstagramAccount' }
          & Pick<InstagramAccount, 'name' | 'username' | 'avatar' | 'followersCount' | 'reach28Day' | 'engagement28Day'>
        )>, tikTok?: Maybe<(
          { __typename?: 'TikTokAccount' }
          & Pick<TikTokAccount, 'displayName' | 'username' | 'reach28Day' | 'engagement28Day'>
        )>, preferredProfile?: Maybe<(
          { __typename?: 'PreferredProfile' }
          & Pick<PreferredProfile, 'avatar' | 'username' | 'name' | 'followers' | 'reach28Day' | 'engagement28Day'>
        )> }
      )>>, brands?: Maybe<Array<(
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name' | 'logo' | 'dmnEnabled' | 'numApplicationsToReview' | 'createdAt'>
        & { instagram?: Maybe<(
          { __typename?: 'InstagramAccount' }
          & Pick<InstagramAccount, 'name' | 'username' | 'avatar' | 'followersCount' | 'reach28Day' | 'engagement28Day'>
        )>, tikTok?: Maybe<(
          { __typename?: 'TikTokAccount' }
          & Pick<TikTokAccount, 'avatar'>
        )>, categories: Array<(
          { __typename?: 'BrandCategory' }
          & Pick<BrandCategory, 'id' | 'category'>
        )>, locations: (
          { __typename?: 'LocationsConnection' }
          & { locations: Array<(
            { __typename?: 'Location' }
            & Pick<Location, 'id'>
          )> }
        ) }
      )>> }
    ), tokens: (
      { __typename?: 'AuthTokens' }
      & Pick<AuthTokens, 'accessToken' | 'refreshToken'>
    ) }
  ) }
);

export type MyBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyBookingsQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & { bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'createdAt' | 'updatedAt'>
      & { listing: (
        { __typename?: 'Listing' }
        & { brand: (
          { __typename?: 'Brand' }
          & Pick<Brand, 'name'>
        ) }
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name'>
      )>, confirmedTimeslot?: Maybe<(
        { __typename?: 'BookingTimeSlot' }
        & Pick<BookingTimeSlot, 'date' | 'title' | 'description'>
      )> }
    )> }
  ) }
);

export type PlaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlaceQuery = (
  { __typename?: 'Query' }
  & { place: (
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'address' | 'addressLine1' | 'addressLine2' | 'postcode' | 'town' | 'county' | 'lat' | 'lng' | 'phone'>
    & { openingHours: Array<(
      { __typename?: 'PlaceOpeningHours' }
      & Pick<PlaceOpeningHours, 'dayOfWeek' | 'openTime' | 'closeTime'>
    )> }
  ) }
);

export type PostsQueryVariables = Exact<{
  input: PostsInput;
}>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & { posts2: (
    { __typename?: 'PostsResponse' }
    & Pick<PostsResponse, 'cursor'>
    & { posts: Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'mediaId' | 'caption' | 'mediaUrl' | 'thumbnailUrl' | 'type' | 'timestamp' | 'impressions' | 'permalink'>
    )> }
  ) }
);

export type PreviewQueryVariables = Exact<{
  brandID: Scalars['ID'];
}>;


export type PreviewQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'instagramHandle' | 'tikTokHandle' | 'images'>
    & { locations: (
      { __typename?: 'LocationsConnection' }
      & { locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name' | 'instagramHandle' | 'tikTokHandle'>
      )> }
    ) }
  )> }
);

export type ProgressBookingMutationVariables = Exact<{
  bookingId: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  status: BookingStatus;
  timeSlotId?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  rescheduleReason?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
  internalRejectionReason?: Maybe<Scalars['String']>;
}>;


export type ProgressBookingMutation = (
  { __typename?: 'Mutation' }
  & { progressBooking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'toggleCardReference' | 'designMyNightBookingID'>
  ) }
);

export type ReportBookingIssueMutationVariables = Exact<{
  issue: Scalars['String'];
  bookingId: Scalars['String'];
}>;


export type ReportBookingIssueMutation = (
  { __typename?: 'Mutation' }
  & { reportBookingIssue: (
    { __typename?: 'ReportBookingIssueResponse' }
    & Pick<ReportBookingIssueResponse, 'success'>
  ) }
);

export type ReportMetricsQueryVariables = Exact<{
  filters: ReportFilters;
}>;


export type ReportMetricsQuery = (
  { __typename?: 'Query' }
  & { reportMetrics: (
    { __typename?: 'ReportMetricsResponse' }
    & { metrics: Array<(
      { __typename?: 'ReportMetric' }
      & Pick<ReportMetric, 'timestamp' | 'impressions' | 'engagement' | 'reach'>
    )> }
  ) }
);

export type ResendEmailVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendEmailVerificationMutation = (
  { __typename?: 'Mutation' }
  & { resendEmailVerification: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email'>
  ) }
);

export type SearchPlacesQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type SearchPlacesQuery = (
  { __typename?: 'Query' }
  & { searchPlaces: (
    { __typename?: 'SearchPlacesResponse' }
    & { places: Array<(
      { __typename?: 'PlaceSearchResult' }
      & Pick<PlaceSearchResult, 'placeId' | 'description' | 'lat' | 'lng'>
    )> }
  ) }
);

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetPassword'>
);

export type SetPhoneNumberMutationVariables = Exact<{
  creatorId: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type SetPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { setPhoneNumber?: Maybe<(
    { __typename?: 'SetPhoneNumberReponse' }
    & Pick<SetPhoneNumberReponse, 'id'>
  )> }
);

export type TimeSlotsQueryVariables = Exact<{
  listingId: Scalars['ID'];
  locationId: Scalars['ID'];
  type?: Maybe<TimeSlotRequestType>;
  date?: Maybe<Scalars['Int']>;
}>;


export type TimeSlotsQuery = (
  { __typename?: 'Query' }
  & { timeSlots: Array<(
    { __typename?: 'TimeSlotGroup' }
    & Pick<TimeSlotGroup, 'date' | 'slots'>
  )> }
);

export type ToggleProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ToggleProductsQuery = (
  { __typename?: 'Query' }
  & { toggleProducts: Array<(
    { __typename?: 'ToggleProduct' }
    & Pick<ToggleProduct, 'id' | 'name' | 'shortDescription' | 'active' | 'maxValue' | 'minValue'>
    & { priceModifiers: Array<(
      { __typename?: 'TogglePriceModifier' }
      & Pick<TogglePriceModifier, 'id' | 'value' | 'description'>
    )> }
  )> }
);

export type UpdateAcccountMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AccountType>;
}>;


export type UpdateAcccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'firstName' | 'lastName' | 'email'>
  ) }
);

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;


export type UpdateBrandMutation = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id'>
  ) }
);

export type UpdateEmailSubscriptionsMutationVariables = Exact<{
  input: UpdateEmailSubscriptionInput;
}>;


export type UpdateEmailSubscriptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEmailSubscription'>
);

export type UpdateListingMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  maxSpend?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ListingStatus>;
  locations?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  days?: Maybe<Array<Days> | Days>;
  bookingOffset?: Maybe<Scalars['Int']>;
  minFollowers?: Maybe<Scalars['Int']>;
  maxFollowers?: Maybe<Scalars['Int']>;
  toggleProductId?: Maybe<Scalars['Int']>;
  toggleProductValue?: Maybe<Scalars['Int']>;
  requiresPhone: Scalars['Boolean'];
  timeSlots?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  schedules?: Maybe<Array<ScheduleInput> | ScheduleInput>;
  images: Array<Scalars['String']> | Scalars['String'];
  type?: Maybe<ListingType>;
  approvalSettings?: Maybe<ApprovalSettingsInput>;
  dmnBookingTypeID?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  minEngagementRate?: Maybe<Scalars['Float']>;
  dietaryPreferences?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  numberOfPeople?: Maybe<Scalars['Int']>;
  internalBookingNotes?: Maybe<Scalars['String']>;
  collinsBookingAutoCreate?: Maybe<Scalars['Boolean']>;
  instagramHandles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  requiredPosts?: Maybe<Scalars['Int']>;
  requiredReels?: Maybe<Scalars['Int']>;
  requiredPostAndReel?: Maybe<Scalars['Boolean']>;
  requiredStories?: Maybe<Scalars['Int']>;
  requiredTikToks?: Maybe<Scalars['Int']>;
  tikTokHandles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  instagramTags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tikTokTags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  collinsBookingCreationTypeID?: Maybe<Scalars['String']>;
  minAge?: Maybe<Scalars['Int']>;
  featuredVideo: Scalars['String'];
  offers: Array<ListingOfferInput> | ListingOfferInput;
  visibleDate?: Maybe<Scalars['Int']>;
}>;


export type UpdateListingMutation = (
  { __typename?: 'Mutation' }
  & { updateListing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'id'>
  ) }
);

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  excludedHours?: Maybe<Array<ExcludedHoursInput> | ExcludedHoursInput>;
  openingHours?: Maybe<Array<OpeningHoursInput> | OpeningHoursInput>;
  dmnVenueId?: Maybe<Scalars['String']>;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )> }
);

export type UpdateRejectionReasonsMutationVariables = Exact<{
  reasons: Array<RejectionReasonInput> | RejectionReasonInput;
}>;


export type UpdateRejectionReasonsMutation = (
  { __typename?: 'Mutation' }
  & { updateRejectionReasons: Array<Maybe<(
    { __typename?: 'RejectionReason' }
    & Pick<RejectionReason, 'id' | 'reason'>
  )>> }
);

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'emailVerifiedAt'>
  ) }
);


export const ListingMarkersDocument = `
    query ListingMarkers($center: Point, $dietaryPreferences: [String!], $distanceKm: Int) {
  listingMarkers(
    center: $center
    dietaryPreferences: $dietaryPreferences
    distanceKm: $distanceKm
  ) {
    markers {
      locationId
      listingIds
      locationName
      brandId
      brandName
      lat
      lng
      availability
      qualified
    }
  }
}
    `;
export const useListingMarkersQuery = <
      TData = ListingMarkersQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: ListingMarkersQueryVariables, 
      options?: UseQueryOptions<ListingMarkersQuery, TError, TData>
    ) => 
    useQuery<ListingMarkersQuery, TError, TData>(
      ['ListingMarkers', variables],
      fetcher<ListingMarkersQuery, ListingMarkersQueryVariables>(client, ListingMarkersDocument, variables),
      options
    );
export const AcceptBrandInviteDocument = `
    mutation AcceptBrandInvite($token: String!) {
  acceptBrandInvite(token: $token) {
    id
    name
  }
}
    `;
export const useAcceptBrandInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AcceptBrandInviteMutation, TError, AcceptBrandInviteMutationVariables, TContext>
    ) => 
    useMutation<AcceptBrandInviteMutation, TError, AcceptBrandInviteMutationVariables, TContext>(
      (variables?: AcceptBrandInviteMutationVariables) => fetcher<AcceptBrandInviteMutation, AcceptBrandInviteMutationVariables>(client, AcceptBrandInviteDocument, variables)(),
      options
    );
export const AccountsDocument = `
    query Accounts {
  account {
    id
    firstName
    lastName
    email
    type
    status
    fbTokenExpiry
    emailVerifiedAt
    createdAt
    creators {
      name
      id
      instagram {
        name
        username
        avatar
        followersCount
        reach28Day
        engagement28Day
      }
      tikTok {
        username
        displayName
        reach28Day
        engagement28Day
      }
      preferredProfile {
        avatar
        username
        name
        followers
        reach28Day
        engagement28Day
      }
    }
    brands {
      id
      name
      logo
      dmnEnabled
      numApplicationsToReview
      createdAt
      tikTok {
        avatar
      }
      instagram {
        name
        username
        avatar
        followersCount
        reach28Day
        engagement28Day
      }
      locations {
        locations {
          id
        }
      }
      categories {
        id
        category
      }
    }
  }
}
    `;
export const useAccountsQuery = <
      TData = AccountsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: AccountsQueryVariables, 
      options?: UseQueryOptions<AccountsQuery, TError, TData>
    ) => 
    useQuery<AccountsQuery, TError, TData>(
      ['Accounts', variables],
      fetcher<AccountsQuery, AccountsQueryVariables>(client, AccountsDocument, variables),
      options
    );
export const ActivateAccountDocument = `
    mutation ActivateAccount($accessCode: String!) {
  activateAccount(accessCode: $accessCode) {
    account {
      id
      status
    }
  }
}
    `;
export const useActivateAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ActivateAccountMutation, TError, ActivateAccountMutationVariables, TContext>
    ) => 
    useMutation<ActivateAccountMutation, TError, ActivateAccountMutationVariables, TContext>(
      (variables?: ActivateAccountMutationVariables) => fetcher<ActivateAccountMutation, ActivateAccountMutationVariables>(client, ActivateAccountDocument, variables)(),
      options
    );
export const AddToBrandBlackListDocument = `
    mutation AddToBrandBlackList($creatorId: ID!) {
  addToBrandBlackList(creatorId: $creatorId) {
    success
  }
}
    `;
export const useAddToBrandBlackListMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddToBrandBlackListMutation, TError, AddToBrandBlackListMutationVariables, TContext>
    ) => 
    useMutation<AddToBrandBlackListMutation, TError, AddToBrandBlackListMutationVariables, TContext>(
      (variables?: AddToBrandBlackListMutationVariables) => fetcher<AddToBrandBlackListMutation, AddToBrandBlackListMutationVariables>(client, AddToBrandBlackListDocument, variables)(),
      options
    );
export const AddToNotificationQueueDocument = `
    mutation AddToNotificationQueue($brandId: ID, $locationId: ID, $listingId: ID, $type: NotificationQueueType!) {
  addToNotificationQueue(
    brandId: $brandId
    locationId: $locationId
    listingId: $listingId
    type: $type
  ) {
    success
  }
}
    `;
export const useAddToNotificationQueueMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AddToNotificationQueueMutation, TError, AddToNotificationQueueMutationVariables, TContext>
    ) => 
    useMutation<AddToNotificationQueueMutation, TError, AddToNotificationQueueMutationVariables, TContext>(
      (variables?: AddToNotificationQueueMutationVariables) => fetcher<AddToNotificationQueueMutation, AddToNotificationQueueMutationVariables>(client, AddToNotificationQueueDocument, variables)(),
      options
    );
export const BillingDocument = `
    query Billing($brandID: ID!) {
  billingPlans {
    id
    name
    price
    planType
    period
    customerFacing
  }
  brand(id: $brandID) {
    billingStatus
    billingPlanId
    stripeCustomerId
  }
}
    `;
export const useBillingQuery = <
      TData = BillingQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BillingQueryVariables, 
      options?: UseQueryOptions<BillingQuery, TError, TData>
    ) => 
    useQuery<BillingQuery, TError, TData>(
      ['Billing', variables],
      fetcher<BillingQuery, BillingQueryVariables>(client, BillingDocument, variables),
      options
    );
export const BillingBannerDocument = `
    query BillingBanner($brandID: ID!) {
  brand(id: $brandID) {
    billingStatus
  }
}
    `;
export const useBillingBannerQuery = <
      TData = BillingBannerQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BillingBannerQueryVariables, 
      options?: UseQueryOptions<BillingBannerQuery, TError, TData>
    ) => 
    useQuery<BillingBannerQuery, TError, TData>(
      ['BillingBanner', variables],
      fetcher<BillingBannerQuery, BillingBannerQueryVariables>(client, BillingBannerDocument, variables),
      options
    );
export const BookingApprovalDocument = `
    mutation BookingApproval($bookingId: String!, $token: String, $status: BookingStatus!, $timeSlotId: String, $additionalInfo: String, $rejectionMessage: String) {
  bookingApproval(
    bookingId: $bookingId
    token: $token
    status: $status
    timeSlotId: $timeSlotId
    additionalInfo: $additionalInfo
    rejectionMessage: $rejectionMessage
  ) {
    id
  }
}
    `;
export const useBookingApprovalMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<BookingApprovalMutation, TError, BookingApprovalMutationVariables, TContext>
    ) => 
    useMutation<BookingApprovalMutation, TError, BookingApprovalMutationVariables, TContext>(
      (variables?: BookingApprovalMutationVariables) => fetcher<BookingApprovalMutation, BookingApprovalMutationVariables>(client, BookingApprovalDocument, variables)(),
      options
    );
export const GetBookingPostDocument = `
    query getBookingPost($id: ID!) {
  bookingPost(id: $id) {
    createdAt
    engagement
    impressions
    reach
    likes
    comments
    saves
    shares
    creator {
      id
      name
      preferredProfile {
        biography
        avatar
        username
        name
        followers
        reach28Day
        engagement28Day
        permalink
        type
      }
      instagram {
        name
        avatar
        username
      }
      tikTok {
        displayName
        avatar
        username
      }
    }
    mediaUrl
    mediaCaption
    mediaType
    postType
    permalink
    thumbnailUrl
  }
}
    `;
export const useGetBookingPostQuery = <
      TData = GetBookingPostQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetBookingPostQueryVariables, 
      options?: UseQueryOptions<GetBookingPostQuery, TError, TData>
    ) => 
    useQuery<GetBookingPostQuery, TError, TData>(
      ['getBookingPost', variables],
      fetcher<GetBookingPostQuery, GetBookingPostQueryVariables>(client, GetBookingPostDocument, variables),
      options
    );
export const BrandBillingDocument = `
    query BrandBilling($brandID: ID!) {
  brand(id: $brandID) {
    stripeCustomerId
    billingPlanId
    billingStatus
    numLocations
    numListings
  }
  billingPlans {
    id
    name
    price
    planType
    period
  }
}
    `;
export const useBrandBillingQuery = <
      TData = BrandBillingQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandBillingQueryVariables, 
      options?: UseQueryOptions<BrandBillingQuery, TError, TData>
    ) => 
    useQuery<BrandBillingQuery, TError, TData>(
      ['BrandBilling', variables],
      fetcher<BrandBillingQuery, BrandBillingQueryVariables>(client, BrandBillingDocument, variables),
      options
    );
export const BrandBookingsDocument = `
    query brandBookings($cursor: String, $statuses: [BookingStatus!], $isAwaitingContent: Boolean, $count: Int!, $sortBy: BookingsSortBy, $bookingStartDate: Int) {
  bookings(
    statuses: $statuses
    cursor: $cursor
    count: $count
    isAwaitingContent: $isAwaitingContent
    sortBy: $sortBy
    bookingStartDate: $bookingStartDate
  ) {
    cursor
    bookings {
      id
      type
      rating
      feedback
      status
      inProgress
      createdAt
      updatedAt
      expiresAt
      redeemedAt
      approvedAt
      completedAt
      listing {
        name
      }
      location {
        id
        name
      }
      confirmedTimeslot {
        date
        title
      }
      creator {
        name
        preferredProfile {
          biography
          avatar
          username
          name
          followers
          reach28Day
          engagement28Day
          permalink
          type
        }
      }
    }
  }
}
    `;
export const useBrandBookingsQuery = <
      TData = BrandBookingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandBookingsQueryVariables, 
      options?: UseQueryOptions<BrandBookingsQuery, TError, TData>
    ) => 
    useQuery<BrandBookingsQuery, TError, TData>(
      ['brandBookings', variables],
      fetcher<BrandBookingsQuery, BrandBookingsQueryVariables>(client, BrandBookingsDocument, variables),
      options
    );
export const BrandBookingNotesDocument = `
    query brandBookingNotes {
  account {
    id
    type
  }
  bookings {
    bookings {
      id
      status
      createdAt
      additionalInfo
      listing {
        id
      }
      location {
        id
      }
    }
  }
}
    `;
export const useBrandBookingNotesQuery = <
      TData = BrandBookingNotesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: BrandBookingNotesQueryVariables, 
      options?: UseQueryOptions<BrandBookingNotesQuery, TError, TData>
    ) => 
    useQuery<BrandBookingNotesQuery, TError, TData>(
      ['brandBookingNotes', variables],
      fetcher<BrandBookingNotesQuery, BrandBookingNotesQueryVariables>(client, BrandBookingNotesDocument, variables),
      options
    );
export const BrandInviteDocument = `
    query BrandInvite($token: String!) {
  brandInvite(token: $token) {
    email
    invitedBy {
      firstName
      lastName
    }
    brand {
      id
      name
      logo
      description
      accounts {
        id
        firstName
      }
    }
  }
}
    `;
export const useBrandInviteQuery = <
      TData = BrandInviteQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandInviteQueryVariables, 
      options?: UseQueryOptions<BrandInviteQuery, TError, TData>
    ) => 
    useQuery<BrandInviteQuery, TError, TData>(
      ['BrandInvite', variables],
      fetcher<BrandInviteQuery, BrandInviteQueryVariables>(client, BrandInviteDocument, variables),
      options
    );
export const BrandListingDocument = `
    query BrandListing($id: ID!) {
  billingPlans {
    id
    name
    price
    planType
    period
  }
  listing(id: $id) {
    id
    name
    details
    terms
    maxSpend
    startDate
    endDate
    status
    minFollowers
    maxFollowers
    minEngagementRate
    images
    type
    dmnBookingTypeID
    collinsBookingAutoCreate
    internalBookingNotes
    publishDate
    numberOfPeople
    eventTimeSlots
    collinsBookingCreationTypeId
    instagramHandles
    tikTokHandles
    instagramTags
    tikTokTags
    minAge
    featuredVideo
    visibleDate
    requirements {
      requiredPosts
      requiredReels
      requiredReelAndPost
      requiredStories
      requiredTikToks
    }
    offers {
      minFollowers
      maxFollowers
      maxGuests
      offer
      platform
      toggleProductId
      toggleProductValue
    }
    approvalSettings {
      id
      type
      minFollowers
      minEngagementRate
      minUkAudience
      tier
      minAvViews
    }
    brand {
      id
      toggleIntegrationEnabled
      locations {
        locations {
          id
          name
        }
      }
      billingStatus
      billingPlanId
    }
    locations {
      id
      name
    }
    days
    categories
    dietaryPreferences
    bookingOffset
    toggleProductId
    toggleProductValue
    requiresPhone
    applicationsCount
    schedules {
      id
      days
      startHour
      startMinute
    }
    timeSlot
  }
}
    `;
export const useBrandListingQuery = <
      TData = BrandListingQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandListingQueryVariables, 
      options?: UseQueryOptions<BrandListingQuery, TError, TData>
    ) => 
    useQuery<BrandListingQuery, TError, TData>(
      ['BrandListing', variables],
      fetcher<BrandListingQuery, BrandListingQueryVariables>(client, BrandListingDocument, variables),
      options
    );
export const BrandListingsDocument = `
    query BrandListings($brandId: String) {
  listings(brandId: $brandId) {
    listings {
      id
      name
      startDate
      publishDate
      endDate
      status
      minFollowers
      maxFollowers
      bookingsCount
      applicationsCount
      type
      eventTimeSlots
      locations {
        id
        name
      }
    }
  }
}
    `;
export const useBrandListingsQuery = <
      TData = BrandListingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: BrandListingsQueryVariables, 
      options?: UseQueryOptions<BrandListingsQuery, TError, TData>
    ) => 
    useQuery<BrandListingsQuery, TError, TData>(
      ['BrandListings', variables],
      fetcher<BrandListingsQuery, BrandListingsQueryVariables>(client, BrandListingsDocument, variables),
      options
    );
export const BrandLocationDocument = `
    query BrandLocation($id: ID!) {
  location(id: $id) {
    name
    id
    lat
    lng
    address
    email
    phone
    dmnVenueId
    instagramHandle
    openingHours {
      dayOfWeek
      openHour
      openMinute
      closeHour
      closeMinute
    }
    excludedHours {
      id
      dayOfWeek
      startHour
      startMinute
      endHour
      endMinute
    }
  }
}
    `;
export const useBrandLocationQuery = <
      TData = BrandLocationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandLocationQueryVariables, 
      options?: UseQueryOptions<BrandLocationQuery, TError, TData>
    ) => 
    useQuery<BrandLocationQuery, TError, TData>(
      ['BrandLocation', variables],
      fetcher<BrandLocationQuery, BrandLocationQueryVariables>(client, BrandLocationDocument, variables),
      options
    );
export const BrandLocationsDocument = `
    query BrandLocations($brandId: ID!) {
  brand(id: $brandId) {
    toggleIntegrationEnabled
    locations {
      locations {
        name
        id
        lat
        lng
        address
        email
        phone
        dmnVenueId
        openingHours {
          dayOfWeek
        }
      }
    }
  }
}
    `;
export const useBrandLocationsQuery = <
      TData = BrandLocationsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandLocationsQueryVariables, 
      options?: UseQueryOptions<BrandLocationsQuery, TError, TData>
    ) => 
    useQuery<BrandLocationsQuery, TError, TData>(
      ['BrandLocations', variables],
      fetcher<BrandLocationsQuery, BrandLocationsQueryVariables>(client, BrandLocationsDocument, variables),
      options
    );
export const BrandNotificationCountDocument = `
    query BrandNotificationCount($brandID: ID!) {
  brand(id: $brandID) {
    numApplicationsToReview
  }
}
    `;
export const useBrandNotificationCountQuery = <
      TData = BrandNotificationCountQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandNotificationCountQueryVariables, 
      options?: UseQueryOptions<BrandNotificationCountQuery, TError, TData>
    ) => 
    useQuery<BrandNotificationCountQuery, TError, TData>(
      ['BrandNotificationCount', variables],
      fetcher<BrandNotificationCountQuery, BrandNotificationCountQueryVariables>(client, BrandNotificationCountDocument, variables),
      options
    );
export const BrandScheduleSnapshotDocument = `
    query brandScheduleSnapshot($statuses: [BookingStatus!], $count: Int!) {
  bookings(statuses: $statuses, count: $count) {
    cursor
    bookings {
      id
      type
      rating
      feedback
      status
      inProgress
      createdAt
      updatedAt
      expiresAt
      redeemedAt
      approvedAt
      confirmedTimeslot {
        date
        title
      }
      location {
        id
        name
      }
      creator {
        name
        preferredProfile {
          biography
          avatar
          username
          name
          followers
          reach28Day
          engagement28Day
          permalink
          type
        }
      }
    }
  }
}
    `;
export const useBrandScheduleSnapshotQuery = <
      TData = BrandScheduleSnapshotQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandScheduleSnapshotQueryVariables, 
      options?: UseQueryOptions<BrandScheduleSnapshotQuery, TError, TData>
    ) => 
    useQuery<BrandScheduleSnapshotQuery, TError, TData>(
      ['brandScheduleSnapshot', variables],
      fetcher<BrandScheduleSnapshotQuery, BrandScheduleSnapshotQueryVariables>(client, BrandScheduleSnapshotDocument, variables),
      options
    );
export const CancelBookingDocument = `
    mutation CancelBooking($bookingId: ID!, $cancellationReason: String) {
  cancelBooking(bookingId: $bookingId, cancellationReason: $cancellationReason) {
    booking {
      id
      status
    }
  }
}
    `;
export const useCancelBookingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CancelBookingMutation, TError, CancelBookingMutationVariables, TContext>
    ) => 
    useMutation<CancelBookingMutation, TError, CancelBookingMutationVariables, TContext>(
      (variables?: CancelBookingMutationVariables) => fetcher<CancelBookingMutation, CancelBookingMutationVariables>(client, CancelBookingDocument, variables)(),
      options
    );
export const ConnectToFacebookDocument = `
    mutation ConnectToFacebook($fbToken: String!, $instagramAccountId: String) {
  connectToFacebook(fbToken: $fbToken, instagramAccountID: $instagramAccountId) {
    account {
      id
    }
  }
}
    `;
export const useConnectToFacebookMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ConnectToFacebookMutation, TError, ConnectToFacebookMutationVariables, TContext>
    ) => 
    useMutation<ConnectToFacebookMutation, TError, ConnectToFacebookMutationVariables, TContext>(
      (variables?: ConnectToFacebookMutationVariables) => fetcher<ConnectToFacebookMutation, ConnectToFacebookMutationVariables>(client, ConnectToFacebookDocument, variables)(),
      options
    );
export const ConnectToTikTokDocument = `
    mutation ConnectToTikTok($ttCode: String!, $redirectUri: String!) {
  connectToTikTok(ttCode: $ttCode, redirectUri: $redirectUri) {
    account {
      id
      status
    }
  }
}
    `;
export const useConnectToTikTokMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ConnectToTikTokMutation, TError, ConnectToTikTokMutationVariables, TContext>
    ) => 
    useMutation<ConnectToTikTokMutation, TError, ConnectToTikTokMutationVariables, TContext>(
      (variables?: ConnectToTikTokMutationVariables) => fetcher<ConnectToTikTokMutation, ConnectToTikTokMutationVariables>(client, ConnectToTikTokDocument, variables)(),
      options
    );
export const CreateBookingDocument = `
    mutation CreateBooking($creatorId: String!, $listingId: String!, $locationId: String!, $timeSlots: [Int!]!) {
  createBooking(
    creatorId: $creatorId
    listingId: $listingId
    locationId: $locationId
    timeSlots: $timeSlots
  ) {
    booking {
      id
    }
  }
}
    `;
export const useCreateBookingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateBookingMutation, TError, CreateBookingMutationVariables, TContext>
    ) => 
    useMutation<CreateBookingMutation, TError, CreateBookingMutationVariables, TContext>(
      (variables?: CreateBookingMutationVariables) => fetcher<CreateBookingMutation, CreateBookingMutationVariables>(client, CreateBookingDocument, variables)(),
      options
    );
export const CreateBrandDocument = `
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    id
    name
  }
}
    `;
export const useCreateBrandMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateBrandMutation, TError, CreateBrandMutationVariables, TContext>
    ) => 
    useMutation<CreateBrandMutation, TError, CreateBrandMutationVariables, TContext>(
      (variables?: CreateBrandMutationVariables) => fetcher<CreateBrandMutation, CreateBrandMutationVariables>(client, CreateBrandDocument, variables)(),
      options
    );
export const CreateBrandInviteDocument = `
    mutation CreateBrandInvite($brandId: ID!, $email: String!) {
  createBrandInvite(brandId: $brandId, email: $email) {
    id
    invites {
      email
    }
  }
}
    `;
export const useCreateBrandInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateBrandInviteMutation, TError, CreateBrandInviteMutationVariables, TContext>
    ) => 
    useMutation<CreateBrandInviteMutation, TError, CreateBrandInviteMutationVariables, TContext>(
      (variables?: CreateBrandInviteMutationVariables) => fetcher<CreateBrandInviteMutation, CreateBrandInviteMutationVariables>(client, CreateBrandInviteDocument, variables)(),
      options
    );
export const CreateListingDocument = `
    mutation createListing($brandId: String!, $name: String!, $details: String!, $maxSpend: Int, $startDate: Int, $endDate: Int, $status: ListingStatus!, $locations: [ID!]!, $days: [Days!]!, $bookingOffset: Int, $minFollowers: Int, $maxFollowers: Int, $toggleProductId: Int, $toggleProductValue: Int, $requiresPhone: Boolean!, $timeSlots: [Int!], $schedules: [ScheduleInput!], $images: [String!]!, $type: ListingType!, $approvalSettings: ApprovalSettingsInput!, $dmnBookingTypeID: String, $categories: [String!]!, $minEngagementRate: Float, $dietaryPreferences: [String!], $numberOfPeople: Int, $internalBookingNotes: String, $collinsBookingAutoCreate: Boolean, $instagramHandles: [String!], $tikTokHandles: [String!], $instagramTags: [String!], $requiredPosts: Int, $requiredReels: Int, $requiredPostAndReel: Boolean, $requiredStories: Int, $requiredTikToks: Int, $tikTokTags: [String!], $collinsBookingCreationTypeID: String, $minAge: Int, $featuredVideo: String!, $offers: [ListingOfferInput!]!, $visibleDate: Int) {
  createListing(
    brandId: $brandId
    name: $name
    details: $details
    maxSpend: $maxSpend
    startDate: $startDate
    endDate: $endDate
    status: $status
    locations: $locations
    days: $days
    bookingOffset: $bookingOffset
    minFollowers: $minFollowers
    maxFollowers: $maxFollowers
    toggleProductId: $toggleProductId
    toggleProductValue: $toggleProductValue
    requiresPhone: $requiresPhone
    timeSlots: $timeSlots
    schedules: $schedules
    images: $images
    type: $type
    approvalSettings: $approvalSettings
    dmnBookingTypeID: $dmnBookingTypeID
    categories: $categories
    minEngagementRate: $minEngagementRate
    dietaryPreferences: $dietaryPreferences
    numberOfPeople: $numberOfPeople
    internalBookingNotes: $internalBookingNotes
    collinsBookingAutoCreate: $collinsBookingAutoCreate
    instagramHandles: $instagramHandles
    tikTokHandles: $tikTokHandles
    instagramTags: $instagramTags
    requiredPosts: $requiredPosts
    requiredReels: $requiredReels
    requiredPostAndReel: $requiredPostAndReel
    requiredStories: $requiredStories
    requiredTikToks: $requiredTikToks
    tikTokTags: $tikTokTags
    collinsBookingCreationTypeID: $collinsBookingCreationTypeID
    minAge: $minAge
    featuredVideo: $featuredVideo
    offers: $offers
    visibleDate: $visibleDate
  ) {
    listing {
      id
    }
  }
}
    `;
export const useCreateListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateListingMutation, TError, CreateListingMutationVariables, TContext>
    ) => 
    useMutation<CreateListingMutation, TError, CreateListingMutationVariables, TContext>(
      (variables?: CreateListingMutationVariables) => fetcher<CreateListingMutation, CreateListingMutationVariables>(client, CreateListingDocument, variables)(),
      options
    );
export const CreateLocationDocument = `
    mutation createLocation($brandId: String!, $name: String!, $lat: Float!, $lng: Float!, $address: String!, $email: String!, $phone: String!, $placesId: String!, $excludedHours: [ExcludedHoursInput!], $openingHours: [OpeningHoursInput!]) {
  createLocation(
    name: $name
    brandId: $brandId
    address: $address
    lat: $lat
    lng: $lng
    email: $email
    phone: $phone
    placesId: $placesId
    excludedHours: $excludedHours
    openingHours: $openingHours
  ) {
    location {
      id
    }
  }
}
    `;
export const useCreateLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateLocationMutation, TError, CreateLocationMutationVariables, TContext>
    ) => 
    useMutation<CreateLocationMutation, TError, CreateLocationMutationVariables, TContext>(
      (variables?: CreateLocationMutationVariables) => fetcher<CreateLocationMutation, CreateLocationMutationVariables>(client, CreateLocationDocument, variables)(),
      options
    );
export const CreateStripeCheckoutDocument = `
    mutation CreateStripeCheckout($additionalBrands: Int!, $additionalRegions: Int!, $managedPlan: Boolean!, $integrations: Int!, $billingPlanId: ID!) {
  createStripeCheckout(
    additionalBrands: $additionalBrands
    additionalRegions: $additionalRegions
    managedPlan: $managedPlan
    integrations: $integrations
    billingPlanId: $billingPlanId
  ) {
    redirectUrl
  }
}
    `;
export const useCreateStripeCheckoutMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateStripeCheckoutMutation, TError, CreateStripeCheckoutMutationVariables, TContext>
    ) => 
    useMutation<CreateStripeCheckoutMutation, TError, CreateStripeCheckoutMutationVariables, TContext>(
      (variables?: CreateStripeCheckoutMutationVariables) => fetcher<CreateStripeCheckoutMutation, CreateStripeCheckoutMutationVariables>(client, CreateStripeCheckoutDocument, variables)(),
      options
    );
export const CreateStripePortalDocument = `
    mutation CreateStripePortal {
  createStripePortal {
    redirectUrl
  }
}
    `;
export const useCreateStripePortalMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateStripePortalMutation, TError, CreateStripePortalMutationVariables, TContext>
    ) => 
    useMutation<CreateStripePortalMutation, TError, CreateStripePortalMutationVariables, TContext>(
      (variables?: CreateStripePortalMutationVariables) => fetcher<CreateStripePortalMutation, CreateStripePortalMutationVariables>(client, CreateStripePortalDocument, variables)(),
      options
    );
export const CreateToggleUserDocument = `
    mutation createToggleUser($username: String!, $password: String!) {
  createToggleUser(username: $username, password: $password) {
    username
  }
}
    `;
export const useCreateToggleUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateToggleUserMutation, TError, CreateToggleUserMutationVariables, TContext>
    ) => 
    useMutation<CreateToggleUserMutation, TError, CreateToggleUserMutationVariables, TContext>(
      (variables?: CreateToggleUserMutationVariables) => fetcher<CreateToggleUserMutation, CreateToggleUserMutationVariables>(client, CreateToggleUserDocument, variables)(),
      options
    );
export const DeleteBrandAccountDocument = `
    mutation DeleteBrandAccount($accountId: ID!) {
  deleteBrandAccount(accountId: $accountId) {
    id
  }
}
    `;
export const useDeleteBrandAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteBrandAccountMutation, TError, DeleteBrandAccountMutationVariables, TContext>
    ) => 
    useMutation<DeleteBrandAccountMutation, TError, DeleteBrandAccountMutationVariables, TContext>(
      (variables?: DeleteBrandAccountMutationVariables) => fetcher<DeleteBrandAccountMutation, DeleteBrandAccountMutationVariables>(client, DeleteBrandAccountDocument, variables)(),
      options
    );
export const DeleteBrandInviteDocument = `
    mutation DeleteBrandInvite($brandInviteId: ID!) {
  deleteBrandInvite(brandInviteId: $brandInviteId) {
    id
  }
}
    `;
export const useDeleteBrandInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteBrandInviteMutation, TError, DeleteBrandInviteMutationVariables, TContext>
    ) => 
    useMutation<DeleteBrandInviteMutation, TError, DeleteBrandInviteMutationVariables, TContext>(
      (variables?: DeleteBrandInviteMutationVariables) => fetcher<DeleteBrandInviteMutation, DeleteBrandInviteMutationVariables>(client, DeleteBrandInviteDocument, variables)(),
      options
    );
export const DeleteListingDocument = `
    mutation DeleteListing($listingId: ID!) {
  deleteListing(listingId: $listingId) {
    id
  }
}
    `;
export const useDeleteListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteListingMutation, TError, DeleteListingMutationVariables, TContext>
    ) => 
    useMutation<DeleteListingMutation, TError, DeleteListingMutationVariables, TContext>(
      (variables?: DeleteListingMutationVariables) => fetcher<DeleteListingMutation, DeleteListingMutationVariables>(client, DeleteListingDocument, variables)(),
      options
    );
export const DeleteLocationDocument = `
    mutation DeleteLocation($locationId: ID!) {
  deleteLocation(locationId: $locationId) {
    id
  }
}
    `;
export const useDeleteLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteLocationMutation, TError, DeleteLocationMutationVariables, TContext>
    ) => 
    useMutation<DeleteLocationMutation, TError, DeleteLocationMutationVariables, TContext>(
      (variables?: DeleteLocationMutationVariables) => fetcher<DeleteLocationMutation, DeleteLocationMutationVariables>(client, DeleteLocationDocument, variables)(),
      options
    );
export const DietaryPreferencesDocument = `
    query DietaryPreferences {
  dietaryPreferences {
    id
    label
  }
}
    `;
export const useDietaryPreferencesQuery = <
      TData = DietaryPreferencesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: DietaryPreferencesQueryVariables, 
      options?: UseQueryOptions<DietaryPreferencesQuery, TError, TData>
    ) => 
    useQuery<DietaryPreferencesQuery, TError, TData>(
      ['DietaryPreferences', variables],
      fetcher<DietaryPreferencesQuery, DietaryPreferencesQueryVariables>(client, DietaryPreferencesDocument, variables),
      options
    );
export const DmnBookingTypesDocument = `
    query DmnBookingTypes($locationIDs: [String!]!, $numberOfPeople: Int!) {
  dmnBookingTypes(locationIDs: $locationIDs, numberOfPeople: $numberOfPeople) {
    id
    name
    missingLocations {
      id
      name
    }
    validLocations {
      id
      name
    }
  }
}
    `;
export const useDmnBookingTypesQuery = <
      TData = DmnBookingTypesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: DmnBookingTypesQueryVariables, 
      options?: UseQueryOptions<DmnBookingTypesQuery, TError, TData>
    ) => 
    useQuery<DmnBookingTypesQuery, TError, TData>(
      ['DmnBookingTypes', variables],
      fetcher<DmnBookingTypesQuery, DmnBookingTypesQueryVariables>(client, DmnBookingTypesDocument, variables),
      options
    );
export const DuplicateListingDocument = `
    mutation DuplicateListing($id: String!) {
  duplicateListing(id: $id) {
    id
  }
}
    `;
export const useDuplicateListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DuplicateListingMutation, TError, DuplicateListingMutationVariables, TContext>
    ) => 
    useMutation<DuplicateListingMutation, TError, DuplicateListingMutationVariables, TContext>(
      (variables?: DuplicateListingMutationVariables) => fetcher<DuplicateListingMutation, DuplicateListingMutationVariables>(client, DuplicateListingDocument, variables)(),
      options
    );
export const EmailSubscriptionsDocument = `
    query EmailSubscriptions {
  emailSubscriptions {
    dailyDigest
    newApplication
    bookingConfirmation
    bookingCancelled
    bookingReschedule
  }
}
    `;
export const useEmailSubscriptionsQuery = <
      TData = EmailSubscriptionsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: EmailSubscriptionsQueryVariables, 
      options?: UseQueryOptions<EmailSubscriptionsQuery, TError, TData>
    ) => 
    useQuery<EmailSubscriptionsQuery, TError, TData>(
      ['EmailSubscriptions', variables],
      fetcher<EmailSubscriptionsQuery, EmailSubscriptionsQueryVariables>(client, EmailSubscriptionsDocument, variables),
      options
    );
export const GenerateBookingNoteDocument = `
    query GenerateBookingNote($bookingId: ID!, $approvedTimeslot: String) {
  generateBookingNote(
    bookingId: $bookingId
    approvedTimeslotID: $approvedTimeslot
  )
}
    `;
export const useGenerateBookingNoteQuery = <
      TData = GenerateBookingNoteQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GenerateBookingNoteQueryVariables, 
      options?: UseQueryOptions<GenerateBookingNoteQuery, TError, TData>
    ) => 
    useQuery<GenerateBookingNoteQuery, TError, TData>(
      ['GenerateBookingNote', variables],
      fetcher<GenerateBookingNoteQuery, GenerateBookingNoteQueryVariables>(client, GenerateBookingNoteDocument, variables),
      options
    );
export const GetBookingDocument = `
    query getBooking($id: ID!, $token: String) {
  booking(id: $id, token: $token) {
    id
    status
    createdAt
    updatedAt
    inProgress
    additionalInfo
    toggleCardReference
    rescheduleReason
    cancellationReason
    rejectionMessage
    type
    rating
    feedback
    approvedAt
    redeemedAt
    isToggleBooking
    toggleProductValue
    approvedBy {
      firstName
      lastName
    }
    platform
    remainingContent {
      instagramPosts
      instagramStories
      instagramReels
      tikToks
    }
    suggestedTimeSlots {
      id
      date
      title
      description
    }
    confirmedTimeslot {
      id
      date
      title
      description
    }
    creator {
      id
      name
      phoneNumber
      ttConnectionExpired
      fbTokenExpiry
      instagram {
        name
        username
        biography
        avatar
        mediaCount
        followersCount
        followsCount
        reach28Day
        prevReach28Day
        impressions28Day
        prevImpressions28Day
        engagement28Day
      }
      tikTok {
        displayName
        username
        reach28Day
        impressions28Day
        prevImpressions28Day
        engagement28Day
        likes28Day
        comments28Day
        shares28Day
        avatar
        followersCount
        biography
      }
      preferredProfile {
        biography
        avatar
        username
        name
        followers
        reach28Day
        engagement28Day
        permalink
        type
      }
      demographics {
        cities {
          id
          city
          percentage
          source
        }
        countries {
          id
          country
          percentage
          source
        }
        ages {
          id
          ageRange
          gender
          percentage
          source
        }
      }
      accounts {
        firstName
        lastName
        email
        phoneNumber
      }
    }
    listing {
      id
      days
      name
      details
      maxSpend
      minFollowers
      status
      terms
      startDate
      endDate
      toggleProductValue
      bookingOffset
      isDesignMyNightListing
      brand {
        name
        id
        logo
        url
      }
    }
    location {
      id
      name
      email
      phone
      address
      lat
      lng
      placeId
      images
      openingHours {
        id
        dayOfWeek
        openHour
        openMinute
        closeHour
        closeMinute
      }
      excludedHours {
        id
        dayOfWeek
        startHour
        startMinute
        endHour
        endMinute
      }
    }
    bookingPosts {
      id
      createdAt
      impressions
      reach
      engagement
      reach
      likes
      comments
      saves
      shares
      mediaUrl
      mediaCaption
      mediaType
      postType
      permalink
      thumbnailUrl
    }
    bookingEvents {
      id
      timestamp
      type
      description
      performedByType
      approvedDate
      performedBy {
        firstName
        lastName
        email
      }
      bookingPost {
        id
        postType
      }
    }
  }
}
    `;
export const useGetBookingQuery = <
      TData = GetBookingQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetBookingQueryVariables, 
      options?: UseQueryOptions<GetBookingQuery, TError, TData>
    ) => 
    useQuery<GetBookingQuery, TError, TData>(
      ['getBooking', variables],
      fetcher<GetBookingQuery, GetBookingQueryVariables>(client, GetBookingDocument, variables),
      options
    );
export const GetBrandDocument = `
    query GetBrand($id: ID!) {
  billingPlans {
    id
    name
    price
    planType
    period
  }
  brand(id: $id) {
    name
    description
    longDescription
    fbReportingEnabled
    dmnEnabled
    dmnBookingsEnabled
    ttReportingEnabled
    toggleIntegrationEnabled
    instagramHandle
    cancellationNotificationPolicy
    logo
    categories {
      category
      id
    }
    instagram {
      avatar
      name
    }
    tikTok {
      avatar
      displayName
    }
    billingStatus
    billingPlanId
    images
    accounts {
      id
      email
      firstName
      lastName
      type
    }
    invites {
      id
      email
    }
  }
}
    `;
export const useGetBrandQuery = <
      TData = GetBrandQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetBrandQueryVariables, 
      options?: UseQueryOptions<GetBrandQuery, TError, TData>
    ) => 
    useQuery<GetBrandQuery, TError, TData>(
      ['GetBrand', variables],
      fetcher<GetBrandQuery, GetBrandQueryVariables>(client, GetBrandDocument, variables),
      options
    );
export const GetBrandBillingStatusDocument = `
    query GetBrandBillingStatus($id: ID!) {
  brand(id: $id) {
    billingStatus
    billingPlanId
  }
  billingPlans {
    id
    name
    price
    planType
    period
  }
}
    `;
export const useGetBrandBillingStatusQuery = <
      TData = GetBrandBillingStatusQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetBrandBillingStatusQueryVariables, 
      options?: UseQueryOptions<GetBrandBillingStatusQuery, TError, TData>
    ) => 
    useQuery<GetBrandBillingStatusQuery, TError, TData>(
      ['GetBrandBillingStatus', variables],
      fetcher<GetBrandBillingStatusQuery, GetBrandBillingStatusQueryVariables>(client, GetBrandBillingStatusDocument, variables),
      options
    );
export const BrandContentDocument = `
    query BrandContent($filters: ReportFilters!, $brandID: ID!, $cursor: String) {
  bookingPosts(filters: $filters, cursor: $cursor) {
    bookingPosts {
      id
      createdAt
      impressions
      reach
      engagement
      mediaUrl
      mediaCaption
      mediaType
      postType
      permalink
      thumbnailUrl
      creator {
        id
        name
        instagram {
          name
          username
        }
        tikTok {
          displayName
          username
        }
      }
    }
    hasMore
    cursor
  }
  brand(id: $brandID) {
    fbReportingEnabled
    billingPlanId
    bookingStatus
    billingStatus
  }
  billingPlans {
    id
    name
    price
    planType
    period
  }
}
    `;
export const useBrandContentQuery = <
      TData = BrandContentQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: BrandContentQueryVariables, 
      options?: UseQueryOptions<BrandContentQuery, TError, TData>
    ) => 
    useQuery<BrandContentQuery, TError, TData>(
      ['BrandContent', variables],
      fetcher<BrandContentQuery, BrandContentQueryVariables>(client, BrandContentDocument, variables),
      options
    );
export const GetBrandHandlesDocument = `
    query GetBrandHandles($id: ID!) {
  brand(id: $id) {
    instagramHandle
    tikTokHandle
  }
}
    `;
export const useGetBrandHandlesQuery = <
      TData = GetBrandHandlesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetBrandHandlesQueryVariables, 
      options?: UseQueryOptions<GetBrandHandlesQuery, TError, TData>
    ) => 
    useQuery<GetBrandHandlesQuery, TError, TData>(
      ['GetBrandHandles', variables],
      fetcher<GetBrandHandlesQuery, GetBrandHandlesQueryVariables>(client, GetBrandHandlesDocument, variables),
      options
    );
export const CategoriesDocument = `
    query Categories {
  categories {
    id
    label
  }
}
    `;
export const useCategoriesQuery = <
      TData = CategoriesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: CategoriesQueryVariables, 
      options?: UseQueryOptions<CategoriesQuery, TError, TData>
    ) => 
    useQuery<CategoriesQuery, TError, TData>(
      ['Categories', variables],
      fetcher<CategoriesQuery, CategoriesQueryVariables>(client, CategoriesDocument, variables),
      options
    );
export const GetCreatorDocument = `
    query getCreator($id: ID!, $refreshData: Boolean) {
  creator(id: $id, RefreshData: $refreshData) {
    id
    name
    phoneNumber
    ttEnabled
    followerCount
    tier
    tikTok {
      id
      displayName
      username
      reach28Day
      engagement28Day
      likes28Day
      comments28Day
      shares28Day
      avatar
      followersCount
      biography
    }
    instagram {
      id
      name
      username
      avatar
      biography
      mediaCount
      followersCount
      followsCount
      reach28Day
      impressions28Day
      engagement28Day
    }
    accounts {
      firstName
      id
      lastName
      email
      type
      status
      createdAt
      fbTokenExpiry
      awaitingApprovalNotification
      wellLit
      rightContent
      rightLocation
    }
  }
}
    `;
export const useGetCreatorQuery = <
      TData = GetCreatorQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetCreatorQueryVariables, 
      options?: UseQueryOptions<GetCreatorQuery, TError, TData>
    ) => 
    useQuery<GetCreatorQuery, TError, TData>(
      ['getCreator', variables],
      fetcher<GetCreatorQuery, GetCreatorQueryVariables>(client, GetCreatorDocument, variables),
      options
    );
export const GetDeclineReasonsDocument = `
    query GetDeclineReasons {
  rejectionReasons {
    id
    reason
  }
}
    `;
export const useGetDeclineReasonsQuery = <
      TData = GetDeclineReasonsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetDeclineReasonsQueryVariables, 
      options?: UseQueryOptions<GetDeclineReasonsQuery, TError, TData>
    ) => 
    useQuery<GetDeclineReasonsQuery, TError, TData>(
      ['GetDeclineReasons', variables],
      fetcher<GetDeclineReasonsQuery, GetDeclineReasonsQueryVariables>(client, GetDeclineReasonsDocument, variables),
      options
    );
export const GetListingInvitesDocument = `
    query getListingInvites($listingId: String, $cursor: String, $count: Int) {
  listingInvites(listingId: $listingId, cursor: $cursor, count: $count) {
    hasMore
    cursor
    creators {
      creator {
        id
        preferredProfile {
          avatar
          username
          name
          followers
          reach28Day
          engagement28Day
          permalink
        }
        dietaryPreferences
        categories
        accounts {
          firstName
          lastName
          email
          phoneNumber
        }
      }
      nearestLocation
      nearestLocationDistance
      similarityScore
    }
  }
}
    `;
export const useGetListingInvitesQuery = <
      TData = GetListingInvitesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetListingInvitesQueryVariables, 
      options?: UseQueryOptions<GetListingInvitesQuery, TError, TData>
    ) => 
    useQuery<GetListingInvitesQuery, TError, TData>(
      ['getListingInvites', variables],
      fetcher<GetListingInvitesQuery, GetListingInvitesQueryVariables>(client, GetListingInvitesDocument, variables),
      options
    );
export const GetLocationDocument = `
    query GetLocation($id: ID!) {
  location(id: $id) {
    id
    name
    email
    phone
    address
    lat
    placeId
    lng
    utcOffset
    openingHours {
      id
      dayOfWeek
      openHour
      openMinute
      closeHour
      closeMinute
    }
    excludedHours {
      id
      dayOfWeek
      startHour
      startMinute
      endHour
      endMinute
    }
    listings {
      id
      name
      details
      maxSpend
      status
      terms
      startDate
      endDate
      days
      bookingOffset
      requiresPhone
      followerGate
      isDesignMyNightListing
      timeSlots {
        id
        title
        description
      }
      minFollowers
      awaitingNotification
      brand {
        name
        id
        logo
        url
      }
    }
    brand {
      name
      id
      images
      bookingStatus
      description
      instagram {
        name
        username
      }
    }
    images
  }
}
    `;
export const useGetLocationQuery = <
      TData = GetLocationQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetLocationQueryVariables, 
      options?: UseQueryOptions<GetLocationQuery, TError, TData>
    ) => 
    useQuery<GetLocationQuery, TError, TData>(
      ['GetLocation', variables],
      fetcher<GetLocationQuery, GetLocationQueryVariables>(client, GetLocationDocument, variables),
      options
    );
export const ReportsDocument = `
    query Reports($filters: ReportFilters!, $brandID: ID!) {
  bookingPosts(filters: $filters) {
    bookingPosts {
      id
      createdAt
      impressions
      reach
      engagement
      mediaUrl
      mediaCaption
      mediaType
      postType
      permalink
      thumbnailUrl
      creator {
        id
        name
        instagram {
          name
          username
        }
        tikTok {
          displayName
          username
        }
      }
    }
  }
  bookingsMetrics(filters: $filters) {
    totalApplications
    totalBookings
    totalRejectedBookings
    totalCompletedBookings
    totalUpcomingBookings
    totalOverdueBookings
    totalCancelledBookings
    totalReels
    totalStories
    totalPosts
    totalLikes
    totalComments
    totalShares
    totalSaves
    totalTiktoks
  }
  reportMetrics(filters: $filters) {
    metrics {
      timestamp
      impressions
      engagement
      reach
    }
    trailingMetrics {
      timestamp
      impressions
      engagement
      reach
    }
    totalImpressions
    totalReach
    totalEngagement
    prevTotalImpressions
    prevTotalReach
    prevTotalEngagement
  }
  brand(id: $brandID) {
    fbReportingEnabled
    billingPlanId
    bookingStatus
  }
  billingPlans {
    id
    name
    price
    planType
    period
  }
}
    `;
export const useReportsQuery = <
      TData = ReportsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: ReportsQueryVariables, 
      options?: UseQueryOptions<ReportsQuery, TError, TData>
    ) => 
    useQuery<ReportsQuery, TError, TData>(
      ['Reports', variables],
      fetcher<ReportsQuery, ReportsQueryVariables>(client, ReportsDocument, variables),
      options
    );
export const InstagramBusinessAccountsDocument = `
    query InstagramBusinessAccounts($fbToken: String!) {
  instagramBusinessAccounts(fbToken: $fbToken) {
    id
    name
    username
    profilePictureUrl
  }
}
    `;
export const useInstagramBusinessAccountsQuery = <
      TData = InstagramBusinessAccountsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: InstagramBusinessAccountsQueryVariables, 
      options?: UseQueryOptions<InstagramBusinessAccountsQuery, TError, TData>
    ) => 
    useQuery<InstagramBusinessAccountsQuery, TError, TData>(
      ['InstagramBusinessAccounts', variables],
      fetcher<InstagramBusinessAccountsQuery, InstagramBusinessAccountsQueryVariables>(client, InstagramBusinessAccountsDocument, variables),
      options
    );
export const ListingKeyDatesDocument = `
    query listingKeyDates {
  listingKeyDates {
    name
    startDate
    endDate
    priority
  }
}
    `;
export const useListingKeyDatesQuery = <
      TData = ListingKeyDatesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: ListingKeyDatesQueryVariables, 
      options?: UseQueryOptions<ListingKeyDatesQuery, TError, TData>
    ) => 
    useQuery<ListingKeyDatesQuery, TError, TData>(
      ['listingKeyDates', variables],
      fetcher<ListingKeyDatesQuery, ListingKeyDatesQueryVariables>(client, ListingKeyDatesDocument, variables),
      options
    );
export const LoginDocument = `
    mutation Login($fbToken: String, $googleToken: String, $code: String, $authType: AuthType!, $brandInviteToken: String, $email: String, $password: String, $firstName: String, $lastName: String, $isSignup: Boolean, $recaptchaToken: String) {
  authenticate(
    fbToken: $fbToken
    googleToken: $googleToken
    code: $code
    authType: $authType
    brandInviteToken: $brandInviteToken
    email: $email
    password: $password
    firstName: $firstName
    lastName: $lastName
    isSignup: $isSignup
    recaptchaToken: $recaptchaToken
  ) {
    signup
    account {
      id
      firstName
      lastName
      email
      type
      createdAt
      status
      fbTokenExpiry
      emailVerifiedAt
      creators {
        name
        id
        instagram {
          name
          username
          avatar
          followersCount
          reach28Day
          engagement28Day
        }
        tikTok {
          displayName
          username
          reach28Day
          engagement28Day
        }
        preferredProfile {
          avatar
          username
          name
          followers
          reach28Day
          engagement28Day
        }
      }
      brands {
        id
        name
        logo
        dmnEnabled
        numApplicationsToReview
        createdAt
        instagram {
          name
          username
          avatar
          followersCount
          reach28Day
          engagement28Day
        }
        tikTok {
          avatar
        }
        categories {
          id
          category
        }
        locations {
          locations {
            id
          }
        }
      }
    }
    tokens {
      accessToken
      refreshToken
    }
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>
    ) => 
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables)(),
      options
    );
export const MyBookingsDocument = `
    query myBookings {
  bookings {
    bookings {
      id
      status
      createdAt
      updatedAt
      listing {
        brand {
          name
        }
      }
      location {
        name
      }
      confirmedTimeslot {
        date
        title
        description
      }
    }
  }
}
    `;
export const useMyBookingsQuery = <
      TData = MyBookingsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: MyBookingsQueryVariables, 
      options?: UseQueryOptions<MyBookingsQuery, TError, TData>
    ) => 
    useQuery<MyBookingsQuery, TError, TData>(
      ['myBookings', variables],
      fetcher<MyBookingsQuery, MyBookingsQueryVariables>(client, MyBookingsDocument, variables),
      options
    );
export const PlaceDocument = `
    query place($id: ID!) {
  place(id: $id) {
    id
    address
    addressLine1
    addressLine2
    postcode
    town
    county
    lat
    lng
    phone
    openingHours {
      dayOfWeek
      openTime
      closeTime
    }
  }
}
    `;
export const usePlaceQuery = <
      TData = PlaceQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: PlaceQueryVariables, 
      options?: UseQueryOptions<PlaceQuery, TError, TData>
    ) => 
    useQuery<PlaceQuery, TError, TData>(
      ['place', variables],
      fetcher<PlaceQuery, PlaceQueryVariables>(client, PlaceDocument, variables),
      options
    );
export const PostsDocument = `
    query Posts($input: PostsInput!) {
  posts2(input: $input) {
    posts {
      mediaId
      caption
      mediaUrl
      thumbnailUrl
      type
      timestamp
      impressions
      permalink
    }
    cursor
  }
}
    `;
export const usePostsQuery = <
      TData = PostsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: PostsQueryVariables, 
      options?: UseQueryOptions<PostsQuery, TError, TData>
    ) => 
    useQuery<PostsQuery, TError, TData>(
      ['Posts', variables],
      fetcher<PostsQuery, PostsQueryVariables>(client, PostsDocument, variables),
      options
    );
export const PreviewDocument = `
    query Preview($brandID: ID!) {
  brand(id: $brandID) {
    locations {
      locations {
        id
        name
        instagramHandle
        tikTokHandle
      }
    }
    instagramHandle
    tikTokHandle
    images
  }
}
    `;
export const usePreviewQuery = <
      TData = PreviewQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: PreviewQueryVariables, 
      options?: UseQueryOptions<PreviewQuery, TError, TData>
    ) => 
    useQuery<PreviewQuery, TError, TData>(
      ['Preview', variables],
      fetcher<PreviewQuery, PreviewQueryVariables>(client, PreviewDocument, variables),
      options
    );
export const ProgressBookingDocument = `
    mutation ProgressBooking($bookingId: String!, $token: String, $status: BookingStatus!, $timeSlotId: String, $additionalInfo: String, $rejectionMessage: String, $timeSlots: [Int!], $rescheduleReason: String, $voucherCode: String, $internalRejectionReason: String) {
  progressBooking(
    bookingId: $bookingId
    token: $token
    status: $status
    timeSlotId: $timeSlotId
    additionalInfo: $additionalInfo
    rejectionMessage: $rejectionMessage
    timeSlots: $timeSlots
    rescheduleReason: $rescheduleReason
    voucherCode: $voucherCode
    internalRejectionReason: $internalRejectionReason
  ) {
    id
    toggleCardReference
    designMyNightBookingID
  }
}
    `;
export const useProgressBookingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ProgressBookingMutation, TError, ProgressBookingMutationVariables, TContext>
    ) => 
    useMutation<ProgressBookingMutation, TError, ProgressBookingMutationVariables, TContext>(
      (variables?: ProgressBookingMutationVariables) => fetcher<ProgressBookingMutation, ProgressBookingMutationVariables>(client, ProgressBookingDocument, variables)(),
      options
    );
export const ReportBookingIssueDocument = `
    mutation ReportBookingIssue($issue: String!, $bookingId: String!) {
  reportBookingIssue(issue: $issue, bookingId: $bookingId) {
    success
  }
}
    `;
export const useReportBookingIssueMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ReportBookingIssueMutation, TError, ReportBookingIssueMutationVariables, TContext>
    ) => 
    useMutation<ReportBookingIssueMutation, TError, ReportBookingIssueMutationVariables, TContext>(
      (variables?: ReportBookingIssueMutationVariables) => fetcher<ReportBookingIssueMutation, ReportBookingIssueMutationVariables>(client, ReportBookingIssueDocument, variables)(),
      options
    );
export const ReportMetricsDocument = `
    query ReportMetrics($filters: ReportFilters!) {
  reportMetrics(filters: $filters) {
    metrics {
      timestamp
      impressions
      engagement
      reach
    }
  }
}
    `;
export const useReportMetricsQuery = <
      TData = ReportMetricsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: ReportMetricsQueryVariables, 
      options?: UseQueryOptions<ReportMetricsQuery, TError, TData>
    ) => 
    useQuery<ReportMetricsQuery, TError, TData>(
      ['ReportMetrics', variables],
      fetcher<ReportMetricsQuery, ReportMetricsQueryVariables>(client, ReportMetricsDocument, variables),
      options
    );
export const ResendEmailVerificationDocument = `
    mutation ResendEmailVerification {
  resendEmailVerification {
    id
  }
}
    `;
export const useResendEmailVerificationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ResendEmailVerificationMutation, TError, ResendEmailVerificationMutationVariables, TContext>
    ) => 
    useMutation<ResendEmailVerificationMutation, TError, ResendEmailVerificationMutationVariables, TContext>(
      (variables?: ResendEmailVerificationMutationVariables) => fetcher<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(client, ResendEmailVerificationDocument, variables)(),
      options
    );
export const ResetPasswordDocument = `
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password) {
    id
    email
  }
}
    `;
export const useResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>
    ) => 
    useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
      (variables?: ResetPasswordMutationVariables) => fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(client, ResetPasswordDocument, variables)(),
      options
    );
export const SearchPlacesDocument = `
    query searchPlaces($input: String!) {
  searchPlaces(input: $input) {
    places {
      placeId
      description
      lat
      lng
    }
  }
}
    `;
export const useSearchPlacesQuery = <
      TData = SearchPlacesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: SearchPlacesQueryVariables, 
      options?: UseQueryOptions<SearchPlacesQuery, TError, TData>
    ) => 
    useQuery<SearchPlacesQuery, TError, TData>(
      ['searchPlaces', variables],
      fetcher<SearchPlacesQuery, SearchPlacesQueryVariables>(client, SearchPlacesDocument, variables),
      options
    );
export const SendResetPasswordDocument = `
    mutation SendResetPassword($email: String!) {
  sendResetPassword(email: $email)
}
    `;
export const useSendResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SendResetPasswordMutation, TError, SendResetPasswordMutationVariables, TContext>
    ) => 
    useMutation<SendResetPasswordMutation, TError, SendResetPasswordMutationVariables, TContext>(
      (variables?: SendResetPasswordMutationVariables) => fetcher<SendResetPasswordMutation, SendResetPasswordMutationVariables>(client, SendResetPasswordDocument, variables)(),
      options
    );
export const SetPhoneNumberDocument = `
    mutation SetPhoneNumber($creatorId: String!, $phoneNumber: String!) {
  setPhoneNumber(creatorId: $creatorId, phoneNumber: $phoneNumber) {
    id
  }
}
    `;
export const useSetPhoneNumberMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SetPhoneNumberMutation, TError, SetPhoneNumberMutationVariables, TContext>
    ) => 
    useMutation<SetPhoneNumberMutation, TError, SetPhoneNumberMutationVariables, TContext>(
      (variables?: SetPhoneNumberMutationVariables) => fetcher<SetPhoneNumberMutation, SetPhoneNumberMutationVariables>(client, SetPhoneNumberDocument, variables)(),
      options
    );
export const TimeSlotsDocument = `
    query TimeSlots($listingId: ID!, $locationId: ID!, $type: TimeSlotRequestType, $date: Int) {
  timeSlots(
    listingId: $listingId
    locationId: $locationId
    type: $type
    date: $date
  ) {
    date
    slots
  }
}
    `;
export const useTimeSlotsQuery = <
      TData = TimeSlotsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: TimeSlotsQueryVariables, 
      options?: UseQueryOptions<TimeSlotsQuery, TError, TData>
    ) => 
    useQuery<TimeSlotsQuery, TError, TData>(
      ['TimeSlots', variables],
      fetcher<TimeSlotsQuery, TimeSlotsQueryVariables>(client, TimeSlotsDocument, variables),
      options
    );
export const ToggleProductsDocument = `
    query ToggleProducts {
  toggleProducts {
    id
    name
    shortDescription
    active
    priceModifiers {
      id
      value
      description
    }
    maxValue
    minValue
  }
}
    `;
export const useToggleProductsQuery = <
      TData = ToggleProductsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: ToggleProductsQueryVariables, 
      options?: UseQueryOptions<ToggleProductsQuery, TError, TData>
    ) => 
    useQuery<ToggleProductsQuery, TError, TData>(
      ['ToggleProducts', variables],
      fetcher<ToggleProductsQuery, ToggleProductsQueryVariables>(client, ToggleProductsDocument, variables),
      options
    );
export const UpdateAcccountDocument = `
    mutation UpdateAcccount($id: ID, $email: String, $firstName: String, $lastName: String, $type: AccountType) {
  updateAccount(
    id: $id
    email: $email
    firstName: $firstName
    lastName: $lastName
    type: $type
  ) {
    id
    firstName
    lastName
    email
  }
}
    `;
export const useUpdateAcccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateAcccountMutation, TError, UpdateAcccountMutationVariables, TContext>
    ) => 
    useMutation<UpdateAcccountMutation, TError, UpdateAcccountMutationVariables, TContext>(
      (variables?: UpdateAcccountMutationVariables) => fetcher<UpdateAcccountMutation, UpdateAcccountMutationVariables>(client, UpdateAcccountDocument, variables)(),
      options
    );
export const UpdateBrandDocument = `
    mutation UpdateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    id
  }
}
    `;
export const useUpdateBrandMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateBrandMutation, TError, UpdateBrandMutationVariables, TContext>
    ) => 
    useMutation<UpdateBrandMutation, TError, UpdateBrandMutationVariables, TContext>(
      (variables?: UpdateBrandMutationVariables) => fetcher<UpdateBrandMutation, UpdateBrandMutationVariables>(client, UpdateBrandDocument, variables)(),
      options
    );
export const UpdateEmailSubscriptionsDocument = `
    mutation UpdateEmailSubscriptions($input: UpdateEmailSubscriptionInput!) {
  updateEmailSubscription(input: $input)
}
    `;
export const useUpdateEmailSubscriptionsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateEmailSubscriptionsMutation, TError, UpdateEmailSubscriptionsMutationVariables, TContext>
    ) => 
    useMutation<UpdateEmailSubscriptionsMutation, TError, UpdateEmailSubscriptionsMutationVariables, TContext>(
      (variables?: UpdateEmailSubscriptionsMutationVariables) => fetcher<UpdateEmailSubscriptionsMutation, UpdateEmailSubscriptionsMutationVariables>(client, UpdateEmailSubscriptionsDocument, variables)(),
      options
    );
export const UpdateListingDocument = `
    mutation UpdateListing($id: ID!, $name: String, $details: String, $terms: String, $maxSpend: Int, $startDate: Int, $endDate: Int, $status: ListingStatus, $locations: [ID!], $days: [Days!], $bookingOffset: Int, $minFollowers: Int, $maxFollowers: Int, $toggleProductId: Int, $toggleProductValue: Int, $requiresPhone: Boolean!, $timeSlots: [Int!], $schedules: [ScheduleInput!], $images: [String!]!, $type: ListingType, $approvalSettings: ApprovalSettingsInput, $dmnBookingTypeID: String, $categories: [String!], $minEngagementRate: Float, $dietaryPreferences: [String!], $numberOfPeople: Int, $internalBookingNotes: String, $collinsBookingAutoCreate: Boolean, $instagramHandles: [String!], $requiredPosts: Int, $requiredReels: Int, $requiredPostAndReel: Boolean, $requiredStories: Int, $requiredTikToks: Int, $tikTokHandles: [String!], $instagramTags: [String!], $tikTokTags: [String!], $collinsBookingCreationTypeID: String, $minAge: Int, $featuredVideo: String!, $offers: [ListingOfferInput!]!, $visibleDate: Int) {
  updateListing(
    id: $id
    name: $name
    details: $details
    terms: $terms
    maxSpend: $maxSpend
    startDate: $startDate
    endDate: $endDate
    status: $status
    locations: $locations
    days: $days
    bookingOffset: $bookingOffset
    minFollowers: $minFollowers
    maxFollowers: $maxFollowers
    toggleProductId: $toggleProductId
    toggleProductValue: $toggleProductValue
    requiresPhone: $requiresPhone
    timeSlots: $timeSlots
    schedules: $schedules
    images: $images
    type: $type
    approvalSettings: $approvalSettings
    dmnBookingTypeID: $dmnBookingTypeID
    categories: $categories
    minEngagementRate: $minEngagementRate
    dietaryPreferences: $dietaryPreferences
    numberOfPeople: $numberOfPeople
    internalBookingNotes: $internalBookingNotes
    collinsBookingAutoCreate: $collinsBookingAutoCreate
    instagramHandles: $instagramHandles
    tikTokHandles: $tikTokHandles
    instagramTags: $instagramTags
    requiredPosts: $requiredPosts
    requiredReels: $requiredReels
    requiredPostAndReel: $requiredPostAndReel
    requiredStories: $requiredStories
    requiredTikToks: $requiredTikToks
    tikTokTags: $tikTokTags
    collinsBookingCreationTypeID: $collinsBookingCreationTypeID
    minAge: $minAge
    featuredVideo: $featuredVideo
    offers: $offers
    visibleDate: $visibleDate
  ) {
    id
  }
}
    `;
export const useUpdateListingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateListingMutation, TError, UpdateListingMutationVariables, TContext>
    ) => 
    useMutation<UpdateListingMutation, TError, UpdateListingMutationVariables, TContext>(
      (variables?: UpdateListingMutationVariables) => fetcher<UpdateListingMutation, UpdateListingMutationVariables>(client, UpdateListingDocument, variables)(),
      options
    );
export const UpdateLocationDocument = `
    mutation UpdateLocation($id: ID!, $name: String!, $email: String, $excludedHours: [ExcludedHoursInput!], $openingHours: [OpeningHoursInput!], $dmnVenueId: String) {
  updateLocation(
    id: $id
    name: $name
    email: $email
    excludedHours: $excludedHours
    openingHours: $openingHours
    dmnVenueId: $dmnVenueId
  ) {
    id
  }
}
    `;
export const useUpdateLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateLocationMutation, TError, UpdateLocationMutationVariables, TContext>
    ) => 
    useMutation<UpdateLocationMutation, TError, UpdateLocationMutationVariables, TContext>(
      (variables?: UpdateLocationMutationVariables) => fetcher<UpdateLocationMutation, UpdateLocationMutationVariables>(client, UpdateLocationDocument, variables)(),
      options
    );
export const UpdateRejectionReasonsDocument = `
    mutation UpdateRejectionReasons($reasons: [RejectionReasonInput!]!) {
  updateRejectionReasons(reasons: $reasons) {
    id
    reason
  }
}
    `;
export const useUpdateRejectionReasonsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateRejectionReasonsMutation, TError, UpdateRejectionReasonsMutationVariables, TContext>
    ) => 
    useMutation<UpdateRejectionReasonsMutation, TError, UpdateRejectionReasonsMutationVariables, TContext>(
      (variables?: UpdateRejectionReasonsMutationVariables) => fetcher<UpdateRejectionReasonsMutation, UpdateRejectionReasonsMutationVariables>(client, UpdateRejectionReasonsDocument, variables)(),
      options
    );
export const VerifyEmailDocument = `
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token) {
    id
    emailVerifiedAt
  }
}
    `;
export const useVerifyEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>
    ) => 
    useMutation<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>(
      (variables?: VerifyEmailMutationVariables) => fetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(client, VerifyEmailDocument, variables)(),
      options
    );