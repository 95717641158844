import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { Toaster } from "sonner";
import { CheckCircle } from "./components/icons/CheckCircle";
import config, { MOBILE_BREAKPOINT } from "./config";
import { useAccountsQuery } from "./graphql/generated";
import useAnalytics from "./hooks/useAnalytics";
import useGqlClient from "./hooks/useGqlClient";
import { useTheme } from "./hooks/useTheme";
import DarkThemeProvider from "./providers/darkThemeProvider";
import Routes from "./routes";
import { authSelectors } from "./store/auth/selector";
import { actions } from "./store/auth/slice";
import { createGlobalStyle } from "./styles";

const queryClient = new QueryClient();

posthog.init(config.posthogToken, {
  api_host: "https://eu.posthog.com",
  opt_in_site_apps: true,
});

const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    min-height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
  }

  html, body, textarea, input {
    font-family: 'Hind', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${(p) => p.theme.color.background.behind};

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      background: linear-gradient(98deg, ${(p) =>
        p.theme.color.background.start} 0%, ${(p) =>
  p.theme.color.background.end} 100%), ${(p) => p.theme.color.background.start};
    }
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
`;

// Sentry logger
setLogger({
  log: (message) => {
    Sentry.captureMessage(message);
  },
  warn: (message) => {
    Sentry.captureMessage(message);
  },
  error: (error) => {
    Sentry.captureException(error);
  },
});

function App() {
  const theme = useTheme();
  const token = useSelector(authSelectors.accessToken);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      return;
    }

    const decoded: any = jwt_decode(token);
    const expires = new Date(decoded.exp * 1000);

    if (expires < new Date()) {
      dispatch(actions.loginFailure());
      history.push("/login");
    }
  }, [token, dispatch, history]);

  const toastOptions = {
    style: {
      background: theme.color.card.background,
      color: theme.color.typography.text,
      boxShadow: theme.shadow.popover,
      border: `1px solid ${theme.color.typography.text}33`,
    },
  };

  return (
    <DarkThemeProvider>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <IntercomProvider appId={config.intercomToken} autoBoot>
            <GoogleOAuthProvider clientId="1049483753159-iaqom4fmjguhg56h3oi6ig5ghl7m6sa5.apps.googleusercontent.com">
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <GlobalStyles />
              <GlobalData />
              <Routes />
              <Toaster
                position="top-right"
                theme={theme.name === "dark" ? "dark" : "light"}
                toastOptions={toastOptions}
                icons={{
                  success: (
                    <CheckCircle
                      color={theme.color.stats.positiveValue}
                      checked
                    />
                  ),
                }}
              />
            </GoogleOAuthProvider>
          </IntercomProvider>
        </PostHogProvider>
      </QueryClientProvider>
    </DarkThemeProvider>
  );
}

const GlobalData = () => {
  const dispatch = useDispatch();
  const client = useGqlClient();
  const auth = useSelector(authSelectors.data);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { identifyUser } = useAnalytics();
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const accountQuery = useAccountsQuery(
    client,
    {},
    {
      enabled: isLoggedIn,
    }
  );

  const { update } = useIntercom();

  useEffect(() => {
    if (!accountQuery.data) {
      return;
    }

    // To handle if their active brand gets deleted
    if (activeBrandId && accountQuery.data.account.brands) {
      const isBrandInAccount = accountQuery.data.account.brands.some(
        (brand) => brand.id === activeBrandId
      );
      if (!isBrandInAccount && accountQuery.data.account.brands.length > 0) {
        dispatch(
          actions.setActiveBrand({
            brandId: accountQuery.data.account.brands[0].id,
          })
        );
      } else if (!isBrandInAccount) {
        dispatch(actions.logout());
      }
    }

    if (accountQuery.isSuccess && isLoggedIn) {
      dispatch(actions.refreshAccount(accountQuery.data.account));
    }
  }, [
    accountQuery.data,
    dispatch,
    activeBrandId,
    isLoggedIn,
    accountQuery.isSuccess,
  ]);

  useEffect(() => {
    if (auth.isLoggedIn && auth.account) {
      let companies: any[] = [];

      if (auth.account.brands) {
        companies.push(
          ...auth.account.brands.map((b) => {
            return {
              companyId: b.id,
              name: b.name,
              customAttributes: {
                ig_handle: b.instagram ? b.instagram.username : "Unknown",
                ig_name: b.instagram ? b.instagram.name : "Unknown",
                ig_followers: b.instagram ? b.instagram.followersCount : 0,
                ig_reach: b.instagram ? b.instagram.reach28Day : 0,
                ig_engagement: b.instagram ? b.instagram.engagement28Day : 0,
                companyType: "brand",
              },
            };
          })
        );
      }

      const acc = auth.account;
      // const activeCreator =
      //   auth.activeCreatorId && acc.creators && acc.creators.length > 0
      //     ? acc.creators.filter((e) => e.id === auth.activeCreatorId)[0].name
      //     : "";

      // const activeBrand =
      //   auth.activeBrandId &&
      //   acc.brands &&
      //   acc.brands.length > 0 &&
      //   acc.brands.find((e) => e.id === auth.activeBrandId)
      //     ? acc.brands.filter((e) => e.id === auth.activeBrandId)[0].name
      //     : "";

      //  Intercom update companies
      update({
        userId: acc.id,
        name: acc.firstName + " " + acc.lastName,
        email: acc.email,
        companies,
        customAttributes: {
          account_type: acc.type,
          account_status: acc.status,
        },
      });

      identifyUser(acc.id, {
        name: acc.firstName + " " + acc.lastName,
        email: acc.email,
      });

      Sentry.setUser({ userId: acc.id, email: acc.email });
    }
  }, [auth, update, identifyUser, activeBrandId]);

  return null;
};

export default Sentry.withProfiler(App);
