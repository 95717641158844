import { MobileMenuIcon } from "./icons/MobileMenuIcon";

function MobileMenu({
  showMenu,
  menuToggle,
}: {
  showMenu: boolean;
  menuToggle: (x: boolean) => void;
}) {
  return (
    <div onClick={() => menuToggle(!showMenu)}>
      <MobileMenuIcon />
    </div>
  );
}

export default MobileMenu;
