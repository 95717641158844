import "@uploadcare/react-uploader/core.css";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { Button, Submit } from "../../components/CTA";
import { Card } from "../../components/Card";
import { SingleCheckBox } from "../../components/Checkbox";
import { CardDivider } from "../../components/Divider";
import { Flex } from "../../components/Flex";
import { H1, H3 } from "../../components/Heading";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Subtitle } from "../../components/Subtitle";
import { Switch } from "../../components/Switch";
import { Text } from "../../components/Text";
import { ExternalTextLink } from "../../components/TextLink";
import { View } from "../../components/View";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { DarkMode } from "../../components/icons/DarkMode";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  useAccountsQuery,
  useEmailSubscriptionsQuery,
  useUpdateAcccountMutation,
  useUpdateEmailSubscriptionsMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { preferencesSelectors } from "../../store/preferences/selector";
import { actions as preferenceActions } from "../../store/preferences/slice";
import { styled } from "../../styles";

const Wrap = styled.div`
  width: 600px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }

  margin-bottom: ${(p) => p.theme.spacing.l};

  overflow: visible;
`;

export const AccountSettings = () => {
  return (
    <Wrap>
      <H1 margin="0 0 0">Account Settings</H1>
      <Subtitle margin="xs 0 l 0">
        Manage your account details and email preferences
      </Subtitle>

      <View padding="0 0 xl">
        <StyledCard>
          <AccountForm />
        </StyledCard>
        <StyledCard>
          <CommunicationForm />
        </StyledCard>
        <StyledCard>
          <DarkModeForm />
        </StyledCard>
      </View>
    </Wrap>
  );
};

const AccountForm = () => {
  const { track } = useAnalytics();
  const client = useGqlClient();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const queryClient = useQueryClient();
  const accountQuery = useAccountsQuery(
    client,
    {},
    {
      retry: false,
    }
  );

  const updateAccount = useUpdateAcccountMutation(client);

  const { isLoading, data, isError } = accountQuery;

  useEffect(() => {
    if (data) {
      setFirstName(data.account.firstName);
      setLastName(data.account.lastName);
      setEmail(data.account.email);
    }
  }, [data]);

  if (!data) {
    return <Loading />;
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateAccount.mutate(
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
      },
      {
        onSuccess: () => {
          track("User Profile Updated");
          toast.success("Account updated");
          queryClient.resetQueries(["Accounts"], {
            exact: false,
          });
        },
        onError: () => {
          toast.error("Failed to update account");
        },
      }
    );
  };

  return (
    <View>
      <H3 margin="0 0 m">Your Details</H3>
      <form onSubmit={onSubmit}>
        <Input
          label="First Name"
          value={firstName}
          name="firstName"
          onChange={(e) => setFirstName(e.target.value)}
          margin="0 0 xl 0"
        />
        <Input
          label="Last Name"
          value={lastName}
          name="lastName"
          onChange={(e) => setLastName(e.target.value)}
          margin="0 0 xl 0"
        />
        <Text weight="semi" margin="0 0 0">
          Email
        </Text>
        <Text size="s" colorPreset="secondary" margin="0 0 s">
          Need to change your email? Reach out to the Joli team via the support
          chat
        </Text>
        <View style={{ position: "relative" }} margin="0 0 xl 0">
          {data.account.email && data.account.emailVerifiedAt && (
            <AbsoluteWrap>
              <CheckCircle checked={true} />
            </AbsoluteWrap>
          )}
          <Input
            margin="0 0 s 0"
            isDisabled={
              data.account.email && data.account.emailVerifiedAt ? true : false
            }
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            name="email"
          />
        </View>
        <Submit
          type="submit"
          value={isLoading ? "Loading..." : "Save"}
          margin="xl 0 0 0"
        />
      </form>
    </View>
  );
};

const CommunicationForm = () => {
  const [subscriptions, setSubscriptions] = useState({
    dailyDigest: false,
    newApplication: false,
    bookingConfirmation: false,
    bookingCancelled: false,
    bookingReschedule: false,
  });
  const client = useGqlClient();
  const {
    data: emailData,
    isLoading,
    error,
  } = useEmailSubscriptionsQuery(
    client,
    {},
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateEmailSubscriptions = useUpdateEmailSubscriptionsMutation(client);

  useEffect(() => {
    if (emailData) {
      setSubscriptions(emailData.emailSubscriptions);
    }
  }, [emailData]);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !emailData) {
    return null;
  }

  return (
    <View>
      <H3 margin="0 0 m">Email Preferences</H3>
      <Flex
        direction="row"
        align="center"
        margin="0 0 0 0"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setSubscriptions({
            ...subscriptions,
            dailyDigest: !subscriptions.dailyDigest,
          });
        }}
        justify="space-between"
      >
        <View flex={1}>
          <Text weight="bold" margin="0" style={{ userSelect: "none" }}>
            Daily Digest
          </Text>
          <Text
            colorPreset="secondary"
            size="xs"
            margin="0"
            isCompact
            style={{ userSelect: "none" }}
          >
            Get updates on bookings, milestones, feedback, and more—all in one
            email
          </Text>
          <Text
            colorPreset="secondary"
            size="xxs"
            margin="xxs 0 0"
            isCompact
            style={{ userSelect: "none", fontStyle: "italic" }}
          >
            <ExternalTextLink
              size="xxs"
              href="https://wenibble-images.s3.eu-central-1.amazonaws.com/marketing/digest/example-digest.jpg"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              View example
            </ExternalTextLink>{" "}
            (We’ll only send it when there’s something new to share)
          </Text>
        </View>
        <Switch
          value={subscriptions.dailyDigest}
          onChange={() => {
            setSubscriptions({
              ...subscriptions,
              dailyDigest: !subscriptions.dailyDigest,
            });
          }}
        />
      </Flex>
      <CardDivider margin="xl 0 xl 0" />
      <Text weight="bold" margin="0 0 xs">
        Additional Notifications
      </Text>
      <NotificationGrid>
        <Flex
          direction="row"
          align="flex-start"
          margin="m l 0 0"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSubscriptions({
              ...subscriptions,
              newApplication: !subscriptions.newApplication,
            });
          }}
        >
          <View margin="xxs 0 0">
            <SingleCheckBox checked={subscriptions.newApplication} />
          </View>
          <View margin="0 0 0 m" style={{ flex: 1 }}>
            <Text
              weight="semi"
              size="s"
              margin="0"
              style={{ userSelect: "none" }}
            >
              New Applications
            </Text>
            <Text
              colorPreset="secondary"
              margin="0"
              size="xxs"
              isCompact
              style={{ userSelect: "none" }}
            >
              Get notified as soon as an influencer applies to your listing
            </Text>
          </View>
        </Flex>
        <Flex
          direction="row"
          align="flex-start"
          margin="m l 0 0"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSubscriptions({
              ...subscriptions,
              bookingConfirmation: !subscriptions.bookingConfirmation,
            });
          }}
        >
          <View margin="xxs 0 0">
            <SingleCheckBox checked={subscriptions.bookingConfirmation} />
          </View>
          <View margin="0 0 0 m" style={{ flex: 1 }}>
            <Text
              weight="semi"
              size="s"
              margin="0"
              style={{ userSelect: "none" }}
            >
              Booking Confirmations
            </Text>
            <Text
              colorPreset="secondary"
              margin="0"
              size="xxs"
              isCompact
              style={{ userSelect: "none" }}
            >
              Receive full details and calendar invites for every confirmed
              booking
            </Text>
          </View>
        </Flex>
        <Flex
          direction="row"
          align="flex-start"
          margin="m l 0 0"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSubscriptions({
              ...subscriptions,
              bookingReschedule: !subscriptions.bookingReschedule,
            });
          }}
        >
          <View margin="xxs 0 0">
            <SingleCheckBox checked={subscriptions.bookingReschedule} />
          </View>
          <View margin="0 0 0 m" style={{ flex: 1 }}>
            <Text
              weight="semi"
              size="s"
              margin="0"
              style={{ userSelect: "none" }}
            >
              Rescheduled Bookings
            </Text>
            <Text
              colorPreset="secondary"
              margin="0"
              size="xxs"
              isCompact
              style={{ userSelect: "none" }}
            >
              Stay updated on all reschedule requests from influencers
            </Text>
          </View>
        </Flex>
        <Flex
          direction="row"
          align="flex-start"
          margin="m l 0 0"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSubscriptions({
              ...subscriptions,
              bookingCancelled: !subscriptions.bookingCancelled,
            });
          }}
        >
          <View margin="xxs 0 0">
            <SingleCheckBox checked={subscriptions.bookingCancelled} />
          </View>
          <View margin="0 0 0 m" style={{ flex: 1 }}>
            <Text
              weight="semi"
              size="s"
              margin="0"
              style={{ userSelect: "none" }}
            >
              Cancelled Bookings
            </Text>
            <Text
              colorPreset="secondary"
              margin="0"
              size="xxs"
              isCompact
              style={{ userSelect: "none" }}
            >
              Instant alerts for any booking cancellations with full details
            </Text>
          </View>
        </Flex>
      </NotificationGrid>
      <Button
        onClick={() => {
          updateEmailSubscriptions.mutate(
            {
              input: {
                dailyDigest: subscriptions.dailyDigest,
                newApplication: subscriptions.newApplication,
                bookingConfirmation: subscriptions.bookingConfirmation,
                bookingCancelled: subscriptions.bookingCancelled,
                bookingReschedule: subscriptions.bookingReschedule,
              },
            },
            {
              onSuccess: () => {
                toast.success("Email preferences updated");
              },
              onError: () => {
                toast.error("Failed to update email preferences");
              },
            }
          );
        }}
        margin="xxl 0 0 0"
      >
        {updateEmailSubscriptions.isLoading
          ? "Loading..."
          : "Update Email Preferences"}
      </Button>
    </View>
  );
};

const NotificationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const AbsoluteWrap = styled.div`
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.spacing.m};
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
`;

const DarkModeForm = () => {
  const dispatch = useDispatch();
  const selectedTheme = useSelector(preferencesSelectors.selectedTheme);
  const theme = selectedTheme || "auto";

  return (
    <View>
      <H3 margin="0 0 0 0">Appearance</H3>
      <Text margin="0 0 m 0" size="s" colorPreset="secondary">
        Selecting auto will adjust your appearance based on your device's system
        settings
      </Text>
      <ThemeGrid>
        <ThemeGridItem
          onClick={() =>
            dispatch(
              preferenceActions.setSelectedTheme({
                selectedTheme: "light",
              })
            )
          }
        >
          <DarkMode type="light" />
        </ThemeGridItem>

        <ThemeGridItem
          onClick={() =>
            dispatch(
              preferenceActions.setSelectedTheme({
                selectedTheme: "dark",
              })
            )
          }
        >
          <DarkMode type="dark" />
        </ThemeGridItem>

        <ThemeGridItem
          onClick={() =>
            dispatch(
              preferenceActions.setSelectedTheme({ selectedTheme: null })
            )
          }
        >
          <DarkMode type="auto" />
        </ThemeGridItem>
      </ThemeGrid>
    </View>
  );
};

const ThemeGridItem = styled.div`
  cursor: pointer;
`;

const ThemeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${(p) => p.theme.spacing.m};
  width: 100%;
  max-width: 420px;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    gap: ${(p) => p.theme.spacing.s};
    max-width: 100%;
  }
`;
