import {
  add,
  format,
  formatDistanceToNow,
  fromUnixTime,
  getUnixTime,
  isSameDay,
  startOfDay,
} from "date-fns";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingStatus,
  BrandScheduleSnapshotQuery,
  GetBookingQuery,
  ListingType,
  ProgressBookingError,
  TimeSlotRequestType,
  useBrandScheduleSnapshotQuery,
  useProgressBookingMutation,
  useTimeSlotsQuery,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { AutoSuggestBookingNotes } from "../../AutoSuggestTextArea";
import { FakeBackLink } from "../../BackLink";
import { Button } from "../../CTA";
import { Callout } from "../../Callout";
import { CancelIcon } from "../../CancelIcon";
import { Chevron } from "../../Chevron";
import { CloseIcon } from "../../CloseIcon";
import { Divider } from "../../Divider";
import { Flex } from "../../Flex";
import { H2, H3 } from "../../Heading";
import { Input } from "../../Input";
import Loader from "../../Loader";
import Loading from "../../Loading";
import { InlineAddBtn } from "../../NewButton";
import { PreferredProfile } from "../../PreferredProfile";
import { Underline } from "../../Tabs";
import { Text } from "../../Text";
import { TextArea } from "../../TextArea";
import { TimeSlots } from "../../TimeSlots";
import { ToggleButton } from "../../ToggleButton";
import { View } from "../../View";
import { BoltIcon } from "../../icons/BoltIcon";
import { ClockIcon } from "../../icons/ClockIcon";
import { LocationPinOutlineIcon } from "../../icons/LocationPinOutline";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  grid-template-rows: auto;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const LeftColumn = styled.div`
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};
  position: relative;
`;

const RightColumn = styled.div`
  overflow-y: scroll;
  position: relative;
  max-height: 90vh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const CloseButtonWrap = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.l};
  right: ${(p) => p.theme.spacing.l};
  z-index: 1;
  cursor: pointer;
`;

interface ScheduleContentProps {
  showReschedule: boolean;
  setShowReschedule: (show: boolean) => void;
  booking: GetBookingQuery["booking"];
  onCancel: () => void;
}

interface Props {
  booking: GetBookingQuery["booking"];
  onCancel: () => void;
}

const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
  }
`;

export const ScheduleOverlay = (props: Props) => {
  const [showReschedule, setShowReschedule] = useState(false);

  return (
    <Grid>
      <LeftColumn>
        <ScheduleContent
          {...props}
          showReschedule={showReschedule}
          setShowReschedule={setShowReschedule}
        />
        <MobileOnly>
          <CloseButtonWrap onClick={props.onCancel}>
            <CloseIcon height={26} width={26} colorPreset="text" />
          </CloseButtonWrap>
        </MobileOnly>
      </LeftColumn>
      <RightColumn>
        <CloseButtonWrap onClick={props.onCancel}>
          <CloseIcon height={26} width={26} colorPreset="text" />
        </CloseButtonWrap>
        <ExistingBookings {...props} showReschedule={showReschedule} />
      </RightColumn>
    </Grid>
  );
};

interface ExistingBookingsProps {
  booking: GetBookingQuery["booking"];
  showReschedule: boolean;
}

type SnapshotBooking = {
  booking: BrandScheduleSnapshotQuery["bookings"]["bookings"][0];
  location: BrandScheduleSnapshotQuery["bookings"]["bookings"][0]["location"];
  confirmedTimeslot:
    | BrandScheduleSnapshotQuery["bookings"]["bookings"][0]["confirmedTimeslot"]
    | null;
};

const ExistingBookings = (props: ExistingBookingsProps) => {
  const grouped: { [key: string]: SnapshotBooking[] } = {};
  const client = useGqlClient();
  const { data, isLoading } = useBrandScheduleSnapshotQuery(
    client,
    {
      statuses: [BookingStatus.BookingStatusApproved],
      count: 100,
    },
    {
      enabled: true,
    }
  );

  const { data: daysData } = useTimeSlotsQuery(client, {
    listingId: props.booking.listing.id,
    locationId: props.booking.location ? props.booking.location.id : "",
    type: TimeSlotRequestType.TimeslotRequestTypeDays,
  });

  if (!data) {
    return null;
  }

  if (isLoading) {
    return (
      <CardCallout flex={1} style={{ height: "100%" }}>
        <Flex justify="center" align="center" margin="xxl 0 0">
          <Loader />
        </Flex>
      </CardCallout>
    );
  }

  const suggestedTimeslotDays = props.showReschedule
    ? daysData &&
      daysData.timeSlots.map((s) =>
        format(startOfDay(fromUnixTime(s.date)), "EEEE, do MMMM y")
      )
    : props.booking.suggestedTimeSlots.map((s) =>
        format(startOfDay(fromUnixTime(s.date)), "EEEE, do MMMM y")
      );

  const suggestedDays = [...new Set(suggestedTimeslotDays)];

  const anytimeBookings = data.bookings.bookings.filter(
    (e) =>
      !e.confirmedTimeslot &&
      e.type === ListingType.RedeemAnytime &&
      !e.redeemedAt &&
      e.creator.preferredProfile
  );

  data.bookings.bookings
    .filter((b) => b.confirmedTimeslot)
    .sort((a, b) => a.confirmedTimeslot!.date - b.confirmedTimeslot!.date)
    .forEach((b) => {
      const key = format(
        startOfDay(fromUnixTime(b.confirmedTimeslot!.date)),
        "EEEE, do MMMM y"
      );

      if (grouped[key]) {
        grouped[key].push({
          booking: b,
          location: b.location,
          confirmedTimeslot: b.confirmedTimeslot,
        });
      } else {
        grouped[key] = [
          {
            booking: b,
            location: b.location,
            confirmedTimeslot: b.confirmedTimeslot,
          },
        ];
      }
    });

  return (
    <CardCallout flex={1}>
      <View>
        <Text size="l" weight="bold" margin="m 0 0">
          Schedule Snapshot
        </Text>
        <Underline selected={true} />
      </View>
      {suggestedDays.map((day) => (
        <div key={day}>
          <Text size="s" isInline weight="bold" margin="xl 0 s">
            {day}
          </Text>
          {grouped[day]?.length ? (
            grouped[day].map((b) =>
              b.booking.creator.preferredProfile ? (
                <BookingsCard key={b.booking.id}>
                  <Flex align="center" margin="0 0 s">
                    <NameLine />
                    <Text isCompact margin="0 0 0 s" weight="bold">
                      {b.booking.creator.preferredProfile.name.length > 38
                        ? `${b.booking.creator.preferredProfile.name.slice(
                            0,
                            38
                          )}...`
                        : b.booking.creator.preferredProfile.name}
                    </Text>
                  </Flex>
                  <Flex direction="row" align="center">
                    <Flex align="center" margin="0 0 0">
                      <ClockIcon width={14} colorPreset="secondary" />
                      {b.confirmedTimeslot && (
                        <Text
                          isCompact
                          margin="0 0 0 s"
                          style={{ marginTop: 2 }}
                          size="s"
                        >
                          {format(
                            fromUnixTime(b.confirmedTimeslot.date),
                            "h:mm a"
                          )}
                        </Text>
                      )}
                    </Flex>
                    <Flex direction="row" align="center" margin="0 0 0 m">
                      <LocationPinOutlineIcon
                        width={19}
                        colorPreset="secondary"
                      />
                      {b.location && (
                        <Text
                          isCompact
                          size="s"
                          margin="0 0 0 xs"
                          style={{ marginTop: 2 }}
                        >
                          {b.location.name}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </BookingsCard>
              ) : null
            )
          ) : (
            <EmptyBookingsCard key={day}>
              <Text margin="0" align="center">
                No other bookings
              </Text>
            </EmptyBookingsCard>
          )}
        </div>
      ))}
      {anytimeBookings && anytimeBookings.length > 0 ? (
        <div>
          <Text size="s" isInline weight="bold" margin="xl 0 s">
            Anytime Redeem
          </Text>
          {anytimeBookings.map((b) => (
            <BookingsCard key={b.id}>
              <Flex align="center" margin="0 0 s">
                <NameLine />
                <Text isCompact margin="0 0 0 s" weight="bold">
                  {b.creator.preferredProfile!.name.length > 38
                    ? `${b.creator.preferredProfile!.name.slice(0, 38)}...`
                    : b.creator.preferredProfile!.name}
                </Text>
              </Flex>
              <Flex direction="row" align="center">
                <Flex align="center" margin="0 0 0">
                  <ClockIcon width={14} colorPreset="secondary" />
                  {b.approvedAt && (
                    <Text
                      isCompact
                      margin="0 0 0 s"
                      style={{ marginTop: 2 }}
                      size="s"
                    >
                      Expires in{" "}
                      {formatDistanceToNow(
                        add(fromUnixTime(b.approvedAt), { days: 30 })
                      )}
                    </Text>
                  )}
                </Flex>
                {b.location && (
                  <Flex direction="row" align="center" margin="0 0 0 m">
                    <LocationPinOutlineIcon
                      width={19}
                      colorPreset="secondary"
                    />

                    <Text
                      isCompact
                      size="s"
                      margin="0 0 0 xs"
                      style={{ marginTop: 2 }}
                    >
                      {b.location.name}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </BookingsCard>
          ))}
        </div>
      ) : suggestedDays.length === 0 ? (
        <>
          <Text size="s" isInline weight="bold" margin="xl 0 s">
            Anytime Redeem
          </Text>
          <EmptyBookingsCard>
            <Text margin="0" align="center">
              No other bookings
            </Text>
          </EmptyBookingsCard>
        </>
      ) : null}
    </CardCallout>
  );
};

export const NameLine = styled.div`
  width: 3px;
  height: 20px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.primary};
`;

const BookingsCard = styled(View)`
  background-color: ${(p) => p.theme.color.card.background};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.l};
  border: 1px solid ${(p) => p.theme.color.card.divider};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const EmptyBookingsCard = styled(View)`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.l};
  border: 1px solid ${(p) => p.theme.color.card.divider};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const CardCallout = styled(View)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: 0;
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.l};
  min-height: 100%;
  border-left: 1px solid ${(p) => p.theme.color.card.divider};
`;

const ScheduleContent = (props: ScheduleContentProps) => {
  const [info, setInfo] = useState("");
  const [approvedTimeslot, setApprovedTimeslot] = useState<string | null>(null);
  const [approvedTimeslotUnix, setApprovedTimeslotUnix] =
    useState<Number | null>(null);
  const [activeDate, setActiveDate] = useState<number | null>(null);
  const [voucherCode, setVoucherCode] = useState("");
  const [showVoucherCode, setShowVoucherCode] = useState(false);
  const [suggestedTimeSlots, setSuggestedTimeSlots] = useState<Date[]>([]);
  const [rescheduleReason, setRescheduleReason] = useState("");
  const [rescheduleReasonError, setRescheduleReasonError] = useState("");

  const client = useGqlClient();
  const progressBooking = useProgressBookingMutation(client);
  const queryClient = useQueryClient();

  const theme = useTheme();
  const { track } = useAnalytics();
  const brand = useSelector(authSelectors.activeBrand);
  const history = useHistory();

  useEffect(() => {
    const ts = props.booking.suggestedTimeSlots.filter(
      (e) => e.id === approvedTimeslot
    );

    if (!approvedTimeslot || ts.length === 0) {
      setApprovedTimeslotUnix(null);
      return;
    }

    setApprovedTimeslotUnix(ts[0].date);
  }, [
    approvedTimeslot,
    setApprovedTimeslotUnix,
    props.booking.suggestedTimeSlots,
  ]);

  useEffect(() => {
    if (!activeDate && props.booking.suggestedTimeSlots.length > 0) {
      setActiveDate(props.booking.suggestedTimeSlots[0].date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.booking.id]);

  const RenderTimePicker = () => {
    const sortedTimeSlots = props.booking.suggestedTimeSlots
      .sort((a, b) => a.date - b.date)
      .map((s) => s.date);

    const filteredTimeSlots: number[] = [];
    sortedTimeSlots.forEach((ts) => {
      if (
        filteredTimeSlots.filter((fts) =>
          isSameDay(fromUnixTime(fts), fromUnixTime(ts))
        ).length !== 0
      ) {
        return;
      }

      filteredTimeSlots.push(ts);
    });

    return (
      <>
        {props.booking.suggestedTimeSlots.length === 0 ? (
          <Text margin={"s 0 xl 0"} color={theme.color.warning}>
            Their suggested dates are no longer available
          </Text>
        ) : (
          <DaysWrap>
            {filteredTimeSlots.map((d) => (
              <DayWrap
                onClick={() => setActiveDate(d)}
                key={d}
                active={activeDate === d}
              >
                <Dates
                  margin="xs 0 0 0"
                  size="s"
                  color={
                    approvedTimeslot &&
                    isSameDay(
                      fromUnixTime(
                        props.booking.suggestedTimeSlots.filter(
                          (e) => e.id === approvedTimeslot
                        )[0]?.date
                      ),
                      fromUnixTime(d)
                    )
                      ? theme.color.primary
                      : theme.color.typography.text
                  }
                >
                  {format(fromUnixTime(d), "MMM")}
                </Dates>
                <DayNames
                  margin="0 0 0 0"
                  weight="bold"
                  color={
                    approvedTimeslot &&
                    isSameDay(
                      fromUnixTime(
                        props.booking.suggestedTimeSlots.filter(
                          (e) => e.id === approvedTimeslot
                        )[0]?.date
                      ),
                      fromUnixTime(d)
                    )
                      ? theme.color.primary
                      : theme.color.typography.text
                  }
                >
                  {format(fromUnixTime(d), "do")}
                </DayNames>
                <Dates
                  margin="0 0 m 0"
                  size="s"
                  color={
                    approvedTimeslot &&
                    isSameDay(
                      fromUnixTime(
                        props.booking.suggestedTimeSlots.filter(
                          (e) => e.id === approvedTimeslot
                        )[0]?.date
                      ),
                      fromUnixTime(d)
                    )
                      ? theme.color.primary
                      : theme.color.typography.text
                  }
                >
                  {format(fromUnixTime(d), "E")}
                </Dates>
              </DayWrap>
            ))}
          </DaysWrap>
        )}

        <TimeSlotWrapper>
          {activeDate &&
            props.booking.suggestedTimeSlots
              .filter((s) =>
                isSameDay(fromUnixTime(s.date), fromUnixTime(activeDate))
              )
              .map((t) => (
                <ToggleButton
                  key={t.id}
                  title={format(fromUnixTime(t.date), "HH:mm")}
                  active={approvedTimeslot === t.id}
                  margin="0 0 s 0"
                  onClick={() => {
                    if (approvedTimeslot === t.id) {
                      // remove it
                      setApprovedTimeslot(null);
                    } else {
                      // add it
                      setApprovedTimeslot(t.id);
                    }
                  }}
                />
              ))}
        </TimeSlotWrapper>
        {props.booking.listing.isDesignMyNightListing ? (
          <Flex direction="row" align="center" justify="center">
            <div style={{ marginRight: theme.spacing.s, top: 2 }}>
              <BoltIcon width={9} colorPreset="reward" />
            </div>
            <Text size="s" colorPreset="reward" weight="semi">
              Powered by Access Collins
            </Text>
          </Flex>
        ) : null}
      </>
    );
  };

  if (progressBooking.isLoading) {
    return <Loading />;
  }

  if (props.showReschedule) {
    return (
      <>
        <FakeBackLink
          onClick={() =>
            props.setShowReschedule &&
            props.setShowReschedule(!props.showReschedule)
          }
        >
          <Chevron direction="left" /> Back to their suggested dates
        </FakeBackLink>
        <H2 margin="m 0">Propose new dates</H2>
        <Text margin={"0 0 l 0"} color={theme.color.typography.secondary}>
          Suggest alternative dates that better fit your schedule. You'll still
          need to approve the final date once they reapply.
        </Text>
        <TimeSlots
          m="0"
          location={props.booking.location}
          listing={props.booking.listing}
          selectedTimeSlots={suggestedTimeSlots}
          onToggle={(date) => {
            if (
              suggestedTimeSlots.filter((e) => e.getTime() === date.getTime())
                .length >= 1
            ) {
              setSuggestedTimeSlots(
                suggestedTimeSlots.filter((e) => e.getTime() !== date.getTime())
              );
            } else {
              setSuggestedTimeSlots((s) => [...s, date]);
            }
          }}
        />
        {props.booking.listing.isDesignMyNightListing ? (
          <Flex direction="row" align="center" justify="center">
            <div style={{ marginRight: theme.spacing.s, top: 2 }}>
              <BoltIcon width={9} colorPreset="reward" />
            </div>
            <Text size="s" colorPreset="reward" weight="semi">
              Powered by Access Collins
            </Text>
          </Flex>
        ) : null}
        <Divider margin="xl 0" />
        <TextArea
          rows={3}
          placeholder="Let them know which dates would work better"
          onChange={(e) => {
            setRescheduleReason(e.currentTarget.value);
            setRescheduleReasonError("");
          }}
          defaultValue={rescheduleReason}
          error={rescheduleReasonError}
        />
        <Button
          isDisabled={!rescheduleReason}
          margin="xxl 0 m 0"
          onClick={() => {
            if (!rescheduleReason) {
              setRescheduleReasonError(
                "Please let them know why you need to reschedule"
              );
              return;
            }

            const timeSlots = suggestedTimeSlots.map((sts) => getUnixTime(sts));

            progressBooking.mutate(
              {
                bookingId: props.booking.id,
                status: BookingStatus.BookingStatusRescheduled,
                timeSlots,
                rescheduleReason,
                voucherCode: voucherCode !== "" ? voucherCode : undefined,
              },
              {
                onSuccess: (res) => {
                  toast.success("Reschedule requested");
                  track("Booking rescheduled", {
                    id: res.progressBooking.id
                      ? res.progressBooking.id
                      : undefined,
                    brand: brand ? brand.name : undefined,
                  });
                  queryClient.resetQueries(
                    ["BrandNotificationCount", "getBooking", "brandBookings"],
                    {
                      exact: false,
                    }
                  );
                  props.onCancel();

                  // force a reload of the page if they're already on bookings
                  if (history.location.pathname === "/b/bookings") {
                    window.location.reload();
                  }

                  history.replace("/b/bookings");
                },
                onError: (error: any) => {
                  const graphQLErrors = error?.response?.errors;
                  if (graphQLErrors && graphQLErrors.length > 0) {
                    switch (graphQLErrors[0].message) {
                      case ProgressBookingError.ProgressBookingDesignMyNightError:
                        alert(
                          "There was an issue creating the booking on Access Collins. Please contact us via the in app chat."
                        );
                        break;
                      case ProgressBookingError.ProgressBookingToggleError:
                        alert(
                          "There was an issue creating voucher on Toggle. Please contact us via the in app chat."
                        );
                        break;
                      default:
                        alert(
                          "Something went wrong. Please contact us via the in app chat."
                        );
                        break;
                    }
                  }
                },
              }
            );
          }}
        >
          {progressBooking.isLoading ? "Loading..." : "Confirm"}
        </Button>
      </>
    );
  }

  if (props.booking.status === BookingStatus.BookingStatusCreatorRescheduled) {
    return (
      <>
        <View margin="0 0 xl">
          <H2 margin={"m 0 0 0"}>Reschedule requested</H2>
          <Underline selected={true} />
        </View>
        <View margin="s 0 0">
          <PreferredProfile
            creator={props.booking.creator}
            platform={props.booking.platform}
          />
        </View>
        <Text margin="0 0 m 0" colorPreset="secondary">
          Unfortunately, they had to cancel their booking at{" "}
          <BoldText>
            {props.booking.location ? props.booking.location.name : ""}
          </BoldText>
          , but they're still excited to visit! They've suggested a few
          alternatives:
        </Text>
        {props.booking.confirmedTimeslot ? null : RenderTimePicker()}
        {props.booking.rescheduleReason ? (
          <Quote margin="m 0 xs 0">
            <Text margin="s xs">
              <Text margin="0" weight="bold">
                “{props.booking.rescheduleReason}”
              </Text>
              {" — "}
              {props.booking.creator.accounts[0].firstName}
            </Text>
          </Quote>
        ) : null}
        <Text margin="m 0 0">
          None of these dates work?{" "}
          <Text
            weight="semi"
            style={{ cursor: "pointer" }}
            margin="0"
            size="s"
            color={theme.color.primary}
            isInline
            onClick={() => {
              props.setShowReschedule &&
                props.setShowReschedule(!props.showReschedule);
            }}
          >
            Suggest alternatives
          </Text>
        </Text>
        <Divider margin="xl 0" />
        <H3 margin="0 0 m">Extra information</H3>
        <AutoSuggestBookingNotes
          rows={4}
          placeholder={`Let ${props.booking.creator.accounts[0].firstName} know who to ask for on arrival or any other specifics`}
          setBookingNoteText={setInfo}
          bookingNoteText={info}
          booking={props.booking}
          approvedTimeslot={approvedTimeslot}
        />

        <Button
          disabled={!approvedTimeslot}
          margin="xxl 0 m 0"
          onClick={() => {
            if (!approvedTimeslot) {
              alert("Please selected a time for them to visit");
              return;
            }

            progressBooking.mutate(
              {
                bookingId: props.booking.id,
                status: BookingStatus.BookingStatusApproved,
                timeSlotId: approvedTimeslot,
                additionalInfo: info,
                voucherCode: voucherCode !== "" ? voucherCode : undefined,
              },
              {
                onSuccess: (res) => {
                  toast.success("Booking approved");
                  track("Booking approved", {
                    id: res.progressBooking.id
                      ? res.progressBooking.id
                      : undefined,
                    brand: brand ? brand.name : undefined,
                  });
                  props.onCancel();
                  queryClient.resetQueries(
                    ["BrandNotificationCount", "getBooking", "brandBookings"],
                    {
                      exact: false,
                    }
                  );
                  history.replace(
                    approvedTimeslotUnix
                      ? `/b/bookings/upcoming?ts=${approvedTimeslotUnix}`
                      : "/b/bookings/upcoming"
                  );
                },
                onError: (error: any) => {
                  const graphQLErrors = error?.response?.errors;
                  if (graphQLErrors && graphQLErrors.length > 0) {
                    switch (graphQLErrors[0].message) {
                      case ProgressBookingError.ProgressBookingDesignMyNightError:
                        alert(
                          "There was an issue creating the booking on Access Collins. Please contact us via the in app chat."
                        );
                        break;
                      case ProgressBookingError.ProgressBookingToggleError:
                        alert(
                          "There was an issue creating voucher on Toggle. Please contact us via the in app chat."
                        );
                        break;
                      default:
                        alert(
                          "Something went wrong. Please contact us via the in app chat."
                        );
                        break;
                    }
                  }
                },
              }
            );
          }}
        >
          {progressBooking.isLoading ? "Loading..." : "Confirm"}
        </Button>
      </>
    );
  }

  return (
    <>
      <View margin="0 0 xl">
        <H2 margin={"m 0 0 0"}>Schedule a visit</H2>
        <Underline selected={true} />
      </View>
      <View margin="s 0 0">
        <PreferredProfile
          creator={props.booking.creator}
          platform={props.booking.platform}
        />
        <H3 margin="xl 0 xs">Availability</H3>
      </View>
      {props.booking.type === ListingType.RedeemAnytime ? (
        <>
          <Text margin={"0 0 l 0"} color={theme.color.typography.text}>
            They will have 30 days to redeem the offer via the Joli app.
            Redeemed offers must be used within 24 hours or they will expire.
          </Text>
        </>
      ) : (
        <>
          <Text margin={"0 0 s 0"} color={theme.color.typography.secondary}>
            Select a time and date from their suggested availability, or propose
            new slots that better fit your schedule
          </Text>
          {props.booking.confirmedTimeslot ? null : RenderTimePicker()}
          <Text margin="m 0 0">
            None of these dates work?{" "}
            <Text
              weight="semi"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              margin="0"
              isInline
              onClick={() => {
                props.setShowReschedule &&
                  props.setShowReschedule(!props.showReschedule);
              }}
            >
              Suggest alternatives
            </Text>
          </Text>
        </>
      )}

      <H3 margin="xl 0 s">Notes</H3>
      <View margin="0 0 m">
        <AutoSuggestBookingNotes
          rows={4}
          placeholder={`Let ${props.booking.creator.accounts[0].firstName} know who to ask for on arrival or any other specifics`}
          setBookingNoteText={setInfo}
          bookingNoteText={info}
          booking={props.booking}
          approvedTimeslot={approvedTimeslot}
        />
      </View>
      {props.booking.isToggleBooking || showVoucherCode ? null : (
        <InlineAddBtn
          label="Add voucher code"
          margin="xs 0 0"
          onClick={() => {
            setShowVoucherCode(true);
          }}
        />
      )}
      {showVoucherCode ? (
        <>
          <Flex margin="s 0 s" justify="space-between">
            <div>
              <H3 margin="s 0 0">Voucher code</H3>
              <Text margin="xs 0 0 0" size="xs" colorPreset="secondary">
                Add a code for them to redeem during their visit
              </Text>
            </div>
            <View
              onClick={() => {
                setShowVoucherCode(false);
                setVoucherCode("");
              }}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <CancelIcon />
            </View>
          </Flex>

          <Input
            margin="0 0 m"
            value={voucherCode}
            placeholder="Enter a voucher code"
            onChange={(e) => {
              setVoucherCode(e.currentTarget.value);
            }}
          />
        </>
      ) : null}
      <RenderIntegrationMessage
        isToggleBooking={props.booking.isToggleBooking}
        listing={props.booking.listing}
        toggleProductValue={props.booking.toggleProductValue ?? undefined}
      />
      <Button
        disabled={
          !approvedTimeslot && props.booking.type !== ListingType.RedeemAnytime
        }
        margin="xxl 0 m 0"
        onClick={() => {
          if (
            !approvedTimeslot &&
            props.booking.type !== ListingType.RedeemAnytime
          ) {
            alert("Please selected a time for them to visit");
            return;
          }

          progressBooking.mutate(
            {
              bookingId: props.booking.id,
              status: BookingStatus.BookingStatusApproved,
              timeSlotId: approvedTimeslot,
              additionalInfo: info,
              voucherCode: voucherCode !== "" ? voucherCode : undefined,
            },
            {
              onSuccess: (res) => {
                toast.success("Booking approved");
                track("Booking approved", {
                  id: res.progressBooking.id
                    ? res.progressBooking.id
                    : undefined,
                  brand: brand ? brand.name : undefined,
                });
                props.onCancel();
                queryClient.resetQueries(
                  ["BrandNotificationCount", "getBooking", "brandBookings"],
                  {
                    exact: false,
                  }
                );
                history.replace(
                  approvedTimeslotUnix
                    ? `/b/bookings/upcoming?ts=${approvedTimeslotUnix}`
                    : "/b/bookings/upcoming"
                );
              },
              onError: (error: any) => {
                const graphQLErrors = error?.response?.errors;
                if (graphQLErrors && graphQLErrors.length > 0) {
                  switch (graphQLErrors[0].message) {
                    case ProgressBookingError.ProgressBookingDesignMyNightError:
                      alert(
                        "There was an issue creating the booking on Access Collins. Please contact us via the in app chat."
                      );
                      break;
                    case ProgressBookingError.ProgressBookingToggleError:
                      alert(
                        "There was an issue creating voucher on Toggle. Please contact us via the in app chat."
                      );
                      break;
                    default:
                      alert(
                        "Something went wrong. Please contact us via the in app chat."
                      );
                      break;
                  }
                }
              },
            }
          );
        }}
      >
        {progressBooking.isLoading ? "Loading..." : "Confirm"}
      </Button>
    </>
  );
};

function RenderIntegrationMessage({
  listing,
  isToggleBooking,
  toggleProductValue,
}: {
  listing: GetBookingQuery["booking"]["listing"];
  isToggleBooking: boolean;
  toggleProductValue: number | undefined;
}) {
  let { isDesignMyNightListing } = listing;

  let alertMessage = "";
  if (isDesignMyNightListing && !isToggleBooking && !toggleProductValue) {
    alertMessage =
      "Approving this booking will automatically create a booking on Access Collins";
  } else if (isToggleBooking && toggleProductValue) {
    alertMessage = `Approving this booking will automatically generate a £${
      toggleProductValue / 100
    } Toggle voucher`;
    if (isDesignMyNightListing) {
      alertMessage += " and a booking on Access Collins";
    }
  }

  if (!alertMessage) {
    return null;
  }

  return (
    <View margin="m 0 0">
      <Callout type="info" text={alertMessage} />
    </View>
  );
}

const TimeSlotWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing.s};
`;

const DaysWrap = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: ${(p) => p.theme.spacing.s};
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const DayWrap = styled.div<{ active: boolean }>`
  cursor: pointer;
  background-color: ${(p) =>
    p.active ? p.theme.color.card.callout : "transparent"};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s} 0 0;
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const DayNames = styled(Text)`
  text-transform: uppercase;
  text-align: center;
  line-height: 130%;
`;
const Dates = styled(Text)`
  /* text-transform: uppercase; */
  text-align: center;
  line-height: 130%;
  opacity: 0.75;
`;

const Quote = styled(View)`
  background-color: ${(p) => p.theme.color.button.secondaryText}15;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: 100%;
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};

  color: ${(p) => p.theme.color.primary};
`;

const BoldText = styled.span`
  font-weight: ${(p) => p.theme.typography.weight.bold};
`;
