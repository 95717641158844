import { add, formatDistanceToNowStrict, fromUnixTime, isPast } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";
import { H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Text } from "../../../components/Text";
import {
  ExpiredIGConnectionText,
  ExpiredTikTokConnectionText,
  InstagramEngagementRateHelpText28Days,
  InstagramEngagementsHelpText,
  InstagramImpressionsHelpText28Days,
  InstagramReachHelpText28Days,
  MEDIUM_DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  TikTokEngagementRateHelpText28Days,
  TikTokEngagementsHelpText28Days,
  TikTokViewsHelpText28Days,
  XL_DESKTOP_BREAKPOINT,
} from "../../../config";
import {
  BookingPostType,
  BookingStatus,
  DemographicSource,
  Gender,
  GetBookingQuery,
  Platform,
  Post,
  PostType,
  PostsDocument,
  PostsQuery,
  useAddToBrandBlackListMutation,
  useGetBookingQuery,
  useProgressBookingMutation,
  useReportBookingIssueMutation,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import { css, styled } from "../../../styles";
import { lightTheme } from "../../../styles/theme";
import {
  renderBookingPostTypeTag,
  renderDemographicSource,
  renderDemographicSourceTooltip,
  renderDemographicTimeframe,
  renderPostTypeTag,
} from "../../../utils/enums";
import { extractSocialMediaHandles } from "../../../utils/text";
import { Button, ButtonWrap } from "../../CTA";
import { Callout } from "../../Callout";
import { Card } from "../../Card";
import { CardLink, ExternalCardLink } from "../../CardLink";
import { CollapsableSection } from "../../CollapsableSection";
import CreatorPostsGrid from "../../CreatorPostsGrid";
import { CardDivider } from "../../Divider";
import { Flex } from "../../Flex";
import { Modal } from "../../Modal";
import { PercentageChange } from "../../PercentageChange";
import { PreferredProfile } from "../../PreferredProfile";
import { SegmentedControl } from "../../SegmentedControl";
import { Tabs } from "../../Tabs";
import { Tooltip } from "../../Tooltip";
import { View } from "../../View";
import { CameraIcon } from "../../icons/CameraIcon";
import { CommentsIcon } from "../../icons/CommentsIcon";
import { EngagementIcon } from "../../icons/EngagementIcon";
import { EngagementRateIcon } from "../../icons/EngagementRateIcon";
import { FollowersIcon } from "../../icons/FollowersIcon";
import { ImpressionsIcon } from "../../icons/ImpressionsIcon";
import { InfoIcon } from "../../icons/InfoIcon";
import { LikesIcon } from "../../icons/LikesIcon";
import { PressLinkIcon } from "../../icons/PressLinkIcon";
import { ReachIcon } from "../../icons/ReachIcon";
import { SavesIcon } from "../../icons/SavesIcon";
import { SharesIcon } from "../../icons/SharesIcon";
import { ViewIcon } from "../../icons/ViewIcon";
import { ContactDetails } from "../ContactDetails";
import { BookingTimeline } from "./BookingTimeline";
import { CompleteBookingOverlay } from "./CompletBookingOverlay";
import { RejectOverlay } from "./RejectOverlay";
import { ReportIssueOverlay } from "./ReportIssueOverlay";
import { ScheduleOverlay } from "./ScheduleOverlay";

const Wrap = styled.div`
  color: ${(p) => p.theme.color.typography.text};
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;

const DesktopOnly = styled.div`
  display: block;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
    margin-bottom: ${(p) => p.theme.spacing.xl};
    margin-top: ${(p) => p.theme.spacing.xs};
  }
`;

const Header = styled.div`
  margin: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.xl}
    ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
`;

export const BookingDetails = React.memo(
  ({ bookingId }: { bookingId: string | null }) => {
    const { track } = useAnalytics();
    const history = useHistory();
    let { id, token } = useParams<{ id: string; token: string }>();
    const client = useGqlClient();
    const progressBooking = useProgressBookingMutation(client);
    const addToBrandBlackList = useAddToBrandBlackListMutation(client);
    const reportIssue = useReportBookingIssueMutation(client);
    const [rejectOverlay, setRejectOverlay] = useState(false);
    const [scheduleOverlay, setScheduleOverlay] = useState(false);
    const [reportIssueOverlay, setReportIssueOverlay] = useState(false);
    const [completeBookingOverlay, setCompleteBookingOverlay] = useState(false);
    const brand = useSelector(authSelectors.activeBrand);
    const path = history.location.pathname;
    const isUpcoming = path.includes("/b/bookings/upcoming");
    const queryClient = useQueryClient();

    const { isLoading, data, isError } = useGetBookingQuery(
      client,
      { id: bookingId ? bookingId : id ? id : "" },
      {
        retry: false,
        enabled: !!bookingId,
      }
    );

    useEffect(() => {
      if (data) {
        track("View booking", {
          id: data.booking.id,
          brand: data.booking.listing.brand.name,
          listingName: data.booking.listing.name,
          location: data.booking.location,
          creator: data.booking.creator.id,
          status: data.booking.status,
        });
      }
    }, [data, track]);

    if (isLoading) {
      return (
        <Wrap>
          <Loading defer />
        </Wrap>
      );
    }

    if (isError || !data) {
      return (
        <Wrap>
          <H3>Oops</H3>
          <Text margin={"0 0 xl 0"}>
            Couldn't load your booking, please try again later.
          </Text>
        </Wrap>
      );
    }

    if (!data.booking.creator.preferredProfile) {
      return null;
    }

    const profileContent = [];
    let profileLabels: string[] = [];
    let bookingLabels: string[] = [];
    let bookingContent = [];

    if (
      data.booking.status === BookingStatus.BookingStatusApproved ||
      data.booking.status === BookingStatus.BookingStatusCompleted
    ) {
      bookingLabels.push("Content");
      bookingLabels.push("Performance");
      bookingContent.push(
        <BookingContent key="content" booking={data.booking} />
      );
      bookingContent.push(<BookingStats key="stats" booking={data.booking} />);
    }

    if (data.booking.id) {
      profileLabels.push("Featured Content");
      profileContent.push(
        <FeaturedContent key="featured" booking={data.booking} />
      );
    }

    if (data.booking.platform === Platform.Instagram) {
      profileLabels.push("Instagram Metrics");
      profileContent.push(
        <InstagramStats key="insta" creator={data.booking.creator} />
      );
    } else {
      profileLabels.push("TikTok Metrics");
      profileContent.push(
        <TikTokStats key="tiktok" creator={data.booking.creator} />
      );
    }

    return (
      <Wrap>
        <Modal
          isOpen={scheduleOverlay}
          // setIsOpen={setScheduleOverlay}
          onClose={() => setScheduleOverlay(false)}
          maxWidth={960}
          noPadding
        >
          <ScheduleOverlay
            booking={data.booking}
            onCancel={() => setScheduleOverlay(false)}
          />
        </Modal>
        <Modal
          isOpen={completeBookingOverlay}
          setIsOpen={setCompleteBookingOverlay}
        >
          <CompleteBookingOverlay
            booking={data.booking}
            onCancel={() => setCompleteBookingOverlay(false)}
          />
        </Modal>
        <Modal
          isOpen={rejectOverlay}
          setIsOpen={setRejectOverlay}
          maxWidth={650}
        >
          <RejectOverlay
            brandName={brand ? brand.name : "brand"}
            creatorName={data.booking.creator.preferredProfile.username}
            isLoading={progressBooking.isLoading}
            onCancel={() => setRejectOverlay(false)}
            onConfirm={(
              rejectionMessage: string,
              blockCreator: boolean,
              internalRejectionReason: string
            ) => {
              progressBooking.mutate(
                {
                  bookingId: data.booking.id,
                  status: BookingStatus.BookingStatusRejected,
                  token: token,
                  rejectionMessage: rejectionMessage,
                  internalRejectionReason: internalRejectionReason,
                },
                {
                  onSuccess: (res) => {
                    toast.success("Booking declined");
                    track("Booking rejected", {
                      id: res.progressBooking.id
                        ? res.progressBooking.id
                        : undefined,
                      brand: brand ? brand.name : undefined,
                    });
                    queryClient.resetQueries(
                      ["BrandNotificationCount", "getBooking"],
                      {
                        exact: false,
                      }
                    );
                    if (blockCreator) {
                      addToBrandBlackList.mutate(
                        {
                          creatorId: data.booking.creator.id,
                        },
                        {
                          onError: (e) => {
                            console.error(e);
                          },
                          onSuccess: () => {
                            toast.success("Creator blocked");
                            track("Creator blocked", {
                              brand: brand ? brand.name : undefined,
                            });
                          },
                        }
                      );
                    }
                    if (token) {
                      history.push("/bookingApproval/done");
                    } else {
                      // TODO Show toast instead
                      history.push("/b");
                    }
                  },
                  onError: (e) => {
                    console.error(e);
                    alert("Oops, something went wrong");
                  },
                }
              );
            }}
          />
        </Modal>
        <Modal isOpen={reportIssueOverlay} setIsOpen={setReportIssueOverlay}>
          <ReportIssueOverlay
            brandName={brand ? brand.name : "brand"}
            creatorName={data.booking.creator.preferredProfile.username}
            isLoading={progressBooking.isLoading}
            onCancel={() => setReportIssueOverlay(!reportIssueOverlay)}
            onConfirm={(issue: string, blockCreator: boolean) => {
              reportIssue.mutate(
                {
                  issue,
                  bookingId: data.booking.id,
                },
                {
                  onSuccess: () => {
                    toast.success("Issue reported");
                    track("Issue reported", {
                      brand: brand ? brand.name : undefined,
                    });
                    if (blockCreator) {
                      addToBrandBlackList.mutate(
                        {
                          creatorId: data.booking.creator.id,
                        },
                        {
                          onError: (e) => {
                            console.error(e);
                          },
                          onSuccess: () => {
                            toast.success("Creator blocked");
                            track("Creator blocked", {
                              brand: brand ? brand.name : undefined,
                            });
                          },
                        }
                      );
                    }
                    setReportIssueOverlay(false);
                    queryClient.resetQueries(["getBooking"], {
                      exact: false,
                    });
                  },
                  onError: (e) => {
                    console.error(e);
                    alert("Oops, something went wrong");
                  },
                }
              );
            }}
          />
        </Modal>
        <Header>
          <Flex align="flex-start" justify="space-between" direction="row">
            <div style={{ flex: 1 }}>
              <PreferredProfile
                creator={data.booking.creator}
                avatarSize={64}
                platform={data.booking.platform}
              />
            </div>
            {data.booking.status === BookingStatus.BookingStatusPending ||
            data.booking.status ===
              BookingStatus.BookingStatusCreatorRescheduled ||
            data.booking.status === BookingStatus.BookingStatusRescheduled ? (
              <DesktopOnly>
                <ButtonWrap>
                  <Button
                    buttonType="secondary"
                    margin="0"
                    size="s"
                    onClick={() => setRejectOverlay(true)}
                  >
                    Not right for us
                  </Button>

                  <Button
                    size="s"
                    margin="0"
                    onClick={() => setScheduleOverlay(true)}
                  >
                    {/* {data.booking.status ===
              BookingStatus.BookingStatusCreatorRescheduled
                ? "Reschedule"
                : "Schedule a visit"} */}
                    Schedule a visit
                  </Button>
                </ButtonWrap>
              </DesktopOnly>
            ) : data.booking.status === BookingStatus.BookingStatusApproved &&
              data.booking.inProgress &&
              !isUpcoming ? (
              <DesktopOnly>
                <ButtonWrap>
                  <Button
                    size="s"
                    buttonType="secondary"
                    margin="0"
                    onClick={() => setReportIssueOverlay(!reportIssueOverlay)}
                  >
                    Report issue
                  </Button>

                  <Button
                    size="s"
                    margin="0"
                    onClick={() =>
                      setCompleteBookingOverlay(!completeBookingOverlay)
                    }
                  >
                    Complete booking
                  </Button>
                </ButtonWrap>
              </DesktopOnly>
            ) : null}
          </Flex>
          <ContactDetails booking={data.booking} />
        </Header>
        {data.booking.status === BookingStatus.BookingStatusPending ||
        data.booking.status === BookingStatus.BookingStatusCreatorRescheduled ||
        data.booking.status === BookingStatus.BookingStatusRescheduled ? (
          <MobileOnly>
            <ButtonWrap>
              <Button
                buttonType="secondary"
                margin="0"
                size="s"
                onClick={() => setRejectOverlay(true)}
              >
                Not right for us
              </Button>

              <Button
                size="s"
                margin="0"
                onClick={() => setScheduleOverlay(true)}
              >
                {/* {data.booking.status ===
              BookingStatus.BookingStatusCreatorRescheduled
                ? "Reschedule"
                : "Schedule a visit"} */}
                Schedule a visit
              </Button>
            </ButtonWrap>
          </MobileOnly>
        ) : data.booking.status === BookingStatus.BookingStatusApproved &&
          data.booking.inProgress &&
          !isUpcoming ? (
          <MobileOnly>
            <ButtonWrap>
              <Button
                size="s"
                buttonType="secondary"
                margin="0"
                onClick={() => setReportIssueOverlay(!reportIssueOverlay)}
              >
                Report issue
              </Button>

              <Button
                size="s"
                margin="0"
                onClick={() =>
                  setCompleteBookingOverlay(!completeBookingOverlay)
                }
              >
                Complete booking
              </Button>
            </ButtonWrap>
          </MobileOnly>
        ) : null}
        <CardDivider />
        <CardBody>
          <View margin="xl 0 m">
            <BookingTimeline
              key={data.booking.id}
              booking={data.booking}
              supportOldTimeline={true}
            />
          </View>
          {bookingContent.length > 0 ? (
            <View margin="0 0 xl">
              <Tabs labels={bookingLabels} content={bookingContent} />
            </View>
          ) : null}

          {profileContent.length > 0 ? (
            <View margin="0">
              <Tabs labels={profileLabels} content={profileContent} />
            </View>
          ) : null}
        </CardBody>
      </Wrap>
    );
  },
  (prevProps, nextProps) => prevProps.bookingId === nextProps.bookingId
);

const CardBody = styled(View)`
  padding: 0 ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.l};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
    height: auto;
    overflow-y: visible;
  }
`;

const Image = styled.img<{ maxHeight?: number; square: boolean }>`
  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 9 / 14;
        `}
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  margin: 0 auto;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : "none")};
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: ${(p) => p.theme.spacing.s};
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.m};
  }

  @media (min-width: ${SMALL_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.m};
    grid-row-gap: 0;
  }

  @media (min-width: ${MEDIUM_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.m};
    grid-row-gap: 0;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.m};
    grid-row-gap: 0;
  }
`;

const PendingContentPortrait = styled.div<{ square: boolean }>`
  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 9 / 14;
        `}
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border: 2px dashed ${(p) => p.theme.color.card.divider} !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const TagWrap = styled.div<{ backgroundGradient?: true }>`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  padding: ${(p) => p.theme.spacing.s};
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};

  ${(p) =>
    p.backgroundGradient
      ? css`
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 25%
          );
        `
      : css``}
`;

export const DueDateWrap = styled.div`
  position: absolute;
  right: 2px;
  bottom: 0px;
  z-index: 1;
  padding: ${(p) => p.theme.spacing.s};
  box-sizing: border-box;
`;

function PendingPost({
  type,
  dueDate,
}: {
  type: PostType;
  dueDate: Date | null;
}) {
  return (
    <PendingContentPortrait square={type === PostType.Feed}>
      <TagWrap>
        <div>{renderPostTypeTag(type)}</div>
      </TagWrap>
      <Flex
        style={{ opacity: 0.4 }}
        align="center"
        justify="center"
        direction="column"
      >
        <CameraIcon colorPreset="secondary" />
        <Text weight="semi" size="xs" margin="s 0 0" colorPreset="secondary">
          Pending
        </Text>
        {dueDate ? (
          <DueDateWrap>
            <Text
              weight="semi"
              size="xxs"
              margin="0 0 0"
              colorPreset="secondary"
              isCompact
            >
              {`Due ${formatDistanceToNowStrict(dueDate, {
                addSuffix: true,
              })}`}
            </Text>
          </DueDateWrap>
        ) : null}
      </Flex>
    </PendingContentPortrait>
  );
}

const BookingContent = ({
  booking,
}: {
  booking: GetBookingQuery["booking"];
}) => {
  let requiredContent = [
    { type: PostType.Reels, count: booking.remainingContent.instagramReels },
    { type: PostType.Story, count: booking.remainingContent.instagramStories },
    { type: PostType.Feed, count: booking.remainingContent.instagramPosts },
    { type: PostType.Tiktok, count: booking.remainingContent.tikToks },
  ];

  requiredContent.sort((a, b) => b.count - a.count);

  let dueDate = booking.confirmedTimeslot
    ? add(fromUnixTime(booking.confirmedTimeslot.date), {
        days: 14,
      })
    : booking.approvedAt
    ? add(fromUnixTime(booking.approvedAt), {
        days: 30,
      })
    : null;

  const generatePlaceholders = (dueDate: Date | null) =>
    requiredContent.flatMap(({ type, count }) =>
      Array.from({ length: count }, (_, index) => (
        <PendingPost key={`${type}-${index}`} type={type} dueDate={dueDate} />
      ))
    );

  return (
    <View margin="xl 0 0">
      <PostsGrid>
        {booking.bookingPosts.map((p) => {
          const imageURL = p.thumbnailUrl ? p.thumbnailUrl : p.mediaUrl;
          const path = new URL(imageURL).pathname;
          const newURL = `https://ik.imagekit.io/fxuomw4xy/${path}?tr=w-300`;
          return (
            <CardLink href={p.permalink} to={"/b/reports/" + p.id} key={p.id}>
              <div style={{ position: "relative" }}>
                <PostWrap>
                  <Image
                    src={newURL}
                    square={p.postType === BookingPostType.BookingPostTypeFeed}
                  />
                  <TagWrap>
                    <div>{renderBookingPostTypeTag(p.postType)}</div>
                  </TagWrap>
                  <PostOverlay />
                  <HoverOverlay className="show-on-hover">
                    <BrandMentionWrap>
                      <Flex
                        margin="0 0 0 0"
                        align="center"
                        justify="center"
                        style={{ marginTop: -2 }}
                      >
                        <PressLinkIcon width={16} />
                      </Flex>
                      <SocialMediaHandle>View in Reports</SocialMediaHandle>
                    </BrandMentionWrap>
                  </HoverOverlay>
                </PostWrap>
              </div>
            </CardLink>
          );
        })}
        {generatePlaceholders(dueDate)}
      </PostsGrid>
    </View>
  );
};

const FeaturedContent = ({
  booking,
}: {
  booking: GetBookingQuery["booking"];
}) => {
  const client = useGqlClient();
  const [posts, setPosts] = useState<Post[]>([]);

  const useInfinitePosts = (bookingId: string) => {
    const getPosts = useCallback(
      async (cursor: number) => {
        return client.request<PostsQuery>(PostsDocument, {
          input: {
            types: [PostType.Reels, PostType.Feed, PostType.Tiktok],
            cursor,
            bookingId,
            count: 10,
          },
        });
      },
      [bookingId]
    );

    return useInfiniteQuery(
      ["Posts", booking.id],
      ({ pageParam }) => getPosts(pageParam),
      {
        getNextPageParam: (lastPage) => {
          const { posts } = lastPage.posts2;
          const { cursor } = lastPage.posts2;

          if (posts.length === 0 || cursor === "") {
            return undefined;
          }

          return cursor;
        },
        refetchOnWindowFocus: false,
      }
    );
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isRefetching,
  } = useInfinitePosts(booking.id);

  useEffect(() => {
    if (!data) {
      return;
    }

    const posts = data.pages.flatMap((page) => page.posts2.posts);

    setPosts(posts as Post[]);
  }, [data]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, fetchNextPage]
  );

  if ((isRefetching || isFetching) && posts.length === 0) {
    return <Loading defer />;
  }

  const ttExpired =
    booking.creator.ttConnectionExpired && booking.platform === Platform.Tiktok;

  const igExpired =
    booking.creator.fbTokenExpiry &&
    isPast(fromUnixTime(booking.creator.fbTokenExpiry)) &&
    booking.platform === Platform.Instagram;

  return (
    <View margin="l 0 0">
      <View margin="0 0 l">
        {ttExpired ? (
          <View margin="0 0 s">
            <Callout type="info" text={ExpiredTikTokConnectionText} />
          </View>
        ) : null}
        {igExpired ? (
          <View margin="0 0 s">
            <Callout type="info" text={ExpiredIGConnectionText} />
          </View>
        ) : null}
      </View>
      {posts.length > 0 ? (
        <CreatorPostsGrid>
          {posts
            .filter((p) => p.impressions !== 0)
            .map((p, index) => {
              const socialMediaHandles = extractSocialMediaHandles(p.caption);
              const imageURL = p.thumbnailUrl ? p.thumbnailUrl : p.mediaUrl;
              const path = new URL(imageURL).pathname;
              const newURL = `https://ik.imagekit.io/fxuomw4xy/${path}?tr=w-600`;

              return (
                <ExternalCardLink
                  href={p.permalink}
                  target="_blank"
                  key={p.mediaId}
                >
                  <PostWrap>
                    <Image
                      ref={index === posts.length - 1 ? lastElementRef : null}
                      src={newURL}
                      square={p.type === PostType.Feed}
                    />
                    <TagWrap backgroundGradient className="hide-on-hover">
                      <div>{renderPostTypeTag(p.type)}</div>
                    </TagWrap>
                    <HoverOverlay className="show-on-hover">
                      {p.type !== PostType.Tiktok ? (
                        <BrandMentionWrap>
                          {socialMediaHandles.length === 0 ? (
                            <SocialMediaHandle>No mentions</SocialMediaHandle>
                          ) : (
                            socialMediaHandles.map((handle) => (
                              <SocialMediaHandle key={handle}>
                                {handle.length > 20
                                  ? `${handle.slice(0, 20)}...`
                                  : handle}
                              </SocialMediaHandle>
                            ))
                          )}
                        </BrandMentionWrap>
                      ) : null}
                    </HoverOverlay>
                    <PostOverlay>
                      <Flex
                        align="center"
                        direction="row"
                        justify="center"
                        margin="0 m xs 0"
                      >
                        <Flex
                          align="center"
                          justify="center"
                          style={{ marginTop: -2 }}
                        >
                          <ViewIcon />
                        </Flex>
                        <Text
                          size="xs"
                          margin="0 0 0 xs"
                          color="#fff"
                          weight="semi"
                        >
                          {p.impressions.toLocaleString()}
                        </Text>
                      </Flex>
                    </PostOverlay>
                    <LinkOverlay className="show-on-hover">
                      <Flex
                        align="center"
                        direction="row"
                        justify="center"
                        margin="xs 0 0 s"
                        className="show-on-hover"
                      >
                        <Flex
                          margin="0 xs 0 0"
                          align="center"
                          justify="center"
                          style={{ marginTop: -2 }}
                        >
                          <PressLinkIcon width={20} />
                        </Flex>
                        <SocialMediaHandle>
                          View on{" "}
                          {p.type === PostType.Tiktok ? "TikTok" : "Instagram"}
                        </SocialMediaHandle>
                      </Flex>
                    </LinkOverlay>
                  </PostWrap>
                </ExternalCardLink>
              );
            })}
        </CreatorPostsGrid>
      ) : (
        <Callout
          type="info"
          text="No featured content available from the last 90 days"
        />
      )}

      {isFetching || (isRefetching && <Loading defer />)}
    </View>
  );
};

export const SocialMediaHandle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
`;

export const BrandMentionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  align-items: center;
  color: white;
  font-weight: 500;
  height: 100%;
`;

export const LinkOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 25%
  );
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: white;
  font-weight: 500;
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

export const PostWrap = styled.div`
  transition: opacity 0.2s ease-out;
  position: relative;
  min-height: 150px;

  .hide-on-hover {
    opacity: 1;
  }

  .show-on-hover {
    opacity: 0;
  }

  :hover {
    .hide-on-hover {
      opacity: 0;
    }

    .show-on-hover {
      opacity: 1;
    }
  }
`;

const SocialCard = styled(Card)`
  overflow: unset;
`;

export const PostOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 8px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  font-weight: 500;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 8px;
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.m};
  background: rgba(0, 0, 0, 0.8) 100%;
  z-index: 0;
  color: white;
  font-weight: 500;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
`;

const MetricValue = styled(Text)<{ size?: "l" }>`
  font-size: ${(p) =>
    p.size === "l"
      ? p.theme.typography.size.xxxl
      : p.theme.typography.size.xxl};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  margin: 0px;
  color: ${(p) => p.theme.color.typography.secondaryHeading};
  line-height: 1.1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.xxl};
  }
`;

function TikTokStats({
  creator,
}: {
  creator: GetBookingQuery["booking"]["creator"];
}) {
  if (!creator.tikTok) {
    return null;
  }

  return (
    <View margin="xl 0 s">
      <View margin="0 0 l">
        {creator.ttConnectionExpired ? (
          <Callout type="info" text={ExpiredTikTokConnectionText} />
        ) : null}
      </View>
      <CollapsableSection
        defaultOpen={true}
        title="Social Metrics"
        headerSize="l"
        tooltip={
          <Tooltip>
            <Text size="s" margin="0">
              *figures calculated over the last 28 days
            </Text>
          </Tooltip>
        }
      >
        <SocialMetricsTwoGrid>
          <Card padding="l" margin="0 0 l">
            <Flex direction="row" align="center">
              <FollowersIcon />
              <View margin="0 0 0 m">
                <MetricValue size="l">
                  {creator.tikTok.followersCount
                    ? creator.tikTok.followersCount.toLocaleString()
                    : 0}
                </MetricValue>
                <Text size="s" isCompact margin="0" weight="semi">
                  Followers
                </Text>
              </View>
            </Flex>
          </Card>
          <SocialCard padding="l" margin="0 0 l">
            <Flex direction="row" justify="space-between" align="center">
              <Flex direction="row" align="center">
                <ImpressionsIcon />
                <View margin="0 0 0 m">
                  <MetricValue size="l">
                    {creator.tikTok.impressions28Day
                      ? creator.tikTok.impressions28Day.toLocaleString()
                      : 0}
                  </MetricValue>
                  <Flex align="center" justify="flex-start">
                    <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                      Views{" "}
                    </Text>
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {TikTokViewsHelpText28Days}
                        </Text>
                      </Tooltip>
                    </div>
                  </Flex>
                </View>
              </Flex>
              {creator.tikTok.impressions28Day &&
              creator.tikTok.prevImpressions28Day &&
              creator.tikTok.impressions28Day >
                creator.tikTok.prevImpressions28Day ? (
                <PercentageChange
                  size="l"
                  percentage={
                    ((creator.tikTok.impressions28Day -
                      creator.tikTok.prevImpressions28Day) /
                      creator.tikTok.prevImpressions28Day) *
                    100
                  }
                />
              ) : null}
            </Flex>
          </SocialCard>
        </SocialMetricsTwoGrid>
        <SocialMetricsTwoGrid>
          <SocialCard padding="l" margin="0 0 l">
            <Flex direction="row" align="center">
              <EngagementIcon />
              <View margin="0 0 0 m">
                <MetricValue size="l">
                  {creator.tikTok.engagement28Day
                    ? creator.tikTok.engagement28Day.toLocaleString()
                    : 0}
                </MetricValue>
                <Flex align="center" justify="flex-start">
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Engagements{" "}
                  </Text>
                  <div style={{ marginTop: 2 }}>
                    <Tooltip>
                      <Text size="s" margin="0">
                        {TikTokEngagementsHelpText28Days}
                      </Text>
                    </Tooltip>
                  </div>
                </Flex>
              </View>
            </Flex>
          </SocialCard>
          <SocialCard padding="l" margin="0 0 l">
            <Flex direction="row" align="center">
              <EngagementRateIcon />
              <View margin="0 0 0 m">
                <MetricValue size="l">
                  {creator.tikTok.engagement28Day &&
                  creator.tikTok.reach28Day &&
                  creator.tikTok.reach28Day > 0
                    ? (
                        (creator.tikTok.engagement28Day /
                          creator.tikTok.reach28Day) *
                        100
                      ).toFixed(1) + "%"
                    : "0%"}
                </MetricValue>
                <Flex align="center" justify="flex-start">
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Engagement rate{" "}
                  </Text>
                  <div style={{ marginTop: 2 }}>
                    <Tooltip>
                      <Text size="s" margin="0">
                        {TikTokEngagementRateHelpText28Days}
                      </Text>
                    </Tooltip>
                  </div>
                </Flex>
              </View>
            </Flex>
          </SocialCard>
        </SocialMetricsTwoGrid>
      </CollapsableSection>
    </View>
  );
}

const SocialMetricsTwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

const SocialMetricsThreeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;

    svg {
      width: 56px;
    }
  }
`;

const SocialMetricsFourGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;

    svg {
      width: 56px;
    }
  }
`;

function BookingStats({ booking }: { booking: GetBookingQuery["booking"] }) {
  if (!booking) {
    return null;
  }

  const reach = booking.bookingPosts.reduce((acc, post) => {
    return acc + post.reach;
  }, 0);

  const engagements = booking.bookingPosts.reduce((acc, post) => {
    return acc + post.engagement;
  }, 0);

  const likes = booking.bookingPosts.reduce((acc, post) => {
    return acc + post.likes;
  }, 0);

  const comments = booking.bookingPosts.reduce((acc, post) => {
    return acc + post.comments;
  }, 0);

  const saves = booking.bookingPosts.reduce((acc, post) => {
    return acc + post.saves;
  }, 0);

  const shares = booking.bookingPosts.reduce((acc, post) => {
    return acc + post.shares;
  }, 0);

  return (
    <View>
      <View margin="xl 0 s">
        <CollapsableSection
          defaultOpen={true}
          title="Social Metrics"
          headerSize="l"
        >
          <SocialMetricsThreeGrid>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <ReachIcon />
                <View margin="0 0 0 m">
                  <Text
                    margin="0"
                    weight="bold"
                    size="xxl"
                    isCompact
                    colorPreset="secondaryHeading"
                  >
                    {reach.toLocaleString()}
                  </Text>
                  <Flex align="center" justify="flex-start">
                    <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                      Reach
                    </Text>
                    <div style={{ marginTop: 2 }}>
                      {/* <Tooltip text={TikTokEngagementsHelpText} /> */}
                    </div>
                  </Flex>
                </View>
              </Flex>
            </Card>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <EngagementIcon width={56} />
                <View margin="0 0 0 m">
                  <Text
                    margin="0"
                    weight="bold"
                    size="xxl"
                    isCompact
                    colorPreset="secondaryHeading"
                  >
                    {engagements.toLocaleString()}
                  </Text>
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Engagements
                  </Text>
                </View>
              </Flex>
            </Card>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <EngagementRateIcon width={56} />
                <View margin="0 0 0 m">
                  <Text
                    margin="0"
                    weight="bold"
                    size="xxl"
                    isCompact
                    colorPreset="secondaryHeading"
                  >
                    {reach > 0
                      ? ((engagements / reach) * 100).toFixed(1) + "%"
                      : "0%"}
                  </Text>
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Engagement rate
                  </Text>
                </View>
              </Flex>
            </Card>
          </SocialMetricsThreeGrid>
          <SocialMetricsFourGrid>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <LikesIcon />
                <View margin="0 0 0 m">
                  <MetricValue>{likes.toLocaleString()}</MetricValue>
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Likes
                  </Text>
                </View>
              </Flex>
            </Card>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <CommentsIcon />
                <View margin="0 0 0 m">
                  <MetricValue>{comments.toLocaleString()}</MetricValue>
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Comments
                  </Text>
                </View>
              </Flex>
            </Card>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <SavesIcon />
                <View margin="0 0 0 m">
                  <MetricValue>{saves.toLocaleString()}</MetricValue>
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Saves
                  </Text>
                </View>
              </Flex>
            </Card>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <SharesIcon />
                <View margin="0 0 0 m">
                  <MetricValue>{shares.toLocaleString()}</MetricValue>
                  <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                    Shares
                  </Text>
                </View>
              </Flex>
            </Card>
          </SocialMetricsFourGrid>
        </CollapsableSection>
      </View>
    </View>
  );
}

function InstagramStats({
  creator,
}: {
  creator: GetBookingQuery["booking"]["creator"];
}) {
  if (!creator.instagram) {
    return null;
  }

  const igExpired =
    creator.fbTokenExpiry && isPast(fromUnixTime(creator.fbTokenExpiry));

  return (
    <View>
      <View margin="xl 0 s">
        <View margin="0 0 l">
          {igExpired ? (
            <Callout type="info" text={ExpiredIGConnectionText} />
          ) : null}
        </View>
        <CollapsableSection
          headerSize="l"
          defaultOpen={true}
          title="Social Metrics"
          tooltip={
            <Tooltip>
              <Text size="s" margin="0">
                Figures calculated over the last 28 days
              </Text>
            </Tooltip>
          }
        >
          <SocialMetricsTwoGrid>
            <Card padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <FollowersIcon />
                <View margin="0 0 0 m">
                  <MetricValue size="l">
                    {creator.instagram.followersCount
                      ? creator.instagram.followersCount.toLocaleString()
                      : 0}
                  </MetricValue>
                  <Text size="s" isCompact margin="0" weight="semi">
                    Followers
                  </Text>
                </View>
              </Flex>
            </Card>
            <SocialCard padding="l" margin="0 0 l">
              <Flex direction="row" justify="space-between" align="center">
                <Flex direction="row" align="center">
                  <ImpressionsIcon />
                  <View margin="0 0 0 m">
                    <MetricValue size="l">
                      {creator.instagram.impressions28Day
                        ? creator.instagram.impressions28Day.toLocaleString()
                        : 0}
                    </MetricValue>
                    <Flex align="center" justify="flex-start">
                      <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                        Views{" "}
                      </Text>
                      <div style={{ marginTop: 2 }}>
                        <Tooltip>
                          <Text size="s" margin="0">
                            {InstagramImpressionsHelpText28Days}
                          </Text>
                        </Tooltip>
                      </div>
                    </Flex>
                  </View>
                </Flex>
                {creator.instagram.impressions28Day &&
                creator.instagram.prevImpressions28Day &&
                creator.instagram.impressions28Day >
                  creator.instagram.prevImpressions28Day ? (
                  <PercentageChange
                    size="l"
                    percentage={
                      ((creator.instagram.impressions28Day -
                        creator.instagram.prevImpressions28Day) /
                        creator.instagram.prevImpressions28Day) *
                      100
                    }
                  />
                ) : null}
              </Flex>
            </SocialCard>
          </SocialMetricsTwoGrid>
          <SocialMetricsThreeGrid>
            <SocialCard padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <ReachIcon />
                <View margin="0 0 0 m">
                  <MetricValue>
                    {creator.instagram.reach28Day
                      ? creator.instagram.reach28Day.toLocaleString()
                      : 0}
                  </MetricValue>
                  <Flex align="center" justify="flex-start">
                    <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                      Reach{" "}
                    </Text>
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {InstagramReachHelpText28Days}
                        </Text>
                      </Tooltip>
                    </div>
                  </Flex>
                </View>
              </Flex>
            </SocialCard>
            <SocialCard padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <EngagementIcon />
                <View margin="0 0 0 m">
                  <MetricValue>
                    {creator.instagram.engagement28Day
                      ? creator.instagram.engagement28Day.toLocaleString()
                      : 0}
                  </MetricValue>
                  <Flex align="center" justify="flex-start">
                    <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                      Engagements{" "}
                    </Text>
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {InstagramEngagementsHelpText}
                        </Text>
                      </Tooltip>
                    </div>
                  </Flex>
                </View>
              </Flex>
            </SocialCard>
            <SocialCard padding="l" margin="0 0 l">
              <Flex direction="row" align="center">
                <EngagementRateIcon />
                <View margin="0 0 0 m">
                  <MetricValue>
                    {creator.instagram.engagement28Day &&
                    creator.instagram.reach28Day &&
                    creator.instagram.reach28Day > 0
                      ? (
                          (creator.instagram.engagement28Day /
                            creator.instagram.reach28Day) *
                          100
                        ).toFixed(1) + "%"
                      : "0%"}
                  </MetricValue>
                  <Flex align="center" justify="flex-start">
                    <Text size="s" isCompact margin="0 xs 0 0" weight="semi">
                      Engagement rate{" "}
                    </Text>
                    <div style={{ marginTop: 2 }}>
                      <Tooltip>
                        <Text size="s" margin="0">
                          {InstagramEngagementRateHelpText28Days}
                        </Text>
                      </Tooltip>
                    </div>
                  </Flex>
                </View>
              </Flex>
            </SocialCard>
          </SocialMetricsThreeGrid>
        </CollapsableSection>
      </View>
      <View margin="0 0 s">
        <CollapsableSection
          headerSize="l"
          defaultOpen={false}
          title="Age Distribution"
        >
          <AgeDemographics demographics={creator.demographics} />
        </CollapsableSection>
      </View>
      <View margin="0 0 s">
        <CollapsableSection
          headerSize="l"
          defaultOpen={false}
          title="Location Insights"
        >
          <LocationDemographics demographics={creator.demographics} />
        </CollapsableSection>
      </View>
    </View>
  );
}

interface Location {
  name: string;
  percentage: number;
  relativePercentage: number;
}

function LocationDemographics({
  demographics,
}: {
  demographics: GetBookingQuery["booking"]["creator"]["demographics"];
}) {
  // Initial view selection for each source
  const [viewSelections, setViewSelections] = useState<
    Record<string, "cities" | "countries">
  >(
    Object.fromEntries(
      demographics?.cities.map((city) => [city.source, "countries"]) || []
    )
  );

  const combineAndGroupLocations = () => {
    const grouped = [
      ...(demographics?.cities || []),
      ...(demographics?.countries || []),
    ].reduce((acc, location) => {
      const type = "city" in location ? "cities" : "countries";
      const name = "city" in location ? location.city : location.country;

      acc[location.source] = acc[location.source] || {
        cities: [],
        countries: [],
      };
      acc[location.source][type].push({
        name,
        percentage: location.percentage,
        relativePercentage: 0,
      });

      return acc;
    }, {} as Record<string, { cities: Location[]; countries: Location[] }>);

    // Calculate relative percentages and sort
    Object.values(grouped).forEach((group) => {
      ["cities", "countries"].forEach((type) => {
        const locations = group[type as "cities" | "countries"];
        const maxPercentage = Math.max(
          ...locations.map((loc) => loc.percentage),
          0
        );
        locations.sort((a, b) => b.percentage - a.percentage).slice(0, 5);
        locations.forEach((loc) => {
          loc.relativePercentage = maxPercentage
            ? (loc.percentage / maxPercentage) * 100
            : 0;
        });
      });
    });

    return grouped;
  };

  const locationsBySource = combineAndGroupLocations();

  const segmentedOptions = [
    { label: "Countries", value: "countries" as const },
    { label: "Cities", value: "cities" as const },
  ];

  return (
    <View>
      <DemographicChartsGrid>
        {Object.entries(locationsBySource).map(([source, data]) => (
          <Card key={source} padding="l" overflow="visible">
            <Flex
              direction="row"
              align="flex-start"
              justify="space-between"
              margin="0 0 m"
            >
              <div>
                <Flex>
                  <Text weight="bold" margin="0 s 0 0" isCompact>
                    {renderDemographicSource(source as DemographicSource)}{" "}
                  </Text>
                  <Tooltip>
                    <Text size="xs" margin="0">
                      {renderDemographicSourceTooltip(
                        source as DemographicSource
                      )}
                    </Text>
                  </Tooltip>
                </Flex>
                <Text
                  style={{ fontSize: 13 }}
                  colorPreset="secondary"
                  margin="0"
                >
                  {renderDemographicTimeframe(source as DemographicSource)}
                </Text>
              </div>
              <div style={{ width: 200, margin: "0 0 m" }}>
                <SegmentedControl
                  onChange={(e: "cities" | "countries") =>
                    setViewSelections((prev) => ({
                      ...prev,
                      [source]: e,
                    }))
                  }
                  value={viewSelections[source]}
                  options={segmentedOptions}
                />
              </div>
            </Flex>
            {data[viewSelections[source]].map((location) => (
              <View margin="0 0 s 0" key={location.name}>
                <Text
                  size="xs"
                  margin="0"
                  isCompact
                  weight="semi"
                  colorPreset="secondaryHeading"
                >
                  {location.name}
                </Text>
                <Flex align="center">
                  <div style={{ flex: 1 }}>
                    <ProgressBar width={location.relativePercentage} />
                  </div>
                  <Text
                    isCompact
                    margin="0 0 0 s"
                    size="xs"
                    style={{ minWidth: "50px" }}
                  >
                    {location.percentage.toFixed(1)}%
                  </Text>
                </Flex>
              </View>
            ))}
          </Card>
        ))}
      </DemographicChartsGrid>
      {demographicExplainer()}
    </View>
  );
}

function AgeDemographics({
  demographics,
}: {
  demographics: GetBookingQuery["booking"]["creator"]["demographics"];
}) {
  const [genderSelections, setGenderSelections] = useState<
    Record<string, Gender>
  >(
    demographics?.ages.reduce((acc, age) => {
      acc[age.source] = Gender.GenderOverall;
      return acc;
    }, {} as Record<string, Gender>) || {}
  );

  if (!demographics) {
    return null;
  }

  interface Age {
    range: string;
    percentage: number;
    source: DemographicSource;
    relativePercentage: number;
  }

  // Group ages by source and filter by selected gender for that source
  const agesBySource = demographics.ages.reduce((acc, age) => {
    const selectedGender = genderSelections[age.source];

    // Only include ages that match the selected gender
    if (age.gender !== selectedGender) {
      return acc;
    }

    if (!acc[age.source]) {
      acc[age.source] = [];
    }

    const existing = acc[age.source].find((a) => a.range === age.ageRange);
    if (existing) {
      return acc;
    }

    acc[age.source].push({
      range: age.ageRange,
      percentage: age.percentage,
      source: age.source,
      relativePercentage: age.percentage,
    });

    return acc;
  }, {} as Record<string, Age[]>);

  // Sort ages and find max percentage for scaling
  Object.keys(agesBySource).forEach((source) => {
    agesBySource[source] = agesBySource[source].sort(
      (a, b) => b.percentage - a.percentage
    );
    const maxPercentage = agesBySource[source][0]?.percentage || 0;

    // Calculate relative percentages
    agesBySource[source] = agesBySource[source]
      .map((age, index) => ({
        ...age,
        relativePercentage: (age.percentage / maxPercentage) * 100,
      }))
      .slice(0, 5);
  });

  const segmentedOptions = [
    { label: "All", value: Gender.GenderOverall },
    { label: "Male", value: Gender.GenderMale },
    { label: "Female", value: Gender.GenderFemale },
  ];

  return (
    <View>
      <DemographicChartsGrid>
        {Object.entries(agesBySource).map(([source, ages]) => (
          <Card key={source} padding="l">
            <Flex
              direction="row"
              align="flex-start"
              justify="space-between"
              margin="0 0 m"
            >
              <div>
                <Text weight="bold" margin="0" isCompact>
                  {renderDemographicSource(source as DemographicSource)}
                </Text>
                <Text
                  style={{ fontSize: 13 }}
                  colorPreset="secondary"
                  margin="0"
                >
                  {renderDemographicTimeframe(source as DemographicSource)}
                </Text>
              </div>
              <div style={{ width: 200, margin: "0 0 m" }}>
                <SegmentedControl
                  onChange={(e: Gender) =>
                    setGenderSelections((prev) => ({
                      ...prev,
                      [source]: e,
                    }))
                  }
                  value={genderSelections[source]}
                  options={segmentedOptions}
                />
              </div>
            </Flex>
            {ages.map((age) => (
              <View margin="0 0 s 0" key={age.range}>
                <Text
                  size="xs"
                  margin="0"
                  isCompact
                  weight="semi"
                  colorPreset="secondaryHeading"
                >
                  {age.range}
                </Text>
                <Flex align="center">
                  <div style={{ flex: 1 }}>
                    <ProgressBar width={age.relativePercentage} />
                  </div>
                  <Text
                    isCompact
                    margin="0 0 0 s"
                    size="xs"
                    style={{ minWidth: "50px" }}
                  >
                    {age.percentage.toFixed(1)}%
                  </Text>
                </Flex>
              </View>
            ))}
          </Card>
        ))}
      </DemographicChartsGrid>
    </View>
  );
}

const DemographicExplainerWrap = styled(View)`
  background-color: ${(p) => p.theme.color.input.hover};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  border: 1px solid ${(p) => p.theme.color.primary};
`;

function demographicExplainer() {
  return (
    <DemographicExplainerWrap margin="xl 0 m" padding="s l">
      <Flex align="center">
        <Flex align="center" justify="center" margin="0 m 0 0">
          <InfoIcon color={lightTheme.color.primary} width={26} />
        </Flex>
        <View>
          <Text margin="0" isCompact size="s" style={{ marginTop: 2 }}>
            Local content typically drives discovery and engagement that
            outperforms these metrics
          </Text>
        </View>
      </Flex>
    </DemographicExplainerWrap>
  );
}

const DemographicChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${(p) => p.theme.spacing.l};
  margin-bottom: ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    gap: ${(p) => p.theme.spacing.m};
  }
`;

const ProgressBarBg = styled.div`
  background-color: ${(p) => p.theme.color.typography.secondary}25;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: 100%;
  height: 8px;
`;

const ProgressBarFg = styled.div<{ width: number }>`
  background: ${(p) => p.theme.color.brandGradient};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: ${(p) => `${Math.min(p.width, 100)}%`};
  height: 8px;
`;

function ProgressBar(props: { width: number }) {
  return (
    <Flex align="center">
      <ProgressBarBg>
        <ProgressBarFg width={props.width} />
      </ProgressBarBg>
    </Flex>
  );
}
