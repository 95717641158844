import { format, fromUnixTime } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Avatar } from "../../components/Avatar";
import { FakeBackLink } from "../../components/BackLink";
import { Button } from "../../components/CTA";
import { Card } from "../../components/Card";
import { Chevron } from "../../components/Chevron";
import { Divider } from "../../components/Divider";
import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { Text } from "../../components/Text";
import { ExternalTextLink } from "../../components/TextLink";
import { View } from "../../components/View";
import { DownloadIcon } from "../../components/icons/DownloadIcon";
import { LinkIcon } from "../../components/icons/LinkIcon";
import { PlayIcon } from "../../components/icons/PlayIcon";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  BookingPostMediaType,
  BookingPostType,
  useGetBookingPostQuery,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { useTheme } from "../../hooks/useTheme";
import { authSelectors } from "../../store/auth/selector";
import { actions } from "../../store/auth/slice";
import { css, styled } from "../../styles";
import { lightTheme } from "../../styles/theme";
import { renderBookingPostType } from "../../utils/enums";

const StickyWrap = styled(Flex)`
  position: sticky;
  top: ${(p) => p.theme.spacing.xl};
  align-self: start;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    position: static;
  }
`;

const CaptionWrap = styled.div`
  position: relative;
`;
const Caption = styled.div<{ showFullCaption: boolean }>`
  overflow-y: scroll;
  max-height: ${(p) => (p.showFullCaption ? "none" : "200px")};
`;

const CaptionOverlay = styled.div`
  cursor: pointer;
  background-image: linear-gradient(
    to top,
    ${(p) => p.theme.color.card.background} 00%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 800px;
  padding-top: 0;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
`;

const Grid = styled.div<{ type: BookingPostType }>`
  width: 100%;
  display: grid;
  margin-top: ${(p) => p.theme.spacing.l};

  ${(p) =>
    p.type === "BOOKING_POST_TYPE_FEED"
      ? css`
          grid-template-columns: 1.5fr 1fr;
        `
      : css`
          grid-template-columns: 1fr 1fr;
        `};
  grid-column-gap: ${(p) => p.theme.spacing.xxl};

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-gap: ${(p) => p.theme.spacing.xxl};
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-top-left-radius: ${(p) => p.theme.misc.borderRadius};
  border-top-right-radius: ${(p) => p.theme.misc.borderRadius};
`;

const VideoWrapper = styled.div`
  position: relative;
`;

const PlayButtonWrap = styled.div<{ playVideo: boolean | null }>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: -8.5px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 150ms linear;

  ${(p) =>
    p.playVideo
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `}
`;

export interface MatchParams {
  page: string;
}

export const BrandReport = () => {
  let history = useHistory();

  return (
    <Wrap>
      <View padding="s l xl">
        <FakeBackLink margin="0" onClick={history.goBack}>
          <Chevron direction="left" /> Back
        </FakeBackLink>
        <ReportDetails />
      </View>
    </Wrap>
  );
};

const ReportDetails = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const client = useGqlClient();
  const [playVideo, setPlayVideo] = useState<boolean | null>(null);
  const [showFullCaption, setShowFullCaption] = useState(false);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const theme = useTheme();
  const history = useHistory();
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  const { data, refetch, isLoading, isError } = useGetBookingPostQuery(
    client,
    { id },
    { retry: false }
  );

  useEffect(() => {
    if (!videoRef.current || playVideo === null) {
      return;
    }
    playVideo ? videoRef.current.play() : videoRef.current.pause();
  }, [playVideo]);

  useEffect(() => {
    const q = new URLSearchParams(history.location.search);
    const newActiveBrandId = q.get("activeBrandId");

    if (newActiveBrandId && newActiveBrandId !== activeBrandId) {
      dispatch(actions.setActiveBrand({ brandId: newActiveBrandId }));
    }
  }, [history.location.search, dispatch, activeBrandId]);

  useEffect(() => {
    refetch();
  }, [activeBrandId, refetch]);

  if (isLoading) {
    return (
      <Wrap>
        <Loading defer />
      </Wrap>
    );
  }

  if (isError || !data) {
    return (
      <Wrap>
        <H3>Oops</H3>

        <Text margin={"0 0 xl 0"}>
          Couldn't load this content, please try again later.
        </Text>
      </Wrap>
    );
  }

  const imageURL = data.bookingPost.thumbnailUrl
    ? data.bookingPost.thumbnailUrl
    : data.bookingPost.mediaUrl;
  const path = new URL(imageURL).pathname;
  const newURL = `https://ik.imagekit.io/fxuomw4xy/${path}?tr=w-800`;

  const videoPending =
    (data.bookingPost.postType === BookingPostType.BookingPostTypeTiktok ||
      data.bookingPost.postType === BookingPostType.BookingPostTypeReel) &&
    data.bookingPost.mediaType ===
      BookingPostMediaType.BookingPostMediaTypeImage;

  return (
    <>
      <Grid type={data.bookingPost.postType}>
        <Card>
          <Flex margin="0 0 m" direction="column">
            {videoPending ? (
              <VideoWrapper>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data.bookingPost.permalink}
                >
                  <PlayButtonWrap playVideo={false}>
                    <PlayIcon />
                  </PlayButtonWrap>
                </a>
                <Image src={newURL} />
              </VideoWrapper>
            ) : data.bookingPost.mediaType ===
              BookingPostMediaType.BookingPostMediaTypeImage ? (
              <Image src={data.bookingPost.mediaUrl} />
            ) : (
              <VideoWrapper>
                <PlayButtonWrap
                  playVideo={playVideo}
                  onClick={() => setPlayVideo(true)}
                >
                  <PlayIcon />
                </PlayButtonWrap>
                <Video
                  muted
                  ref={videoRef}
                  src={data.bookingPost.mediaUrl}
                  poster={newURL}
                  onClick={() => setPlayVideo(!playVideo)}
                  onEnded={() => {
                    if (!videoRef.current) {
                      return;
                    }
                    setPlayVideo(false);
                    videoRef.current.currentTime = 0;
                  }}
                />
              </VideoWrapper>
            )}
            <Flex margin="s m 0" direction="column">
              {data.bookingPost.creator.preferredProfile ? (
                <Flex margin="0 0 l 0" align="center">
                  <Avatar
                    withShadow={false}
                    margin="0 l 0 0"
                    size={50}
                    url={data.bookingPost.creator.preferredProfile.avatar}
                  />
                  <div style={{ textAlign: "left" }}>
                    <Text size="l" weight="bold" margin="0" truncate>
                      {data.bookingPost.postType ===
                        BookingPostType.BookingPostTypeTiktok &&
                      data.bookingPost.creator.tikTok &&
                      data.bookingPost.creator.tikTok.displayName
                        ? data.bookingPost.creator.tikTok.displayName
                        : data.bookingPost.creator.instagram &&
                          data.bookingPost.creator.instagram.name
                        ? data.bookingPost.creator.instagram.name
                        : ""}
                    </Text>
                    <ExternalTextLink
                      target="_blank"
                      href={`https://www.${
                        data.bookingPost.postType ===
                        BookingPostType.BookingPostTypeTiktok
                          ? `tiktok`
                          : `instagram`
                      }.com/${
                        data.bookingPost.postType ===
                          BookingPostType.BookingPostTypeTiktok &&
                        data.bookingPost.creator.tikTok
                          ? `@${data.bookingPost.creator.tikTok.username}`
                          : data.bookingPost.creator.instagram!.username
                      }`}
                      rel="noreferrer"
                    >
                      @{data.bookingPost.creator.preferredProfile.username}
                    </ExternalTextLink>
                  </div>
                </Flex>
              ) : null}

              <CaptionWrap>
                <Caption showFullCaption={showFullCaption}>
                  {data.bookingPost.mediaCaption.split("\n").map((t, i) => (
                    <Text margin="0" size="s" key={i}>
                      {t}
                    </Text>
                  ))}
                </Caption>
                {showFullCaption ? null : (
                  <CaptionOverlay onClick={() => setShowFullCaption(true)}>
                    <Text
                      style={{ marginBottom: -5 }}
                      weight="semi"
                      color={lightTheme.color.primary}
                      margin="0"
                      onClick={() => setShowFullCaption(showFullCaption)}
                    >
                      Show more
                    </Text>
                  </CaptionOverlay>
                )}
              </CaptionWrap>
            </Flex>
          </Flex>
        </Card>
        <StickyWrap direction="column">
          <Text colorPreset="heading" margin="0" size="xl" weight="bold">
            {renderBookingPostType(data.bookingPost.postType)} insights
          </Text>
          <Flex align="center" justify="space-between">
            <Text margin="xs 0 s" size="s" isCompact colorPreset="secondary">
              Posted on{" "}
              {format(
                fromUnixTime(data.bookingPost.createdAt),
                "EEEE do MMMM y - HH:mm"
              )}
            </Text>
          </Flex>
          <Text colorPreset="heading" margin="m 0 0" size="m" weight="bold">
            Overview
          </Text>
          <Flex margin="s 0 0" justify="space-between">
            {data.bookingPost.postType !==
            BookingPostType.BookingPostTypeTiktok ? (
              <>
                <Text colorPreset="secondary" margin="0">
                  Views
                </Text>
                <Text margin="0">
                  {data.bookingPost.impressions.toLocaleString()}
                </Text>
              </>
            ) : null}
          </Flex>
          <Flex margin="xs 0 0" justify="space-between">
            <Text colorPreset="secondary" margin="0">
              Reach
            </Text>
            <Text margin="0">{data.bookingPost.reach.toLocaleString()}</Text>
          </Flex>
          <Flex margin="xs 0 0" justify="space-between">
            <Text colorPreset="secondary" margin="0">
              Engagements
            </Text>
            <Text margin="0">
              {data.bookingPost.engagement.toLocaleString()}
            </Text>
          </Flex>
          <Flex margin="xs 0 0" justify="space-between">
            <Text colorPreset="secondary" margin="0">
              Engagement rate
            </Text>
            <Text margin="0">
              {`${
                data.bookingPost.engagement > 0 && data.bookingPost.reach > 0
                  ? (
                      (data.bookingPost.engagement / data.bookingPost.reach) *
                      100
                    ).toFixed(1)
                  : 0
              }%`}
            </Text>
          </Flex>
          {data.bookingPost.likes > 0 ||
          data.bookingPost.comments > 0 ||
          data.bookingPost.saves > 0 ||
          data.bookingPost.shares > 0 ? (
            <Text colorPreset="heading" margin="xl 0 0" size="m" weight="bold">
              Engagement
            </Text>
          ) : null}
          {data.bookingPost.likes > 0 ? (
            <Flex margin="s 0 0" justify="space-between">
              <Text colorPreset="secondary" margin="0">
                Likes
              </Text>
              <Text margin="0">{data.bookingPost.likes.toLocaleString()}</Text>
            </Flex>
          ) : null}
          {data.bookingPost.comments > 0 ? (
            <Flex margin="xs 0 0" justify="space-between">
              <Text colorPreset="secondary" margin="0">
                Comments
              </Text>
              <Text margin="0">
                {data.bookingPost.comments.toLocaleString()}
              </Text>
            </Flex>
          ) : null}
          {data.bookingPost.saves > 0 ? (
            <Flex margin="xs 0 0" justify="space-between">
              <Text colorPreset="secondary" margin="0">
                Saves
              </Text>
              <Text margin="0">{data.bookingPost.saves.toLocaleString()}</Text>
            </Flex>
          ) : null}
          {data.bookingPost.shares > 0 ? (
            <Flex margin="xs 0 0" justify="space-between">
              <Text colorPreset="secondary" margin="0">
                Shares
              </Text>
              <Text margin="0">{data.bookingPost.shares.toLocaleString()}</Text>
            </Flex>
          ) : null}
          <Divider margin="xl 0" />

          {videoPending ? null : (
            <Button
              isDisabled={videoPending}
              onClick={() => {
                if (videoPending) {
                  return;
                }
                track("Content downloaded");
                window.open(data.bookingPost.mediaUrl, "_blank");
              }}
            >
              <Flex align="center" justify="center">
                {videoPending ? null : <DownloadIcon color="#fff" />}
                <span style={{ marginLeft: theme.spacing.s }}>
                  {videoPending ? "Assets pending" : "Download assets"}
                </span>
              </Flex>
            </Button>
          )}
          {data.bookingPost.permalink && (
            <Button
              margin="m 0 0"
              buttonType="secondary"
              onClick={() => {
                track("Content viewed");
                window.open(data.bookingPost.permalink, "_blank");
              }}
            >
              <Flex align="center" justify="center">
                <LinkIcon iconColor={theme.color.button.secondaryText} />
                <span style={{ marginLeft: theme.spacing.s }}>
                  View on{" "}
                  {data.bookingPost.postType ===
                  BookingPostType.BookingPostTypeTiktok
                    ? `TikTok`
                    : `Instagram`}
                </span>
              </Flex>
            </Button>
          )}
        </StickyWrap>
      </Grid>
    </>
  );
};
