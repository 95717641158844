import { useSelector } from "react-redux";
import { preferencesSelectors } from "../../store/preferences/selector";
import styled, { ThemeProp } from "../../styles";
import { darkTheme, lightTheme } from "../../styles/theme";
import { Card } from "../Card";
import { Text } from "../Text";
import { View } from "../View";

const Wrap = styled(View)<{ active: boolean }>`
  width: 100%;
  height: 100%;
  min-height: 70px;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  overflow: hidden;
  border: ${(p) => (p.active ? 2 : 1)}px solid
    ${(p) => (p.active ? p.theme.color.primary : `${p.theme.color.inactive}`)};
  position: relative;
  z-index: 0;
`;

type RadioButtonWrapProps = ThemeProp & {
  active: boolean;
  type: Props["type"];
};

const RadioButtonWrap = styled(View)<RadioButtonWrapProps>`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  margin-left: auto;
  margin-top: auto;
  border: 1px solid
    ${(p) =>
      p.active
        ? p.theme.color.primary
        : p.type === "light"
        ? lightTheme.color.typography.secondary
        : darkTheme.color.typography.secondary};
`;

type RadioButtonProps = ThemeProp & {
  active: boolean;
};

const RadioButton = styled(View)<RadioButtonProps>`
  height: 10px;
  width: 10px;
  border-radius: 999px;
  background-color: ${(p) =>
    p.active ? p.theme.color.primary : "transparent"};
`;

interface Props {
  type: "light" | "dark" | "auto";
}

type StyledCardProps = ThemeProp & {
  type: Props["type"];
};

const StyledCard = styled(Card)<StyledCardProps>`
  width: 100%;
  height: 100%;
  border-radius: 0px;
  border-top-left-radius: 4px;
  z-index: 2;
  background-color: ${(p) =>
    p.type === "light"
      ? lightTheme.color.card.background
      : p.type === "dark"
      ? darkTheme.color.card.background
      : lightTheme.color.card.background};

  box-shadow: ${(p) =>
    p.type === "dark" ? darkTheme.shadow.card : lightTheme.shadow.card};
`;

const GradientBackground = styled.div<{ type: Props["type"] }>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${(p) => (p.type === "auto" ? "50%" : "100%")};
  height: 100%;
  background: ${(p) =>
    p.type === "light"
      ? `linear-gradient(90deg, ${lightTheme.color.background.start}, ${lightTheme.color.background.end})`
      : p.type === "dark"
      ? `linear-gradient(90deg, ${darkTheme.color.background.start}, ${darkTheme.color.background.end})`
      : `linear-gradient(90deg, ${lightTheme.color.background.start}, ${lightTheme.color.background.end})`};
  z-index: 1;
`;

const DarkGradient = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${darkTheme.color.background.start},
    ${darkTheme.color.background.end}
  );
  z-index: 1;
`;

export function DarkMode(props: Props) {
  const selectedTheme = useSelector(preferencesSelectors.selectedTheme);
  const theme = selectedTheme || "auto";

  return (
    <Wrap active={theme === props.type}>
      <GradientBackground type={props.type} />
      <View style={{ position: "relative", zIndex: 2 }} padding="m 0 0 m">
        <StyledCard type={props.type}>
          <View padding="s m m s">
            <Text
              margin="0 0 m 0"
              weight="semi"
              size="xs"
              color={
                props.type === "light"
                  ? lightTheme.color.typography.text
                  : props.type === "dark"
                  ? darkTheme.color.typography.text
                  : lightTheme.color.typography.text
              }
            >
              {props.type.charAt(0).toUpperCase() + props.type.slice(1)}
            </Text>
            <RadioButtonWrap active={theme === props.type} type={props.type}>
              <RadioButton active={theme === props.type} />
            </RadioButtonWrap>
          </View>
        </StyledCard>
      </View>
      {props.type === "auto" && (
        <>
          <DarkGradient />
          <View
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              width: "50%",
              zIndex: 2,
            }}
            padding="m 0 0"
          >
            <StyledCard type="dark" style={{ borderTopLeftRadius: 0 }}>
              <View padding="s m s s">
                <Text margin="s 0 0 0" size="xs"></Text>
                <RadioButtonWrap active={theme === "auto"} type={props.type}>
                  <RadioButton active={theme === "auto"} />
                </RadioButtonWrap>
              </View>
            </StyledCard>
          </View>
        </>
      )}
    </Wrap>
  );
}
