import { gql, GraphQLClient } from "graphql-request";
import { useQuery } from "react-query";
import { MOBILE_BREAKPOINT } from "../config";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { Flex } from "./Flex";
import Loading from "./Loading";

const endpoint =
  "https://graphql.contentful.com/content/v1/spaces/lblgi29a0gb4";

const BRAND_LOGOS_QUERY = gql`
  {
    brandLogosCollection(order: priority_ASC, limit: 20) {
      items {
        image {
          url
        }
        altText
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  justify-items: center;
  align-items: center;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: ${(p) => p.theme.spacing.l};
  row-gap: ${(p) => p.theme.spacing.s};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    max-width: 400px;
    column-gap: ${(p) => p.theme.spacing.l};
    row-gap: ${(p) => p.theme.spacing.s};
  }
`;

const Wrap = styled.div<{ darkMode?: boolean; faded?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  color: ${(p) => p.theme.color.typography.text};
  filter: saturate(200%) grayscale(1);

  ${(p) =>
    p.faded &&
    css`
      opacity: 0.7;
    `}

  ${(p) =>
    p.darkMode &&
    css`
      filter: saturate(200%) grayscale(1) invert(1);
    `}
`;

const Image = styled.img`
  width: 80px;
`;

const graphQLClient = new GraphQLClient(endpoint);

graphQLClient.setHeader(
  "authorization",
  "Bearer SOlDtpfoRwpfY0TtRuOW_dUta7hixiBb1yM86nYT3oU"
);

export function LogoCloud({ faded }: { faded?: boolean }) {
  const theme = useTheme();
  const {
    data,
    isLoading,
    isError,
  }: { data: any; isLoading: boolean; isError: boolean } = useQuery(
    "brandLogos",
    () => {
      return graphQLClient.request(BRAND_LOGOS_QUERY);
    }
  );

  if (isError || isLoading || !data || !data.brandLogosCollection) {
    return (
      <Wrap>
        <Loading defer />
      </Wrap>
    );
  }

  const logos = data.brandLogosCollection.items;

  return (
    <Wrap darkMode={theme.name === "dark"} faded={faded}>
      <Grid>
        {logos.map((i: any) => {
          return (
            <Flex
              align="center"
              justify="center"
              style={{ width: 100, height: 100 }}
            >
              <Image src={i.image.url} />
            </Flex>
          );
        })}
      </Grid>
    </Wrap>
  );
}
